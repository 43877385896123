import React, { useEffect } from "react";
import { useState } from "react";
import Table from "react-bootstrap/Table";
import { getPaymentHistory } from "../../../../services/student";
import CustomSpinner from "../../../../ui/loaders/CustomSpinner";
import Pagination from "react-bootstrap/Pagination";

const NoInvoice = () => {
  return <h3 className="text-center mt-2">No Invoices</h3>;
};

function PaymentHistory() {
  const fileBaseUrl = process.env.REACT_APP_PDF_DOWNLOAD_URL;
  const [invoices, setInvoices] = useState([]);
  const [active, setActive] = useState(1);
  const [total, setTotal] = useState(0);
  const pages = Math.ceil(total / 10);
  const pageNum = [];
  for (let number = 1; number <= pages; number++) {
    pageNum.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => setActive(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setLoader(true);
    getPaymentHistory(active).then((res) => {
      setInvoices(res.data.invoice.data);
      setTotal(res.data.invoice.total);
      setLoader(false);
    });
  }, [active]);
  return (
    <div className="card card-primary">
      <div id="" role="tabpanel">
        <div className="card-header">
          <h3 className="card-title">Payment History</h3>
        </div>
        <div className="card-body p-4">
          {loader ? (
            <CustomSpinner />
          ) : (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>Invoice No.</th>
                    <th>Total Amount</th>
                    <th className="text-center">Date</th>
                    <th>Teacher</th>
                    <th className="text-center">Download</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td>{i.invoice_number}</td>
                        <td>${Number(i.total).toFixed(2)}</td>
                        <td className="text-center">
                          {new Date(i.created_at).toLocaleDateString()}
                        </td>
                        <td>{i.tutor_detal.first_name}</td>
                        <td className="text-center">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={fileBaseUrl + i.invoice_path}
                            download
                          >
                            <i className="bi bi-download"></i>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {invoices.length > 0 ? (
                <Pagination>
                  <Pagination.Prev
                    onClick={() => setActive((prev) => prev - 1)}
                    disabled={active === 1}
                  />
                  {pageNum}
                  <Pagination.Next
                    onClick={() => setActive((prev) => prev + 1)}
                    disabled={active === pages}
                  />
                </Pagination>
              ) : (
                <NoInvoice />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentHistory;
