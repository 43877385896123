import React from "react";

function ImAvailable({ setDropdown, dropdown, time, setTime, days, setDays }) {
  // handle time change
  const handleTimeChange = (id) => {
    setTime(
      time.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            checked: !i.checked,
          };
        } else {
          return i;
        }
      })
    );
  };
  // handle days change
  const handleDaysChange = (id) => {
    setDays(
      days.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            checked: !i.checked,
          };
        } else {
          return i;
        }
      })
    );
  };
  return (
    <div className="input-group" onClick={(e) => setDropdown("time")}>
      <label className="label">I’m available</label>
      <div id="select-time">Select a Time</div>
      {dropdown === "time" && (
        <div className="time-select-box">
          <b className="mb-1">Choose the time of the day when you are free:</b>
          <div className="row p-2">
            {time.map((i, index) => {
              return (
                <div
                  key={index}
                  id={i.class}
                  className={`col-lg-3 text-center p-3 border ${
                    i.checked ? "active_time" : "not_active"
                  }`}
                  onClick={() => handleTimeChange(i.id)}
                >
                  <b>{i.label1}</b>
                  <p>{i.label2}</p>
                </div>
              );
            })}
          </div>
          <div className="row p-2">
            <b className="mb-1">Days of the week:</b>
            {days.map((i, index) => {
              return (
                <div
                  key={index}
                  id={i.class}
                  className={`col text-center p-2 border ${
                    i.checked ? "active_time" : "not_active"
                  }`}
                  onClick={() => handleDaysChange(i.id)}
                >
                  <p>{i.label}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default ImAvailable;
