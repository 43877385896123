import React from "react";
import { useEffect } from "react";

function TermsAndConditionPage() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <section className="terms_and_conditions container mt-5">
      <div className="mt-3">
        <h2>
          <b>Terms of Use, Website Privacy & Cookie Policy</b>
        </h2>
        <p>
          Some important information to take into consideration when using our
          services
        </p>
      </div>

      <div className="mt-5">
        <h4>
          <b>Use of Day Translations, Inc.</b>
        </h4>
        <p>
          You may use information available at Day Translations, Inc. for
          personal, non-commercial purposes, provided that you agree with, and
          comply with our terms Terms of Service and Terms of Use. If the
          information is an original content from Day Translations, Inc., we
          require proper attribution with a link back to our website. Day
          Translations, Inc. reserves the right to require you to cease
          distributing our original content at any time for any reason. Use of
          information obtained from or through Day Translations, Inc. is at your
          own risk.
        </p>
      </div>

      <div className="mt-3">
        <h6>
          <b>Contact Information</b>
        </h6>
        <p>
          Day Translations, Inc., AND ALL INFORMATION, PRODUCTS, CONTENT, AND
          SERVICES ACCESSED THROUGH OR PROVIDED BY Day Translations, Inc., ARE
          MADE AVAILABLE “AS IS” WITHOUT WARRANTY OF ANY KIND. Day Translations,
          Inc. HEREBY DISCLAIMS ALL WARRANTIES CONCERNING THE WEBSITE, WHETHER
          EXPRESS OR IMPLIED FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
        </p>
      </div>

      <div className="mt-3">
        <h6>
          <b>Intellectual Property</b>
        </h6>
        <p>
          You acknowledge that Day Translations, Inc. owns all intellectual
          property rights related to it, which include the copyrights in our web
          page designs and original content. Day Translations, Inc. provides
          access to third party-published content, and that content is often
          protected by copyright law. Generally, you may not copy, modify,
          re-publish or re-distribute any of the third party content to which
          you gain access through Day Translations, Inc., without first
          obtaining the consent of the owner of that content.
        </p>
      </div>

      <div className="mt-3">
        <h6>
          <b>Intellectual Property</b>
        </h6>
        <p>
          Day Translations, Inc. understands that you care how your information
          is used and shared and we take this with absolute seriousness and
          vigilance. Information about our website visitor is kept confidential
          in our organization and we are not in the business of selling it to
          others. We receive and store certain types of information whenever you
          interact with us. For instance, like many websites on the internet, we
          use “cookies,” and we obtain certain types of information when your
          web browser accesses daytranslations.com, or advertisements and other
          content served by or on behalf of daytranslations.com on other Web
          sites. The specific cookie types are indicated below :
        </p>
      </div>

      <div className="mt-3">
        <p>
          Cloudflare: To ensure our pages are served faster and to manage the
          load to our servers so we can provide you better service, we use
          Cloudflare which sets cookies. Their security and privacy policy
          provides more information.
        </p>
      </div>

      <div className="mt-3">
        <p>
          Analytical and Social Media Cookies: We use social media sharing tool
          so that you as a user can more easily share the content on our
          website, as well as analytical cookies that will help us identify and
          count the number of visitors and to see how visitors move around the
          site when they’re using it. This helps us to improve the way our
          website works.
        </p>
      </div>

      <div className="mt-3">
        <h6>
          <b>Contact Information</b>
        </h6>
        <p>
          If you have any queries or complaints, please contact us in writing or
          via e-mail at the address below :
        </p>
        <p className="mt-3">Day Translations. Inc,</p>
        <p>477 Madison Avenue Floor 6</p>
        <p>New York, New York 10022</p>
        <p>E-mail: contact@daytranslations.com</p>
      </div>
    </section>
  );
}

export default TermsAndConditionPage;
