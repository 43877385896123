import React, { useEffect, useState } from "react";
import BookingList from "./BookingList";
import { useDispatch } from "react-redux";
import { storeUser } from "../../../features/storeUser/userSlice";
import { getTutor } from "../../../services/tutors";
import CustomSpinner from "../../../ui/loaders/CustomSpinner";
import Verification from "../Profile/Verification";
import Details from "./Details";
import Availability from "./Availability";
import Message from "./Message";
// bootstrap tabs
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useNavigate } from "react-router-dom";
import "./dashboard.css";
function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [userVerified, setUserVerfied] = useState(false);
  // get student data
  useEffect(() => {
    getTutor()
      .then((res) => {
        if (res.data.data.verified === "1") {
          setUserVerfied(true);
        } else {
          setUserVerfied(false);
        }
        dispatch(storeUser(res?.data?.data));
        setLoader(false);
      })
      .catch();
  }, [dispatch]);
  return (
    <>
      <main id="main" className="container mt-5 pt-3">
        <section className="tabs_section">
          <Tab.Container defaultActiveKey="details" className="student_tabs">
            <Row>
              <Col sm={3}>
                <Nav
                  variant="tabs"
                  className="flex-column card custom_padding_class"
                >
                  <Nav.Item>
                    <Nav.Link eventKey="details">Dashboard</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => navigate("/tutor/bookings-listing")}
                    >
                      Booking's List
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={() => navigate("/tutor/availability")}>
                      Availability
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={() => navigate("/tutor/booking-slots")}>
                      Booking Slots
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="messages">Messages</Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="details">
                    <Details />
                  </Tab.Pane>
                  <Tab.Pane eventKey="bookings">
                    <BookingList />
                  </Tab.Pane>
                  <Tab.Pane eventKey="availability">
                    <Availability />
                  </Tab.Pane>
                  <Tab.Pane eventKey="messages">
                    <Message />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </section>
      </main>
    </>
  );
}

export default Dashboard;
