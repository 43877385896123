import { languages, countries, specialties } from "../services/common";
export const parseParams = (querystring) => {
  // parse query string
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

export const getLists = async () => {
  const obj = {
    languages: [],
    countries: [],
    specialties: [],
  };
  await languages().then((res) => {
    const result = res.data.data;
    obj.languages = result.map((i) => {
      return { label: i.name, value: i.id };
    });
  });
  await countries().then((res) => {
    const result = res.data.data;
    obj.countries = result.map((i) => {
      return { label: i.name, value: i.id };
    });
  });
  await specialties().then((res) => {
    const result = res.data.data;
    obj.specialties = result.map((i, index) => {
      return { id: index, label: i.name, value: i.id, checked: false };
    });
  });
  return obj;
};
