import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import spain from "../../assets/img/spain.svg";
import "./findtutor.css";
import { Link } from "react-router-dom";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

function FeaturedLanguage() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getFeaturedLanguage();
  }, []);

  async function getFeaturedLanguage() {
    let result = await fetch(baseUrl + "/languages/featured?per_page=8");
    result = await result.json();
    if (result.data) {
      setData(result.data);
    } else {
    }
  }
  return (
    <>
      <section id="tutors" className="tutors">
        <Container data-aos="fade-up">
          <Row>
            {data.map((item) => (
              <Col md="3" key={item.id} className="d-flex align-items-stretch">
                <div className="icon-box">
                  <Link
                    to={item?.teachers_count > 0 ? `/tutors` : ""}
                    state={{ label: item?.name, value: item?.id }}
                  >
                    <div className="icon">
                      <img
                        src={item.icon ? item.icon : spain}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <h4>{item.name} tutors</h4>
                    <span
                      className="count-area"
                    >
                      <p>{item?.teachers_count} Teachers</p>
                      <i className="bi bi-arrow-right-short"></i>
                    </span>
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default FeaturedLanguage;
