import React from "react";
import { openBookTrailModal, openLoginModal } from "../../../../features/modal/modalSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  openBookingCalendarModal,
  openBookingLoginModal,
} from "../../../../features/modal/modalSlice";
function BlogSidebar({ tutor }) { 
  const { video_url, id } = tutor;
  // get video id
  const videoId = video_url?.split("=")[1];
  const dispatch = useDispatch();

  const checkLoggedForBooking = (action) => { 
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(openBookingCalendarModal({ data: action }));
    } else {
      dispatch(openLoginModal({ data: action }));
    }
  };

  return (
    <div className="card rounded-0 border-0 mb-4">
      <div className="card-body p-0">
        <div className="media mb-3">
          {videoId && (
            <iframe
              title="youtube-video"
              width="100%"
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
            ></iframe>
          )}
          <div className="content-area text-center">
            {/* <Link
              to=""
              className="theme-btn"
              onClick={() => dispatch(openBookTrailModal(id))}
            >
              Book trial lesson
            </Link> */}
            <button
              to=""
              className="theme-btn form-control"
              onClick={() => checkLoggedForBooking(tutor)}
              data={tutor}
            >
              Book Trial lesson
            </button>
            <Link to="" className="theme-btn no-bg">
              Send message
            </Link>
            <Link to="" className="theme-btn no-bg">
              Save to my list
            </Link>
            <ul>
              <li>Usually responds in 11 hrs</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogSidebar;
