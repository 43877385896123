import React from "react";
// bootstrap tabs
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
// import account,email,passoword,payment metod,payment history,auto-confirmation,calendar,notification component
import Account from "./Account";
import Email from "./Email";
import Password from "./Password";
import PaymentMethod from "./PaymentMethod";
import PaymentHistory from "./PaymentHistory";
import AutoConfirmation from "./AutoConfirmation";
import Calendar from "./Calendar";
// import Notification from "./notification/Notification";
import Notification from "./notification";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
function Setting(props) {
  const { setPercentage } = props;
  const user = useSelector((state) => state?.user?.value);

  const [searchParams, setSearchParams] = useSearchParams();
  let menu = searchParams.get("menu");
  const removeQueryParams = () => {
    if (searchParams.has("menu")) {
      const token = searchParams.get("menu");
      if (token) {
        searchParams.delete("menu");
        setSearchParams(searchParams);
      }
    }
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-dark"
        style={{ marginTop: "10%" }}
      >
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link
                  to="/student/dashboard/message"
                  className="nav-link"
                  name="message"
                >
                  Message
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/student/dashboard/my-lesson"
                  className="nav-link"
                  name="myLesson"
                >
                  My Lesson
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/student/dashboard/setting"
                  className="nav-link"
                  name="setting"
                >
                  Settings
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container mt-5 mb-5">
        {" "}
        <Tab.Container
          defaultActiveKey={`${menu ? menu : "account"}`}
          className="student_tabs"
        >
          <Row>
            <Col sm={3}>
              <Nav
                variant="tabs"
                className="flex-column card custom_padding_class"
              >
                <Nav.Item>
                  <Nav.Link eventKey="account" onClick={removeQueryParams}>
                    Account
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="email" onClick={removeQueryParams}>
                    Email
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="password" onClick={removeQueryParams}>
                    Password
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="payment_method"
                    onClick={removeQueryParams}
                  >
                    Payment Method
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="payment_history"
                    onClick={removeQueryParams}
                  >
                    Payment History
                  </Nav.Link>
                </Nav.Item>
                {/*<Nav.Item>
                <Nav.Link eventKey="auto_confirmation" href="#">
                  Auto Confirmation
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="calendar" href="#">
                  Calendar
                </Nav.Link>
              </Nav.Item>*/}
                <Nav.Item>
                  <Nav.Link eventKey="notification" onClick={removeQueryParams}>
                    Notification
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="account">
                  <Account setPercentage={setPercentage} />
                </Tab.Pane>
                <Tab.Pane eventKey="email">
                  <Email user={user} />
                </Tab.Pane>
                <Tab.Pane eventKey="password">
                  <Password />
                </Tab.Pane>
                <Tab.Pane eventKey="payment_method">
                  <PaymentMethod />
                </Tab.Pane>
                <Tab.Pane eventKey="payment_history">
                  <PaymentHistory />
                </Tab.Pane>
                <Tab.Pane eventKey="auto_confirmation">
                  <AutoConfirmation />
                </Tab.Pane>
                <Tab.Pane eventKey="calendar">
                  <Calendar />
                </Tab.Pane>
                <Tab.Pane eventKey="notification">
                  <Notification />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
}

export default Setting;
