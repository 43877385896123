import React from "react";
import { Spinner } from "react-bootstrap";

function CustomSpinner() {
  return (
    <div className="d-flex align-items-center custom_loader justify-content-center">
      <Spinner animation="border" />
      <span className="ms-2">Loading ... </span>
    </div>
  );
}

export default CustomSpinner;
