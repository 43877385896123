import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
// import service
import { languages } from "../../services/common";

function Header() {
  // for naviagte to result page
  const navigate = useNavigate();

  // language states
  // language state
  const [language, setLanguage] = useState(null);
  // langauges list state
  const [languagesList, setLanguagesList] = useState([]);

  // handle search
  function search() {
    if (language) {
      navigate(`/tutors`, { state: language });
    }
  }

  // get language lists
  useEffect(() => {
    async function fetchdata() {
      await languages().then((res) => {
        const data = res.data.data;
        setLanguagesList(
          data.map((i) => {
            return {
              value: i?.id,
              label: i?.name,
            };
          })
        );
      });
    }
    fetchdata();
  }, []);
  
  // custom styles for search button
  const styles = {
    control: (base) => ({
      ...base,
      border: "none",
      boxShadow: "none",
    }),
    menuList: (base) => ({
      ...base,
      height: "10rem",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#1a2e9e",
        borderRadius: "3px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#1a2e9e",
      },
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999, top: "5px" }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? "#1a2e9e" : "",
      "&:hover": {
        backgroundColor: "#1a2e9e",
        color: "white",
      },
    }),
  };

  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-7 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>Learn from the world’s best language tutors online.</h1>
              <h2>the fastest way to learn a new language.</h2>
              <Form style={{ padding: "10px 0px" }}>
                <Form.Group>
                  <Select
                    menuPortalTarget={document.body}
                    styles={styles}
                    options={languagesList}
                    placeholder="Search by language"
                    onChange={(opt) => {
                      setLanguage(opt);
                    }}
                  />
                  <Button
                    type="button"
                    onClick={search}
                    style={{
                      height: "40px",
                      marginTop: "3px",
                    }}
                  >
                    Search Tutors
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
