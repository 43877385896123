import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Home from "./common/Home/home";
import FindATutor from "./component/find-a-tutor/find-a-tutor";
import Result from "./component/Result/result";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";

// header & footer component
import Header from "./common/Header/header";
import Footer from "./common/Footer/footer";

// page not found component
import PageNotFound from "./component/404-Page";
// protected routes component
import Protected from "./Protected";
// tutor detail component
import TutorDetails from "./component/Tutor/Details";
// signup components
import StudentSignup from "./component/Student/Signup";
import TutorSignup from "./component/Tutor/Signup";
import EnterpriseSignup from "./component/Enterprise/Signup";
import Congratulation from "./component/Congratulation/congratulation";

// reset password component
import ResetPassword from "./component/ResetPassword/ResetPassword";

// dashboard component
// import StudentDashboard from "./component/Student/Dashboard";
import TutorDashboard from "./component/Tutor/Dashboard";
import EnterpriseDashboard from "./component/Dashboard/EnterpriseDashboard";

// profile components
import StudentProfile from "./component/Student/Profile";
import EnterpriseProfile from "./component/Enterprise//Profile";
import TutorProfile from "./component/Tutor/Profile";

// Auth
import StudentAuth from "./auth/StudentAuth";
import TutorAuth from "./auth/TutorAuth";
import EnterpriseAuth from "./auth/EnterpriseAuth";
//  custom loader
import CustomLoader from "./common/CustomLoader/CustomLoader";
import { useSelector } from "react-redux";
// toaster container
import { Toaster } from "react-hot-toast";
// component for modals
import Modal from "./ui/modals/Modal";
import Checkout from "./component/checkout/Checkout";
import Message from "./component/Student/Dashboard/message";
import MyLesson from "./component/Student/Dashboard/my-lesson/MyLesson";
import Setting from "./component/Student/Dashboard/settings";

// pages
import LoginPage from "./pages/LoginPage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import TermsAndConditionPage from "./pages/TermsAndConstionPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import BookingsListing from "./component/Tutor/Booking-list/BookingsListing";
import Availability from "./component/Tutor/Availability/Availability";
import BookingSlots from "./component/Tutor/Booking-slots.jsx/BookingSlots";

function App() {
  // for uploading percentage
  const [percentage, setPercentage] = useState(0);

  // for dynamic email in congratulation page
  const [email, setEmail] = useState();
  // to get state for loader
  const state = useSelector((state) => state.uploading.value);
  return (
    <>
      {/* toaster container*/}
      <Toaster />
      {/* common loader for uploading files */}
      {state && <CustomLoader percentage={percentage} />}
      <Router>
        {/* modals */}
        <Modal />
        {/* Header  */}
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="find-a-tutor" element={<FindATutor />} />
          <Route exact path="became-a-tutor" element={<FindATutor />} />
          <Route exact path="enterprise-account" element={<FindATutor />} />
          <Route exact path="tutors" element={<Result />} />
          <Route exact path="login" element={<LoginPage />} />
          <Route exact path="about-us" element={<AboutUsPage />} />
          <Route exact path="contact-us" element={<ContactUsPage />} />
          <Route exact path="tutor/details" element={<TutorDetails />} />
          <Route
            exact
            path="terms-and-conditions"
            element={<TermsAndConditionPage />}
          />
          <Route exact path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            exact
            path="student-signup"
            element={<StudentSignup setEmail={setEmail} />}
          />
          <Route
            exact
            path="tutor-signup"
            element={<TutorSignup setEmail={setEmail} />}
          />
          <Route
            exact
            path="enterprise-signup"
            element={<EnterpriseSignup setEmail={setEmail} />}
          />
          <Route
            exact
            path="congratulation"
            element={<Congratulation email={email} />}
          />
          <Route
            exact
            path="reset-password/:token"
            element={<ResetPassword />}
          />
          {/* protected routes */}
          <Route element={<Protected />}>
            {/* teacher deatils route */}
            
            {/* student routes */}
            <Route element={<StudentAuth />}>
              {/* <Route
                path="student/dashboard"
                element={<StudentDashboard setPercentage={setPercentage} />}
              /> */}
              <Route
                path="student/profile"
                element={<StudentProfile setPercentage={setPercentage} />}
              />
              <Route path="checkout/:uuid" element={<Checkout />} />
              <Route path="student/dashboard/message" element={<Message />} />
              <Route
                path="student/dashboard/my-lesson"
                element={<MyLesson />}
              />
              <Route path="student/dashboard/setting" element={<Setting />} />
            </Route>
            {/* tutor routes */}
            <Route element={<TutorAuth />}>
              <Route path="tutor/dashboard" element={<TutorDashboard />} />
              <Route path="tutor/profile" element={<TutorProfile setPercentage={setPercentage} />}/>
              <Route path="tutor/bookings-listing" element={<BookingsListing />} />
              <Route path="tutor/availability" element={<Availability />} />
              <Route path="tutor/booking-slots" element={<BookingSlots />} />
            </Route>
            {/* enterprise routes */}
            <Route element={<EnterpriseAuth />}>
              <Route
                path="enterprise/dashboard"
                element={<EnterpriseDashboard />}
              />
              <Route
                path="enterprise/profile"
                element={<EnterpriseProfile setPercentage={setPercentage} />}
              />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {/* footer */}
        <Footer />
      </Router>
    </>
  );
}

export default App;
