import React from "react";
import Modal from "react-bootstrap/Modal";
import { closeModal } from "../../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";

function ViewScheduleModal() {
  const state = useSelector((state) => state.modalState.viewScheduleModal);
  const dispatch = useDispatch();
  return (
    <Modal show={state} onHide={() => dispatch(closeModal())}>
      <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header>
      <Modal.Body>View Schedule</Modal.Body>
    </Modal>
  );
}

export default ViewScheduleModal;
