import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
function Progress({ progress, variant, stars, rating }) {
  return (
    <div className="d-flex align-items-center">
      <div
        style={{
          width: "35px",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        {stars} <i className="bi bi-star-fill"></i>
      </div>
      <div style={{ width: "180px", marginLeft: "10px" }}>
        <ProgressBar
          variant={variant}
          now={progress}
          style={{ height: "9px", margin: "5px 0" }}
        />
      </div>
      <div style={{ marginLeft: "10px" }}>{rating}</div>
    </div>
  );
}

export default Progress;
