import React from "react";
import LoginModal from "./LoginModal";
import ForgotPasswordModal from "./ForgotPassword";
import CongratulationModal from "./CongratulationModal";
import ViewScheduleModal from "./ViewScheduleModal";
import BookTrailModal from "./BookTrailModal";
import SignupModal from "./SignupModal";
import ChangeBookedLessonStatusModal from "./ChangeBookedLessonStatusModal";
import AddNewCardModal from "./AddNewCardModal";
import UpdateCardModal from "./UpdateCardModal";
import BookingCalendarModal from "./BookingCalendarModel";
import BookingLoginModal from "./BookingLoginModal";
import EditBookingSlotsModal from "./EditBookingSlotsModal";
function Modal() {
  return (
    <>
      <LoginModal />
      <SignupModal />
      <ForgotPasswordModal />
      <CongratulationModal />
      <ViewScheduleModal />
      <BookTrailModal />
      <ChangeBookedLessonStatusModal />
      <AddNewCardModal />
      <UpdateCardModal />
      <BookingCalendarModal />
      <BookingLoginModal />
      <EditBookingSlotsModal />
    </>
  );
}
export default Modal;
