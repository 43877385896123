import React, { useState, useRef } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks
} from "date-fns";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
//import { closeModals } from "./features/modal/modalSlice";
import { closeModal } from "./features/modal/modalSlice";
import { useNavigate } from "react-router-dom";
import { getTimeSlots, bookLesson } from "./services/tutors";
import { errorNotify } from "./ui/toasters";

const Calendar = (props) => {  
  const { showDetailsHandle } = props;
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [avaiableSlot, setAvaiableSlot] = useState(null);
  const [sendBookingSlot, setSendBookingSlot] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showConfirmBtn, setShowConfirmBtn] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const confirmTime = () => {
    if (sendBookingSlot != null) { 
      // obj to end in api
      const obj = {
        tutor_id: sendBookingSlot.teacher_id,
        language_id: props.language_id,
        book_time: sendBookingSlot.start_time_utc,
        available_id: sendBookingSlot.id,
        //date: date.value,
      };
      // api hit
      setLoader(true);
      bookLesson(obj)
        .then((res) => { 
          setSendBookingSlot(null);
          dispatch(closeModal());
          navigate(`/checkout/${res.data.book_lesson.uuid}`);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          errorNotify("Something went wrong");
        });

    } else {
      errorNotify("Please select available slot");
    }

  };

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
    showDetailsHandle(dayStr);
  };

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const sendSlotDetail = (sendSlotDetail) => {  
    setSendBookingSlot(sendSlotDetail);
  }

  const getDaySlot = (slotFormattedDate) => { 
    if (props?.data?.length > 0) {
      return props.data.map((item, index) => {
        if (item.slot_date == slotFormattedDate && item.booking == 0) {
          // className={`slot-time ${active ? "active" : ""}`}
          return <div id={item.id} className={`slot-time ${index === activeIndex ? "active" : ""}`} key={item.id} onClick={() => {
            sendSlotDetail(item)
            handleClick(index)
          }}>{item.start_time_utc.slice(0, 5)}</div>
        }
      });
    }
  }

  const renderHeader = () => {
    const dateFormat = "MMM yyyy"; 
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          {/* <div className="icon" onClick={() => changeMonthHandle("prev")}>
            prev month
          </div> */}
        </div>
        <div className="col col-center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end">
          {/* <div className="icon" onClick={() => changeMonthHandle("next")}>next month</div> */}
        </div>
      </div>
    );
  };
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days row">{renderFooter()}{days}</div>;
  };


  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const slotDateFormat = "Y-MM-dd";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    let slotFormattedDate = "";
    let timeSlot = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        slotFormattedDate = format(day, slotDateFormat);
        timeSlot = getDaySlot(slotFormattedDate);
        const cloneDay = day;
        days.push(
          <div
            className={`col cell`}
            key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "ccc dd MMM yy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <span className="number">{formattedDate}</span>
            {timeSlot}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  const renderFooter = () => {
    return (
      <div className="header row flex-middle middle-header pb-2">
        <div className="col1">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            prev week
          </div>
        </div>
        <div className="col1">{currentWeek}</div>
        <div className="col1" onClick={() => changeWeekHandle("next")}>
          <div className="icon">next week</div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="calendar">
        {renderHeader()}
        {renderDays()}
        {renderCells()}
        {/* {renderFooter()} */}
      </div>
      <div className="mt-4 float-right" >
        <Button onClick={confirmTime} >Confirm Time</Button>
      </div>
    </>
  );
};

export default Calendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */