import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getBookLessonListing } from "../../../services/tutors";
import { errorNotify } from "../../../ui/toasters";
import Pagination from "react-bootstrap/Pagination";
// custom spinner
import CustomSpinner from "../../../ui/loaders/CustomSpinner";
import Listing from "./Listing";
import Card from "react-bootstrap/Card";
function BookingList() {
  const [statusChange, setStatusChange] = useState(false);
  const state = useSelector((state) => state.user.value);
  const [loader, setLoader] = useState(true);
  const [from, setFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  // pagination
  const [bookings, setBookings] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalBookings, setTotalBookings] = useState(0);
  const pageNum = Math.ceil(totalBookings / 10);
  let pages = [];
  for (let number = 1; number <= pageNum; number++) {
    pages.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => setActivePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  // get booked lessons list
  useEffect(() => {
    setLoader(true);
    const fetch = async () => {
      await getBookLessonListing(state?.id, activePage)
        .then((res) => {
          setBookings(res.data.book_lesson.data);
          setCurrentPage(res.data.book_lesson.current_page);
          setFrom(res.data.book_lesson.from);
          setTotalBookings(res.data.book_lesson.total);
          setLoader(false);
          window.scroll(0, 0);
        })
        .catch((err) => {
          errorNotify("Something went wrong");
          setLoader(false);
        });
    };
    fetch();
  }, [activePage, state?.id, statusChange]);
  return (
    <>
      <Card>
        <Card.Header>Bookings List</Card.Header>
        {loader ? (
          <CustomSpinner />
        ) : (
          <>
            <Card.Body>
              <Listing
                bookings={bookings}
                from={from}
                setStatusChange={setStatusChange}
              />
              {bookings.length > 0 && (
                <Pagination>
                  <Pagination.Prev
                    onClick={() => setActivePage((prev) => prev - 1)}
                    disabled={currentPage === 1}
                  />
                  {pages}
                  <Pagination.Next
                    onClick={() => setActivePage((prev) => prev + 1)}
                    disabled={currentPage === pageNum}
                  />
                </Pagination>
              )}

              {bookings.length === 0 && (
                <h3 className="text-center mt-5">No Bookings Found</h3>
              )}
            </Card.Body>
          </>
        )}
      </Card>
    </>
  );
}

export default BookingList;
