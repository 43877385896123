import React from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Details from "../Dashboard/Details";
import BookingList from "../Dashboard/BookingList";
import AvailabilityTab from "./AvailabilityTab";
import BookingSlot from "../Dashboard/BookingSlot";
import { useState } from "react";
import { getTutor } from "../../../services/tutors";
import { useEffect } from "react";

function Availability() {
  const navigate = useNavigate();
  return (
    <main id="main" className="container mt-5 pt-3">
      <section className="tabs_section">
        <Tab.Container defaultActiveKey="availability" className="student_tabs">
          <Row>
            <Col sm={3}>
              <Nav
                variant="tabs"
                className="flex-column card custom_padding_class"
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="dashboard"
                    onClick={() => navigate("/tutor/dashboard")}
                  >
                    Dashboard
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="bookings-listing"
                    onClick={() => navigate("/tutor/bookings-listing")}
                  >
                    Booking's List
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="availability"
                    onClick={() => navigate("/tutor/availability")}
                  >
                    Availability
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="booking-slots"
                    onClick={() => navigate("/tutor/booking-slots")}
                  >
                    Booking Slots
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  {/* <Nav.Link eventKey="messages">Messages</Nav.Link> */}
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="details">
                  <Details />
                </Tab.Pane>
                <Tab.Pane eventKey="bookings-listing">
                  <BookingList />
                </Tab.Pane>
                <Tab.Pane eventKey="availability">
                  {/* <Availability /> */}
                  <AvailabilityTab />
                </Tab.Pane>
                <Tab.Pane eventKey="booking-slots">
                  <BookingSlot />
                </Tab.Pane>
                <Tab.Pane eventKey="messages">
                  {/* <Message /> */}
                  Message
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </section>
    </main>
  );
}

export default Availability;
