import React from "react";
import RatingBlock from "./RatingBlock";
import RatingBreakdown from "./RatingBreakdown";
import Review from "./Review";

function Reviews() {
  return (
    <section id="reviews" className="tutor-reviews card-body-section">
      <h3>What students say</h3>
      <div className="container">
        <div className="row">
          <div className="col">
            <RatingBlock />
          </div>
          <div className="col">
            <RatingBreakdown /> 
          </div>
        </div>
        <hr />
        <Review/>
      </div>
      {/* <!-- /container --> */}
    </section>
  );
}

export default Reviews;
