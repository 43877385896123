import React from "react";
import Select from "react-select";
import { components } from "react-select";
import flag from "../../assets/img/spain.svg";
const { Option } = components;

// custom styling
const customStylesForLanguageDropDown = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? null : null,
  }),
  menu: (base) => ({
    ...base,
    boxShadow: "none",
    // override border radius to match the box
    borderRadius: ".5rem",
    height: "12rem",
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    border: "none",
    height: "12rem",
  }),
  option: (base, { isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? "#1a2e9e" : "",
    "&:hover": {
      backgroundColor: "#1a2e9e",
      color: "white",
    },
  }),
};
const IconOption = (props) => (
  <Option {...props}>
    <div>
      <img src={flag} alt="flag" style={{ marginRight: "1rem" }} />
      <span>{props.data.label}</span>
    </div>
  </Option>
);

function SelectLanguage({
  language,
  languages,
  setLanguage,
  setDropdown,
  dropdown,
}) {
  return (
    <div
      className="input-group"
      style={{ position: "relative" }}
      onClick={(e) => setDropdown("language")}
    >
      <label className="label">Selected Language</label>
      <div id="select-language">{language?.label || "Select a Language"}</div>
      {dropdown === "language" && (
        <div className="language-select-box">
          <Select
            defaultValue={language}
            className="react-select__control"
            components={{
              DropdownIndicator: () => null,
              Option: IconOption,
            }}
            menuIsOpen
            options={languages}
            onChange={(opt) => setLanguage(opt)}
            placeholder="Select language"
            cacheOptions
            defaultOptions
            styles={customStylesForLanguageDropDown}
          />
        </div>
      )}
    </div>
  );
}

export default SelectLanguage;
