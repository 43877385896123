import ReactDom from "react-dom";
import "./customLoader.css";
// backdrop and loader for image uploading
const CustomLoader = (props) => {
  return ReactDom.createPortal(
    <div className="backdrop">
      <div className="uploading_text">Uploading... {props.percentage} %</div>
    </div>,
    document.getElementById("modal")
  );
};

export default CustomLoader;
