import React from "react";
import profile from "../../../assets/img/profilepic.png";
import Badge from "react-bootstrap/Badge";

function Image({ imgUrl }) {
  return (
    <div>
      <div className="text-center">
        <img
          className="rounded-circle"
          alt="img"
          height={60}
          width={60}
          src={imgUrl ? imgUrl : profile}
        />
      </div>
      <div className="text-center bagde-container">
        <Badge pill bg="light" text="dark" className="bagde">
          <i className="bi bi-star-fill"></i> 5
        </Badge>
      </div>
    </div>
  );
}

export default Image;
