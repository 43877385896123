import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import UpdateCard from "../../stripe/UpdateCard";
import { closeModal } from "../../features/modal/modalSlice";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripe = loadStripe(PUBLIC_KEY);

function UpdateCardModal() {
  // modal states
  const state = useSelector((state) => state.modalState.updateCardModal);
  const dispatch = useDispatch();
  return (
    <Modal
      id="loginForm"
      show={state?.value}
      onHide={() => dispatch(closeModal())}
      centered
    >
      <Button
        className="btn-close"
        data-bs-dismiss="modal"
        onClick={() => dispatch(closeModal())}
        aria-label="Close"
      ></Button>
      <Modal.Body>
        <div className="m-3 mt-0">
          <Elements stripe={stripe}>
            <UpdateCard setUpdateCard={state?.data} />
          </Elements>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateCardModal;
