import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo1 from "../../assets/img/Logo-1.svg";
import student from "../../assets/img/icon-1.svg";
import tutor from "../../assets/img/icon-2.svg";
import enterprise from "../../assets/img/icon-3.svg";
import { useDispatch, useSelector } from "react-redux";
import { openLoginModal,closeModal } from "../../features/modal/modalSlice";

function SignupModal() {
  const dispatch = useDispatch();
  // modal states
  const state = useSelector((state) => state.modalState.signupModal);
  return (
    <Modal
      style={{ padding: "10px" }}
      id="loginPopup"
      show={state}
      onHide={() => dispatch(closeModal())}
    >
      <Button
        className="btn-close"
        data-bs-dismiss="modal"
        onClick={() => dispatch(closeModal())}
        aria-label="Close"
      ></Button>
      <Modal.Header>
        <Link to="#" className="logo">
          <img src={logo1} alt="" className="img-fluid" />
        </Link>
      </Modal.Header>
      <Modal.Body>
        <Link to="/student-signup" onClick={() => dispatch(closeModal())}>
          <div className="icon-box">
            <div className="icon">
              <img src={student} className="img-fluid" alt="" />
            </div>
            <p>Sign up as a</p>
            <h4>Student</h4>
          </div>
        </Link>
        <Link to="/tutor-signup" onClick={() => dispatch(closeModal())}>
          <div className="icon-box">
            <div className="icon">
              <img src={tutor} className="img-fluid" alt="" />
            </div>
            <p>Sign up as a</p>
            <h4>Tutors</h4>
          </div>
        </Link>
        <Link to="/enterprise-signup" onClick={() => dispatch(closeModal())}>
          <div className="icon-box">
            <div className="icon">
              <img src={enterprise} className="img-fluid" alt="" />
            </div>
            <p>Sign up as a</p>
            <h4>Enterprise</h4>
          </div>
        </Link>
      </Modal.Body>
      <Modal.Footer>
        <p>
          Already have an account?{" "}
          <Link to="#" onClick={() => dispatch(openLoginModal())}>
            Sign in
          </Link>
        </p>
      </Modal.Footer>
    </Modal>
  );
}

export default SignupModal;
