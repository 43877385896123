import React from "react";

function WorkExperience() {
  return (
    <div
      className="tab-pane"
      id="WorkExperience"
      role="tabpanel"
      aria-labelledby="WorkExperience-tab"
    >
      <div className="row">
        <div className="col-6 col-md-3">
          <p>1981 — 1982</p>
        </div>
        <div className="col-12 col-md-9">
          <p>
            University of Pennsylvania (USA)Masters of Science in Engineering
          </p>
        </div>
      </div>
    </div>
  );
}

export default WorkExperience;
