import React from "react";
import Table from "react-bootstrap/Table";

function Total({ total }) {
  return (
    <Table borderless>
      <tbody>
        <tr>
          <td>Total</td>
          <td className="text-right">${total.toFixed(2)}</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default Total;
