import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo1 from "../../assets/img/Logo-1.svg";
import { useForm } from "react-hook-form";
import {
  openForgotModal,
  openSignupModal,
  closeModal,
  openBookingCalendarModal,
} from "../../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import toasters
import { errorNotify } from "../../ui/toasters";
// import login service
import { login } from "../../services/common";
import reportWebVitals from "../../reportWebVitals";
function LoginModal() {
  // for navigate

  const navigate = useNavigate();
  // modal states
  const state = useSelector((state) => state.modalState.loginModal);
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  // handle login
  const onSubmit = async (form) => {
    login(form)
      .then((response) => {
        const data = response.data;
        localStorage.setItem("token", JSON.stringify(data.access_token));
        localStorage.setItem("username", JSON.stringify(data.user.username));
        localStorage.setItem("name", JSON.stringify(data.user.name));
        localStorage.setItem("user_email", JSON.stringify(data.user.email));
        localStorage.setItem("user_role", JSON.stringify(data.user.role_id));
        if (data.user.role_id === 3) {
          if (location.pathname === "/tutors" && state.data) {
            navigate("/tutors");
            dispatch(closeModal());
            dispatch(openBookingCalendarModal({ data: state?.data?.data }));
          } else if (location.pathname === "/tutor/details" && state.data) {
            navigate(`/tutor/details${location.search}`);
            dispatch(closeModal());
            dispatch(openBookingCalendarModal({ data: state?.data?.data }));
          } else {
            navigate("/student/dashboard/setting");
            dispatch(closeModal());
          }
        }
        if (data.user.role_id === 2) {
          dispatch(closeModal());
          navigate("/tutor/dashboard");
        }
        if (data.user.role_id === 4) {
          dispatch(closeModal());
          navigate("/enterprise/dashboard");
        }
        reset();
      })
      .catch((error) => {
        let err;
        if (error.response.status === 400) {
          err = error.response.data.message;
        } else if (error.response.status === 422) {
          err = Object.values(error.response.data?.errors)[0][0];
        } else {
          err = "Something went Wrong";
        }
        errorNotify(err);
      });
  };
  return (
    <Modal
      id="loginForm"
      show={state.value}
      onHide={() => dispatch(closeModal({ value: null, data: false }))}
    >
      <Button
        className="btn-close"
        data-bs-dismiss="modal"
        onClick={() => {
          reset();
          dispatch(closeModal({ value: null, data: false }));
        }}
        aria-label="Close"
      ></Button>
      <Modal.Header className="m-2">
        <Link to="#" className="logo">
          <img src={logo1} alt="" className="img-fluid" />
        </Link>
      </Modal.Header>
      <Modal.Body>
        <div className="m-3 mt-0">
          <p>Welcome back</p>
          <h2>Login to your account</h2>
          {/* login form */}
          <form
            action=""
            className="signin-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-group mb-3">
              <label className="" htmlFor="email">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter email address"
                autoComplete="off"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <p className="text-danger">Email is required.</p>
              )}
            </div>
            <div className="form-group mb-3">
              <label className="" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                autoComplete="off"
                {...register("password", { required: true })}
              />
              {errors.password && (
                <p className="text-danger">Password is required.</p>
              )}
            </div>
            <div className="form-group d-md-flex mb-4 mt-2">
              <div className="w-100 text-left">
                <label className="checkbox-wrap checkbox-primary mb-0">
                  Remember Me
                  <input type="radio" id="f-option" name="selector" />
                  <span className="check"></span>
                </label>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="w-100 text-right"
                onClick={() => dispatch(openForgotModal())}
              >
                <Link to="#" className="forgot-link">
                  Forgot password ?
                </Link>
              </div>
            </div>
            <div className="form-group mb-4">
              <button
                type="submit"
                className="form-control btn btn-primary rounded submit px-3"
                style={{
                  backgroundColor: "#1a2e9e",
                }}
              >
                Login
              </button>
            </div>
            <div>
              Don't have an account?{" "}
              <Link to="#" onClick={() => dispatch(openSignupModal())}>
                Signup
              </Link>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;