import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Review() {
  return (
    <div className="row">
      <div className="col">
        <div className="review-block">
          <div className="row">
            <div className="col-sm-3">
              <img
                src="http://dummyimage.com/60x60/666/ffffff&text=No+Image"
                className="img-rounded"
                alt="img"
              />
              <div className="review-block-name">
                <Link to="">nktailor</Link>
              </div>
              <div className="review-block-date">
                January 29, 2016
                <br />1 day ago
              </div>
            </div>
            <div className="col-sm-9">
              <div className="review-block-rate">
                <Button
                  variant="warning"
                  size="sm"
                  className="c_white me-1"
                  disabled
                >
                  <i className="bi bi-star-fill"></i>
                </Button>
                <Button
                  variant="warning"
                  size="sm"
                  className="c_white me-1"
                  disabled
                >
                  <i className="bi bi-star-fill"></i>
                </Button>
                <Button
                  variant="warning"
                  size="sm"
                  className="c_white me-1"
                  disabled
                >
                  <i className="bi bi-star-fill"></i>
                </Button>
                <Button
                  variant="light"
                  size="sm"
                  className="me-1"
                  disabled
                >
                  <i className="bi bi-star-fill"></i>
                </Button>
                <Button
                  variant="light"
                  size="sm"
                  className="me-1"
                  disabled
                >
                  <i className="bi bi-star-fill"></i>
                </Button>
              </div>
              <div className="review-block-title">this was nice in buy</div>
              <div className="review-block-description">
                this was nice in buy. this was nice in buy. this was nice in
                buy. this was nice in buy this was nice in buy this was nice in
                buy this was nice in buy this was nice in buy
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;
