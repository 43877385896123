import React from "react";
import Select from "react-select";
import flag from "../../assets/img/spain.svg";
import { components } from "react-select";

// custom style for country dropdown
const customStylesForCountryDropDown = {
  control: (base) => ({
    ...base,
    maxHeight: "38px",
    overflow: "hidden",
    marginTop: "5px",
  }),
  menu: (base) => ({
    ...base,
    boxShadow: "none",
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    position: "relative !important",
    padding: 0,
    border: "none",
    height: "12rem",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    position: "absolute",
    right: "0",
    top: "0",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "none",
    alignItems: "center",
    display: "flex",
  }),
  valueContainer: (base) => ({
    ...base,
    display: "flex",
  }),
};
// custom options for drop down
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="custom-checkbox">
          <label className="container-check">
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
              className="m_7"
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="m_7 display_none"
        />
        <img src={flag} alt="img" className="m_7" />
        <label className="m_7">{props.label}</label>
      </components.Option>
    </div>
  );
};
function TutorOrigin({ setDropdown, dropdown, countries, setCountry }) {
  return (
    <div className="input-group" onClick={(e) => setDropdown("country")}>
      <label className="label">Tutor origin</label>
      <div id="select-country">Select a country</div>
      {dropdown === "country" && (
        <div className="country-select-box">
          <Select
            options={countries}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            menuIsOpen
            components={{
              DropdownIndicator: () => null,
              Option: Option,
            }}
            placeholder=" Select country"
            onChange={(opt) => setCountry(opt)}
            styles={customStylesForCountryDropDown}
          />
        </div>
      )}
    </div>
  );
}

export default TutorOrigin;
