import React from "react";

function Verification() {
  return (
    <div className="text-center mt-5">
      <p>
        Thank you for completing registration! We’ve received your application
        and are currently reviewing it. You will receive an email with the
        status of your application within 5 business days.
      </p>
    </div>
  );
}

export default Verification;
