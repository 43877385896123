import React from "react";
import { useNavigate } from "react-router";
import "./enterprise.css";
import axios from "axios";
import { Link } from "react-router-dom";
// library for handling validation on form
import { useForm, Controller } from "react-hook-form";

// library for toaster
import toast, { Toaster } from "react-hot-toast";

// library for phone number with country code
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const errorNotify = (msg) =>
  toast(msg, {
    duration: 3000,
    position: "top-right",
  });

function EnterpriseSignup(props) {
  const { setEmail } = props;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control,
  } = useForm({ mode: "onChange" });
  const onSubmit = async (data) => {
    const url = baseUrl + "/register/enterprise";
    // enterprise role ID
    const headers = { Accept: "application/json" };
    await axios
      .post(url, data, { headers })
      .then(async (response) => {
        setEmail({
          email: data.email,
          type: 4,
        });
        navigate("/congratulation");
      })
      .catch((error) => {
        let err;
        if (error.response.status === 422) {
          err = Object.values(error.response.data.errors)[0][0];
        } else if (error.response.status === 400) {
          err = error.response.data.message;
        } else {
          err = "Something went wrong";
        }
        errorNotify(err);
      });
  };
  return (
    <>
      <main id="main" className="bg-inner">
        <section className="student-signup-page why-us">
          <div className="container" style={{ paddingTop: "30px" }}>
            <div className="row">
              <div className="col-lg-6 d-flex flex-column align-items-stretch  order-1 order-lg-1">
                <div className="content">
                  <h3>Empower your team with language training</h3>
                  <p>
                    Day Learning training designed for teams and businesses
                    offering made-to-measure language learning with online
                    tutors.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 d-flex flex-column align-items-stretch order-2 order-lg-2">
                <div className="student-signup-wrapper">
                  <div className="student-form-header">
                    <div className="w-100">
                      <h3 className="title mb-3">Sign up as an Enterprise </h3>
                    </div>
                    <div className="w-100">
                      <p className="subtitle mb-4">
                        Join the world biggest community.
                      </p>
                    </div>
                  </div>
                  <form
                    action=""
                    className="signin-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-group">
                      <label htmlFor="first_name">First name</label>
                      <input
                        placeholder="First Name"
                        autoComplete="off"
                        type="text"
                        htmlFor="first_name"
                        className="form-control"
                        {...register("first_name", { required: true })}
                      ></input>
                      {errors.first_name?.type === "required" && (
                        <span className="text-danger">
                          First name is required
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="last_name">Last name</label>
                      <input
                        placeholder="Last Name"
                        autoComplete="off"
                        type="text"
                        name="last_name"
                        className="form-control"
                        {...register("last_name", { required: true })}
                      ></input>
                      {errors.last_name?.type === "required" && (
                        <span className="text-danger">
                          Last name is required
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="companyEmail">Buisness Email</label>
                      <input
                        placeholder="Buisness Email"
                        autoComplete="off"
                        type="text"
                        name="email"
                        className="form-control"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                        })}
                      ></input>
                      {errors.email?.type === "required" && (
                        <span className="text-danger">
                          Company Email is required
                        </span>
                      )}
                      {errors.email?.type === "pattern" && (
                        <span className="text-danger">Invalid email</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="job_title">Job Title (Optional)</label>
                      <input
                        placeholder="Job Title"
                        autoComplete="off"
                        type="text"
                        name="job_title"
                        className="form-control"
                        {...register("job_title")}
                      ></input>
                    </div>
                    <div className="form-group">
                      <label htmlFor="companyName">Company</label>
                      <input
                        placeholder="Company"
                        type="text"
                        autoComplete="off"
                        name="company_name"
                        className="form-control"
                        {...register("company_name", { required: true })}
                      ></input>
                      {errors.company_name?.type === "required" && (
                        <span className="text-danger">
                          Company name is required
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        placeholder="Password"
                        type="password"
                        name="password"
                        className="form-control"
                        {...register("password", {
                          required: true,
                          maxLength: 50,
                          pattern:
                            /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,50}/,
                        })}
                      ></input>
                      {errors.password?.type === "required" && (
                        <span className="text-danger">
                          Password is required
                        </span>
                      )}
                      {errors.password?.type === "maxLength" && (
                        <span className="text-danger">Maxlength exceeded</span>
                      )}
                      {errors.password?.type === "pattern" && (
                        <span className="text-danger">
                          Password must be alteast 8 character long must contain
                          1 letter , 1 number and 1 special character
                        </span>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="phone">Phone Number</label>
                      <Controller
                        control={control}
                        name="company_phone"
                        rules={{
                          required: {
                            value: true,
                            message: "Phone number is required",
                          },
                        }}
                        render={({ field: { ref, ...field } }) => (
                          <PhoneInput
                            {...field}
                            inputExtraProps={{
                              ref,
                              required: true,
                            }}
                            country={"us"}
                            countryCodeEditable={true}
                          />
                        )}
                      />
                      {errors.company_phone?.type === "required" && (
                        <span className="text-danger">
                          Phone Number is required
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <button
                        className="form-control btn btn-primary rounded submit px-3"
                        disabled={!isDirty || !isValid}
                      >
                        Sign up as Enterprise
                      </button>
                    </div>

                    <Toaster
                      toastOptions={{
                        style: {
                          background: "red",
                          color: "white",
                        },
                      }}
                    />
                    <p className="terms">
                      By Clicking "Sign Up" button I agree to DayLearing <Link to="/terms-and-conditions">Terms
                      Of Use</Link> and <Link to="/privacy-policy">Privacy Statement</Link>.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default EnterpriseSignup;
