import React, { useState } from "react";
// error & success toaster
import { errorNotify } from "../../ui/toasters";
// availability & book trial service
import { getTimeSlots, bookLesson } from "../../services/tutors";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { closeModal } from "../../features/modal/modalSlice";

// if no time slots available
const NoTimeSlotsAvailable = () => {
  return (
    <p>No time-slots available on this day. Please select different day</p>
  );
};

function BookTrail({
  state,
  date,
  setDate,
  setShowNoTimeSlotsComp,
  setTimeSlots,
  setSelectedSlot,
  showNoTimeSlotsComp,
  timeSlots,
  selectedSlot,
  dispatch,
}) {
  const navigate = useNavigate();
  // to filter timeslots for particular day
  const days = [
    { day: "Monday", value: "1" },
    { day: "Tuesday", value: "2" },
    { day: "Wednesday", value: "3" },
    { day: "Thursday", value: "4" },
    { day: "Friday", value: "5" },
    { day: "Saturday", value: "6" },
    { day: "Sunday", value: "7" },
  ];

  // loader state
  const [loader, setLoader] = useState(false);
  const searchForTimeSlots = () => {
    const dateValid = checkDateError();
    if (dateValid) {
      setLoader(true);
      // object to send in api
      const obj = {
        tutor_id: state.data.data,
        search_date: date.value,
      };
      getTimeSlots(obj)
        .then((res) => {
          const data = res.data.data;
          // get day
          const day = days.find((i) => {
            return i.day === date.day;
          });
          // get timeslots for day
          const timeslots = data.filter((i) => {
            return i.day === day?.value;
          });
          if (timeslots.length > 0) {
            setShowNoTimeSlotsComp(false);
            setTimeSlots(timeslots);
          } else {
            setShowNoTimeSlotsComp(true);
            setTimeSlots(timeslots);
          }
          setLoader(false);
        })
        .catch((err) => {errorNotify("Something went wrong")});
    } else {
      return false;
    }
  };
  // check for date error
  const checkDateError = () => {
    const { value } = date;
    if (value === "") {
      setDate({
        ...date,
        error: true,
        message: "Date is required",
        day: null,
      });
      setTimeSlots([]);
      return false;
    } else {
      setDate({
        ...date,
        error: false,
        message: null,
      });
      return true;
    }
  };
  // handle date change
  const handleDateChange = (e) => {
    const { value } = e.target;
    const day = moment(value).format("dddd");
    setDate({
      value: value,
      error: false,
      message: null,
      day: day,
    });
  };

  // handle timeslot  button click
  const timeSlotSelect = (time) => {
    setSelectedSlot({
      value: time,
      selected: true,
    });
  };
  // handle confirm time
  const confirmTime = () => {
    // obj to end in api
    const obj = {
      tutor_id: state.data.data,
      language_id: state.data.language.id,
      book_time: selectedSlot.value,
      date: date.value,
    };
    // api hit
    setLoader(true);
    bookLesson(obj)
      .then((res) => {
        dispatch(closeModal());
        setTimeSlots([]);
        setDate({
          value: "",
          error: "",
          message: "",
        });
        setSelectedSlot({
          value: null,
          selected: false,
        });
        navigate(`/checkout/${res.data.book_lesson.uuid}`);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        errorNotify("Something went wrong");
      });
  };
  const today = new Date().toISOString().split("T")[0];
  return (
    <div>
      <div className="row">
        <h5>Select day </h5>
        <div className="col">
          <input
            type="date"
            min={today}
            className="form-control"
            onChange={handleDateChange}
            defaultValue={date?.value}
          />
          {date?.error && <span className="text-danger">{date?.message}</span>}
        </div>
        <div className="col">
          <button
            className="form-control btn btn-primary rounded submit px-3"
            style={{
              backgroundColor: "#1a2e9e",
            }}
            onClick={searchForTimeSlots}
          >
            Search
          </button>
        </div>
      </div>

      {loader ? (
        <div className="text-center mt-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="mt-4">
            {timeSlots.length > 0 && (
              <>
                <h5>Found {timeSlots.length} time slots</h5>
                {timeSlots.map((i, index) => {
                  return (
                    <Button
                      key={index}
                      className="timeslot_btn"
                      onClick={() =>
                        timeSlotSelect(i?.start_time_utc.slice(0, 5))
                      }
                    >
                      {`${i?.start_time_utc.slice(
                        0,
                        5
                      )} - ${i?.end_time_utc.slice(0, 5)}`}
                    </Button>
                  );
                })}
                <div className="mt-4">
                  <Button
                    disabled={!selectedSlot.selected}
                    className="timeslot_btn form-control"
                    onClick={confirmTime}
                  >
                    Confirm Time
                  </Button>
                </div>
              </>
            )}
            {showNoTimeSlotsComp && <NoTimeSlotsAvailable />}
          </div>
        </>
      )}
    </div>
  );
}

export default BookTrail;
