import React from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
const VideoCallBaseUrl = process.env.REACT_APP_CLASS_CALL_LINK;
// get Button accoring to status
const BookingStatus = (status) => {
  switch (status) {
    case "1":
      return "Pending";
    case "2":
      return "Approved";
    case "3":
      return "Declined";
    default:
      return "Pending";
  }
};

const PaymentStatus = (status) => {
  switch (status) {
    case "0":
      return "Unpaid";
    case "1":
      return "Paid";
    default:
      return "Unpaid";
  }
};
const connectCall = (token) => {
  const url = VideoCallBaseUrl + token;
  window.open(url, "_blank", "noopener,noreferrer");
};
function Listing({ bookings, from }) {
  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-dark"
        style={{ marginTop: "10%" }}
      >
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link
                  to="/student/dashboard/message"
                  className="nav-link"
                  name="message"
                >
                  Message
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/student/dashboard/my-lesson"
                  className="nav-link"
                  name="myLesson"
                >
                  My Lesson
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/student/dashboard/setting"
                  className="nav-link"
                  name="setting"
                >
                  Settings
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container mt-5 mb-5">
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>Teacher Name</th>
              <th className="text-center">Book Time</th>
              <th className="text-center">Date</th>
              <th className="text-center">Booking Status</th>
              <th className="text-center">Payment Status</th>
              <th className="text-center">Call</th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{from + index}</td>
                  <td>{`${item.book_tutor.first_name} ${item.book_tutor.last_name}`}</td>
                  <td className="text-center">{item.book_time.slice(0, 5)}</td>
                  <td className="text-center">{item.date}</td>
                  <td className="text-center">
                    {BookingStatus(item.booking_status)}
                  </td>
                  <td className="text-center">
                    {PaymentStatus(item.payment_status)}
                  </td>
                  <td className="text-center">
                    <button
                      onClick={() => connectCall(item.video_token)}
                      className="btn"
                      disabled={item.booking_status === "2" ? false : true}
                    >
                      {item.booking_status === "2" ? (
                        <i className="bi bi-camera-video-fill video_icon"></i>
                      ) : (
                        <i className="bi bi-camera-video-off-fill"></i>
                      )}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default Listing;
