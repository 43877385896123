import React, { useState } from "react";
import CustomSpinner from "../../../../ui/loaders/CustomSpinner";
import { getBookedLessonListing } from "../../../../services/student";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { errorNotify } from "../../../../ui/toasters";
import Listing from "./Listing";
import Pagination from "react-bootstrap/Pagination";
const MyLesson = (props) => {
  const state = useSelector((state) => state.user.value);
  const [bookings, setBookings] = useState([]);
  const [from, setfrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // pagination
  const [totalBookings, setTotalBookings] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const pageNum = Math.ceil(totalBookings / 10);
  let pages = [];
  for (let number = 1; number <= pageNum; number++) {
    pages.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => setActivePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  // loader state
  const [loader, setLoader] = useState(true);
  // get booked lessons list
  useEffect(() => {
    setLoader(true);
    const fetch = async () => {
      await getBookedLessonListing(state?.id, activePage)
        .then((res) => {
          setBookings(res.data.book_lesson.data);
          setfrom(res.data.book_lesson.from);
          setCurrentPage(res.data.book_lesson.current_page);
          setTotalBookings(res.data.book_lesson.total);
          setLoader(false);
          window.scroll(0,0);
        })
        .catch((err) => {
          errorNotify("Something went wrong");
        });
    };
    fetch();
  }, [activePage, state?.id]);

  return (
    <>
      {loader ? (
        <CustomSpinner />
      ) : (
        <>
          <Listing bookings={bookings} from={from} />
          {bookings.length > 0 && (
            <Pagination>
              <Pagination.Prev
                onClick={() => setActivePage((prev) => prev - 1)}
                disabled={currentPage === 1}
              />
              {pages}
              <Pagination.Next
                onClick={() => setActivePage((prev) => prev + 1)}
                disabled={currentPage === pageNum}
              />
            </Pagination>
          )}
          {bookings.length === 0 && (
            <h3 className="text-center mt-5">No Bookings Found</h3>
          )}
        </>
      )}
    </>
  );
};

export default MyLesson;
