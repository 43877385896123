import React from "react";

function DayLearningInsight({ notifications, setNotifications }) {
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    if(checked){
      setNotifications({
        ...notifications,
        [name]: '1'
      })
    }
    else{
      setNotifications({
        ...notifications,
        [name]: '0'
      })
    }
  };
  return (
    <div className="col-md-12 mb-4">
      <div className="form-check mt-sm-2">
        <input
          className="form-check-input"
          type="checkbox"
          onChange={handleCheckbox}
          name="product_improvements"
          defaultChecked={notifications.product_improvements==='1'}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Allow DayLearning team to contact me for product improvements
        </label>
      </div>
    </div>
  );
}

export default DayLearningInsight;
