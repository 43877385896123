import { configureStore } from '@reduxjs/toolkit'
import uploadingReducer from './features/uploading/uploadingSlice'
import regiterEmailReducer from './features/registerEmail/registerEmailSlice'
import stepSlice  from './features/Tutor/stepsSlice';
import  userSlice  from './features/storeUser/userSlice';
import  modalSlice  from './features/modal/modalSlice';

export const store = configureStore({
  reducer: {
    uploading:uploadingReducer,
    registerEmail:regiterEmailReducer,
    steps:stepSlice,
    user:userSlice,
    modalState:modalSlice,
  },
})