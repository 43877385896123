import React from "react";
import Intro from "../Intro/Intro";
import NavBar from "../Navbar/NavBar";
import About from "../About/About";
import Schedule from "../Schedule/Schedule";
import Reviews from "../Reviews/Reviews";
import Resume from "../Resume/Resume";
import Subject from "../Subject/Subject";

function BlogListing({ tutor }) {
  const {
    profile_image,
    first_name,
    last_name,
    bio,
    teaching_experience,
    teacher_education,
    teacher_certificate,
    native_language,
    teacher_languages,
    verified,
  } = tutor;
  return (
    <>
      {/* intro sectio  */}
      <Intro
        profile_image={profile_image}
        first_name={first_name}
        last_name={last_name}
        bio={bio}
        teaching_experience={teaching_experience}
        native_language={native_language}
        teacher_languages={teacher_languages}
        verified={verified}
      />
      {/* navbar */}
      <NavBar />
      {/* about section */}
      <About teaching_experience={teaching_experience} />
      {/* schedule section */}
      <Schedule />
      {/* review section */}
      <Reviews />
      {/* resume section */}
      <Resume
        teacher_education={teacher_education}
        teacher_certificate={teacher_certificate}
      />
      {/* subject section */}
      <Subject teacher_languages={teacher_languages} />
    </>
  );
}

export default BlogListing;
