import React from "react";
//import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo1 from "../../assets/img/Logo-1.svg";
import { useForm } from "react-hook-form";
import {
  openForgotModal,
  openSignupModal,
  closeModal,
} from "../../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import toasters
import { errorNotify } from "../../ui/toasters";
// import login service
import { login } from "../../services/common";

function Login() {
  // for navigate
  const navigate = useNavigate();
  // modal states
  const state = useSelector((state) => state.modalState.loginModal);
  const dispatch = useDispatch();
  // for login form validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  // handle login
  const onSubmit = async (form) => {
    // login service
    login(form)
      .then(async (response) => {
        dispatch(closeModal());
        const data = await response.data;
        if (data.access_token) {
          localStorage.setItem("token", JSON.stringify(data.access_token));
          localStorage.setItem(
            "first_name",
            JSON.stringify(data.user.first_name)
          );
          localStorage.setItem("username", JSON.stringify(data.user.username));
          localStorage.setItem("name", JSON.stringify(data.user.name));
          localStorage.setItem("user_email", JSON.stringify(data.user.email));
          localStorage.setItem("user_role", JSON.stringify(data.user.role_id));
          reset();
          if (JSON.parse(localStorage.getItem("user_role")) === 3) {
            setTimeout(() => {
              navigate("/student/dashboard/setting");
            }, 1000);
          } else if (JSON.parse(localStorage.getItem("user_role")) === 2) {
            setTimeout(() => {
              navigate("/tutor/dashboard");
            }, 1000);
          } else if (JSON.parse(localStorage.getItem("user_role")) === 4) {
            setTimeout(() => {
              navigate("/enterprise/dashboard");
            }, 1000);
          } else {
            return false;
          }
        } else {
        }
      })
      .catch((error) => {
        let err;
        if (error.response.status === 400) {
          err = error.response.data.message;
        } else if (error.response.status === 422) {
          err = Object.values(error.response.data?.errors)[0][0];
        } else {
          err = "Something went Wrong";
        }
        errorNotify(err);
      });
  };
  return (
    <section className="container mt-5 align-items-center">
      <div className="row d-flex justify-content-center mt-3">
        <div className="col-lg-6">
          <Card>
            <form
              action=""
              className="signin-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Card.Body>
                <Card.Title className="text-center mb-3">
                  <h2>Login to your account</h2>
                </Card.Title>
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Enter email address"
                  autoComplete="off"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <p className="text-danger">Email is required.</p>
                )}
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control mb-3"
                  placeholder="Enter password"
                  autoComplete="off"
                  {...register("password", { required: true })}
                />
                {errors.password && (
                  <p className="text-danger">Password is required.</p>
                )}
                <Button
                  type="submit"
                  variant="primary"
                  className="form-control"
                >
                  Login
                </Button>
              </Card.Body>
            </form>
          </Card>
        </div>
      </div>
    </section>
  );
}

export default Login;
