import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { closeModal } from "../../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";

import BookTrail from "../../component/book-trail/BookTrail";

function BookTrailModal() {
  // no slots component state
  const [showNoTimeSlotsComp, setShowNoTimeSlotsComp] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  // slot state
  const [selectedSlot, setSelectedSlot] = useState({
    value: null,
    selected: false,
  });
  const dispatch = useDispatch();
  const state = useSelector((state) => state.modalState.bookTrailModal);
  // check date null error
  const [date, setDate] = useState({
    value: new Date().toISOString().substring(0, 10),
    error: "",
    message: "",
    day: "",
  });

  return (
    <Modal
      show={state?.value}
      onHide={() => {
        dispatch(closeModal());
        setShowNoTimeSlotsComp(false);
        setTimeSlots([]);
        setDate({
          value: new Date().toISOString().substring(0, 10),
          error: "",
          message: "",
        });
        setSelectedSlot({
          value: null,
          selected: false,
        });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Book Trial lesson</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BookTrail
          state={state}
          date={date}
          setDate={setDate}
          setShowNoTimeSlotsComp={setShowNoTimeSlotsComp}
          setTimeSlots={setTimeSlots}
          setSelectedSlot={setSelectedSlot}
          showNoTimeSlotsComp={showNoTimeSlotsComp}
          timeSlots={timeSlots}
          selectedSlot={selectedSlot}
          dispatch={dispatch}
        />
      </Modal.Body>
    </Modal>
  );
}

export default BookTrailModal;
