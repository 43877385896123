import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Subject() {
  return (
    <section id="subjects" className="tutor-subjects card-body-section">
      <h3>Subjects</h3>
      {/* <!-- Tabs navs --> */}
      <Tabs
        defaultActiveKey="english"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="english" title="English">
          <div
            className="tab-pane show active"
            id="ex1-tabs-1"
            role="tabpanel"
            aria-labelledby="ex1-tab-1"
          >
            <div className="row">
              <div className="col-12 col-md-9">
                <h4>Business English</h4>
                <p>
                  University of Pennsylvania (USA)Masters of Science in
                  Engineering
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-9">
                <h4>Business English</h4>
                <p>
                  University of Pennsylvania (USA)Masters of Science in
                  Engineering
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-9">
                <h4>Business English</h4>
                <p>
                  University of Pennsylvania (USA)Masters of Science in
                  Engineering
                </p>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    </section>
  );
}

export default Subject;
