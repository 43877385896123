import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loginModal: false,
  signupModal: false,
  forgotModal: false,
  congratulationModal: false,
  viewScheduleModal: false,
  bookTrailModal: {
    value: false,
    data: null,
  },
  changeBookedLessonStatusModal: {
    value: false,
    data: null,
  },
  addNewCardModal: {
    value: false,
    data: null,
  },
  updateCardModal: {
    value: false,
    data: null,
  },
  bookingCalendarModal: {
    value: false,
    data: null,
  },
  bookingLoginModal: {
    value: false,
  },
  editBookingModal: {
    value: false,
  },
};
export const modalSlice = createSlice({
  name: "modalState",
  initialState,
  reducers: {
    openLoginModal: (state, action) => {
      return {
        loginModal: {
          value: true,
          data: action.payload,
        },
        signupModal: false,
        forgotModal: false,
        congratulationModal: false,
        viewScheduleModal: false,
        bookTrailModal: {
          value: false,
          data: null,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: false,
          data: null,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
    openSignupModal: (state, action) => {
      return {
        loginModal: false,
        signupModal: true,
        forgotModal: false,
        congratulationModal: false,
        viewScheduleModal: false,
        bookTrailModal: {
          value: false,
          data: null,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: false,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
    openForgotModal: (state, action) => {
      return {
        loginModal: false,
        signupModal: false,
        forgotModal: true,
        congratulationModal: false,
        viewScheduleModal: false,
        bookTrailModal: {
          value: false,
          data: null,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: false,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
    openCongratulationModal: (state, action) => {
      return {
        loginModal: false,
        signupModal: false,
        forgotModal: false,
        congratulationModal: true,
        viewScheduleModal: false,
        bookTrailModal: {
          value: false,
          data: null,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: false,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
    openViewScheduleModal: (state, action) => {
      return {
        loginModal: false,
        signupModal: false,
        forgotModal: false,
        congratulationModal: false,
        viewScheduleModal: true,
        bookTrailModal: {
          value: false,
          data: null,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: false,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
    openBookTrailModal: (state, action) => {
      return {
        loginModal: false,
        signupModal: false,
        forgotModal: false,
        congratulationModal: false,
        viewScheduleModal: false,
        bookTrailModal: {
          value: true,
          data: action.payload,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: false,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
    openChangeBookedLessonStatusModal: (state, action) => {
      return {
        loginModal: false,
        signupModal: false,
        forgotModal: false,
        congratulationModal: false,
        viewScheduleModal: false,
        bookTrailModal: {
          value: false,
          data: action.payload,
        },
        changeBookedLessonStatusModal: {
          value: true,
          data: action.payload,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: false,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
    openAddPaymentCardmodal: (state, action) => {
      return {
        loginModal: false,
        signupModal: false,
        forgotModal: false,
        congratulationModal: false,
        viewScheduleModal: false,
        bookTrailModal: {
          value: false,
          data: null,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: true,
          data: action.payload,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: false,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
    openUpdateCardPaymentCardmodal: (state, action) => {
      return {
        loginModal: false,
        signupModal: false,
        forgotModal: false,
        congratulationModal: false,
        viewScheduleModal: false,
        bookTrailModal: {
          value: false,
          data: null,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: true,
          data: action.payload,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: false,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
    openBookingCalendarModal: (state, action) => {
      return {
        loginModal: false,
        signupModal: false,
        forgotModal: false,
        congratulationModal: false,
        viewScheduleModal: false,
        bookTrailModal: {
          value: false,
          data: null,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: true,
          data: action.payload,
        },
        bookingLoginModal: {
          value: false,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
    openBookingLoginModal: (state, action) => {
      return {
        loginModal: false,
        signupModal: false,
        forgotModal: false,
        congratulationModal: false,
        viewScheduleModal: false,
        bookTrailModal: {
          value: false,
          data: null,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: true,
          data: action.payload,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
    openEditBookingSlotsModal: (state, action) => {
      return {
        loginModal: false,
        signupModal: false,
        forgotModal: false,
        congratulationModal: false,
        viewScheduleModal: false,
        bookTrailModal: {
          value: false,
          data: null,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: false,
        },
        editBookingModal: {
          value: true,
        },
      };
    },

    closeModal: (state, action) => {
      return {
        loginModal: {
          value: false,
          data: null,
        },
        signupModal: false,
        forgotModal: false,
        congratulationModal: false,
        viewScheduleModal: false,
        bookTrailModal: {
          value: false,
          data: null,
        },
        changeBookedLessonStatusModal: {
          value: false,
          data: null,
        },
        addNewCardModal: {
          value: false,
          data: null,
        },
        updateCardModal: {
          value: false,
          data: null,
        },
        bookingCalendarModal: {
          value: false,
          data: null,
        },
        bookingLoginModal: {
          value: false,
        },
        editBookingModal: {
          value: false,
        },
      };
    },
  },
});

export const {
  openLoginModal,
  openSignupModal,
  openForgotModal,
  openCongratulationModal,
  openViewScheduleModal,
  openBookTrailModal,
  openChangeBookedLessonStatusModal,
  openAddPaymentCardmodal,
  openUpdateCardPaymentCardmodal,
  closeModal,
  openBookingCalendarModal,
  openBookingLoginModal,
  openEditBookingSlotsModal,
} = modalSlice.actions;
export default modalSlice.reducer;
