import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo1 from "../../assets/img/Logo-1.svg";
import { openLoginModal,closeModal } from "../../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
function CongratulationModal() {
  const state = useSelector((state) => state.modalState.congratulationModal);
  const dispatch = useDispatch();
  return (
    <Modal id="loginForm" show={state} onHide={() => dispatch(closeModal())}>
      <Button
        className="btn-close"
        data-bs-dismiss="modal"
        onClick={() => dispatch(closeModal())}
        aria-label="Close"
      ></Button>
      <Modal.Header className="m-2">
        <Link to="#" className="logo">
          <img src={logo1} alt="" className="img-fluid" />
        </Link>
      </Modal.Header>
      <Modal.Body>
        <div className="m-3">
          <h2>Successful!</h2>
          <p>
            An email has been sent with a link to reset your account password.{" "}
          </p>
          <div
            onClick={() => dispatch(openLoginModal())}
            style={{
              marginTop: "1rem",
              color: "blue",
              cursor: "pointer",
            }}
          >
            <p>
              <i className="bi bi-arrow-left"></i> Back to login page
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CongratulationModal;
