import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import DefaultProfileImage from "../../../assets/img/profilepic.png";
import { Link } from "react-router-dom";
import verifiedIcon from "../../../assets/img/verified.svg";
import { getTutor } from "../../../services/tutors";
import CustomSpinner from "../../../ui/loaders/CustomSpinner";
const ImageUrl = process.env.REACT_APP_FILES;
function Details() {
  const [loader, setLoader] = useState(true);
  const [user, setUser] = useState(null);
  useEffect(() => {
    getTutor()
      .then((res) => {
        setUser(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);
  return (
    <Card>
      <Card.Header>Details</Card.Header>
      {loader ? (
        <CustomSpinner />
      ) : (
        <Card.Body>
          <div className="list-item row align-items-center mb-0">
            <div className="col-lg-3">
              <Link className="d-block post-trending" to="">
                <img
                  className="img-fluid w-100"
                  src={
                    user?.profile_image_id
                      ? ImageUrl + user?.profile_image?.path
                      : DefaultProfileImage
                  }
                  alt="profile_image"
                />
              </Link>
            </div>
            <div className="col-lg-9">
              <h2 className="h3 mb-4">
                <Link className="d-block reset-anchor" to="">
                  {user?.first_name} {user?.last_name}{" "}
                  {user?.verified === "1" && (
                    <img
                      className="img-fluid ms-1"
                      src={verifiedIcon}
                      alt="verfied"
                    />
                  )}
                </Link>
              </h2>
              <ul className="list-inline">
                <li className="list-inline-item mr-2">
                  <Link className="category-link" to="">
                    <span>Speaks:</span> English
                    {/* {
                      user
                    } */}
                    {/* {teacher_languages
               .map((i) => {
                 return i?.language?.name;
               })
               .toString()} */}
                  </Link>
                </li>
                <li className="list-inline-item mx-2">
                  <Link className="category-link meta-link" to="">
                    <span>Native:</span>
                    {user?.native_language?.name}
                  </Link>
                </li>
                <li className="list-inline-item ">
                  <Link className="category-link meta-link" to="">
                    <i className="bi bi-people"></i>
                    361 Student
                  </Link>
                </li>
                <li className="list-inline-item mx-2">
                  <Link className="category-link meta-link" to="">
                    <i className="bi bi-journal-bookmark"></i>
                    500 lessons
                  </Link>
                </li>
              </ul>
              <p className="text-muted">
                {user?.bio ? user?.bio : user?.teaching_experience}
              </p>
            </div>
          </div>
        </Card.Body>
      )}
    </Card>
  );
}

export default Details;
