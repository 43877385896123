import React from "react";
import { Link } from "react-router-dom";
import verified from "../../assets/img/verified.svg";
import pic1 from "../../assets/img/pic1.svg";
// for navigation to detail page
import { useNavigate } from "react-router-dom";
// action dispatcher
import { useDispatch, useSelector } from "react-redux";
// action
import {
  openLoginModal,
  openBookingCalendarModal,
} from "../../features/modal/modalSlice";
import { useEffect } from "react";
const imageUrl = process.env.REACT_APP_FILES;
function TeacherCard({ teacher, language }) {
  // navigate
  const navigate = useNavigate();
  // dispatcher
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const checkLoggedForBooking = (action) => {
    if (token) {
      dispatch(openBookingCalendarModal({ data: action }));
    } else {
      dispatch(openLoginModal({ data: action }));
    }
  };

  const profileView = (action) => {
    navigate({ pathname: "/tutor/details", search: `?id=${action}` });
  };
 
  return (
    <div
      key={teacher.id}
      className="list-item row align-items-center mb-5 result-card c_pointer"
    >
      <div className="col-lg-3">
        <img
          className="img-fluid w-100"
          src={
            // if image
            teacher?.profile_image
              ? imageUrl + teacher?.profile_image?.path
              : // else default image
                pic1
          }
          alt=""
          onClick={() => profileView(teacher.id)}
        />
      </div>
      <div className="col-lg-6">
        <h2 className="h3 mb-4" onClick={() => profileView(teacher.id)}>
          {teacher.first_name} {teacher.last_name}
          <img
            className="img-fluid ms-1"
            src={teacher.verified === "1" ? verified : ""}
            alt=""
          />
        </h2>
        <ul className="list-inline">
          <li className="list-inline-item mr-2">
            <Link className="category-link p_none" to="">
              <span>Speaks:</span>{" "}
              <span>
                {teacher?.teacher_languages
                  ?.map((i2) => i2?.language?.name)
                  .toString()}
              </span>
            </Link>
          </li>
          <li className="list-inline-item mx-2">
            <Link className="category-link meta-link p_none" to="#">
              <span>Native:</span> {teacher?.native_language?.name}
            </Link>
          </li>
          <li className="list-inline-item ">
            <Link className="category-link meta-link p_none" to="#">
              <i className="bi bi-people"></i> 0 Student
            </Link>
          </li>
          <li className="list-inline-item mx-2">
            <Link className="category-link meta-link p_none" to="#">
              <i className="bi bi-journal-bookmark"></i> 0 lessons
            </Link>
          </li>
        </ul>
        <p className="text-muted">
          {/* if bio */}
          {teacher?.bio ? (
            <>
              {teacher?.bio.slice(0.15) + "..."}{" "}
              <span
                className="btn-link p-0 read-more-btn readmore"
                onClick={() => profileView(teacher.id)}
              >
                Read more
              </span>
              <i className="fas fa-long-arrow-alt-right"></i>
            </>
          ) : // if teaching experiend
          teacher?.teaching_experience ? (
            <>
              {teacher?.teaching_experience.slice(0.15) + "..."}{" "}
              <span
                className="btn-link p-0 read-more-btn readmore"
                onClick={() => profileView(teacher.id)}
              >
                Read more
              </span>
              <i className="fas fa-long-arrow-alt-right"></i>
            </>
          ) : // else nothing
          null}
        </p>
      </div>
      <div className="col-lg-3 justify-content-between">
        <div className="rating-area">
          <ul className="list-inline">
            <li className="list-inline-item mr-2">
              <Link className="star-link p_none" to="#">
                <i className="bi bi-star-fill"></i>
                <span>4.93 (16)</span>
              </Link>
            </li>
            <li className="list-inline-item mx-2">
              <Link className="wishlist-link meta-link p_none" to="#">
                <i className="bi bi-heart"></i>
              </Link>
            </li>
          </ul>
        </div>
        <div className="btn-area">
          {/* <button
            to=""
            className="border-theme-btn form-control"
            onClick={() => checkLoggedin("view_schedule")}
            disabled
          >
            View Schedule
          </button> */}
          <button
            to=""
            className="theme-btn form-control"
            onClick={() => checkLoggedForBooking(teacher)}
            data={teacher}
          >
            Book Trial lesson
          </button>
        </div>
      </div>
    </div>
  );
}

export default TeacherCard;
