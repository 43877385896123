import axios from "axios";

// api endpoint
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

// get book lesson listing
export const getBookLessonListing = async (id, page) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url =
    baseUrl + `/book-lesson?per_page=10&page=${page}&tutor_booking=${id}`;
  return await axios.get(url, { headers });
};
// get filtereed tutors
export const getTutors = async (url) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  return await axios.get(url, { headers });
};
// book lesson
export const bookLesson = async (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/book-lesson";
  return await axios.post(url, data, { headers });
};
// get timeslots of teacher
export const getTimeSlots = async (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/search-availability";
  return await axios.post(url, data, { headers });
};
// get tutor by id
export const getTutorById = async (id) => {
  // headers
  const headers = {
    Accept: "application/json",
  };
  const url = baseUrl + `/teacher/${id}`;
  return await axios.get(url, { headers });
};

// upload profile pic
export const uploadImage = async (file, setPercentage) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/image-upload";
  const config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setPercentage(percent);
    },
    headers: headers,
  };
  return await axios.post(url, file, config);
};

// update profile image
export const updateTutorProfileImage = async (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/teacher/profile/image";
  return await axios.post(url, data, { headers });
};

// upload tutor  demo video to get id
export const uploadVideo = async (file, setPercentage) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/video-upload";
  const config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setPercentage(percent);
    },
    headers: headers,
  };
  return await axios.post(url, file, config);
};

// update tutor profile
export const updateTutor = async (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/teacher/profile";
  return await axios.post(url, data, { headers });
};

// get tutor profile
export const getTutor = async () => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/teacher/profile";
  return await axios.get(url, { headers });
};

// get timezone
export const timeZones = async () => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/timezone";
  return await axios.get(url, { headers });
};

// update certification
export const certification = async (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/teacher/certificate";
  return await axios.post(url, data, { headers });
};

// update education
export const education = async (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/teacher/education";
  return await axios.post(url, data, { headers });
};

// update description
export const description = async (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/teacher/desciption";
  return await axios.post(url, data, { headers });
};

// upload document
export const document = async (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/document-upload";
  return await axios.post(url, data, { headers });
};

// upload video link
export const videoUrl = async (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/teacher/video";
  return await axios.post(url, data, { headers });
};

// update availability
export const availability = (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/teacher/availability";
  return axios.post(url, data, { headers });
};

// update status of booking
export const updateBookingStatus = async (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/booking/update";
  return await axios.post(url, data, { headers });
};
export const addSlots = async (data) => {
  // headers
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/teacher/add-availability";
  return await axios.post(url, data, { headers });
};