import React from "react";

function Speciality({ setDropdown, dropdown, specialities, setSpecialities }) {
  const handleSpeciality = (id) => {
    setSpecialities(
      specialities.map((i) => {
        if (i.id === id) {
          return { ...i, checked: !i.checked };
        } else {
          return i;
        }
      })
    );
  };
  return (
    <div
      onClick={(e) => setDropdown("speciality")}
      className="input-group"
      style={{ width: "9rem" }}
    >
      <div id="select-speciality" className="speciality">
        Speaciality
      </div>
      {dropdown === "speciality" && (
        <div className="speciality-select-box">
          {specialities.map((item, index) => (
            <div className="custom-checkbox" key={index}>
              <label className="container-check">
                {item.label}
                <input
                  name={item.label}
                  type="checkbox"
                  onChange={() => handleSpeciality(item.id)}
                  checked={item.checked}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Speciality;
