import React from "react";

function RatingBlock() {
  return (
    <div className="rating-block">
      <h4>Average user rating</h4>
      <h2 className="bold padding-bottom-7">
        4.3 <small>/ 5</small>
      </h2>
      <button
        type="button"
        className="btn btn-warning btn-sm me-1 c_white"
        aria-label="Left Align"
        disabled
      >
        <i className="bi bi-star-fill"></i>
      </button>
      <button
        type="button"
        className="btn btn-warning btn-sm me-1 c_white"
        aria-label="Left Align"
        disabled
      >
        <i className="bi bi-star-fill"></i>
      </button>
      <button
        type="button"
        className="btn btn-warning btn-sm me-1 c_white"
        aria-label="Left Align"
        disabled
      >
        <i className="bi bi-star-fill"></i>
      </button>
      <button
        type="button"
        className="btn btn-default btn-grey btn-sm me-1"
        aria-label="Left Align"
        disabled
      >
        <i className="bi bi-star-fill"></i>
      </button>
      <button
        type="button"
        className="btn btn-default btn-grey btn-sm me-1"
        aria-label="Left Align"
        disabled
      >
        <i className="bi bi-star-fill"></i>
      </button>
    </div>
  );
}

export default RatingBlock;
