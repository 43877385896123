import React from "react";

function AutoConfirmation() {
  return (
    <div className="card card-primary">
      {" "}
      <div
        className=""
        id="vert-tabs-autoconfirmation"
        role="tabpanel"
        aria-labelledby="vert-tabs-autoconfirmation-tab"
      >
        <div className="card-header">
          <h3 className="card-title">Lesson Settings</h3>
        </div>

        <div className="wrapper bg-white mt-sm-5 p-4">
          <div className="py-2">
            <div className="row py-2">
              <div className="col-md-6">
                <h5>Autoconfirmation</h5>
                <p>
                  All lessons will be confirmed automatically 72 hours after the
                  scheduled end time unless you report an issue. Choose
                  automatic confirmation if you trust your tutors and there are
                  no issues with scheduling lessons through the Preply Calendar.
                </p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    I want to confirm each lesson manually
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    defaultChecked
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    Confirm my lessons automatically after 72 hours
                  </label>
                </div>
              </div>

              <div className="col-md-6">
                <p>
                  Autoconfirmation for lessons in the Preply Space Autoconfirm
                  tracks the time you spend connected with your tutor in the
                  Preply Space. 15 minutes after a lesson is completed, it is
                  confirmed automatically and we release your tutor's payment.
                  If there are any issues, please contact us right after the
                  lesson and we'll help you find a solution.
                </p>
              </div>
            </div>
          </div>

          <div className="py-3 pb-4">
            <button className="btn btn-primary mr-3">Save Changes</button>
            <button className="btn border button">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutoConfirmation;
