import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import EmailNotification from "./EmailNotification";
import SmsNotification from "./SmsNotification";
import DayLearningInsight from "./DayLearningInsight";
import {
  createNotificationSettings,
  updateNotificationSettings,
} from "../../../../../services/student";
import { successNotify, errorNotify } from "../../../../../ui/toasters";
import { useState } from "react";

function Notification({ notifications, setNotifications, newUser,setNewUser }) {
  const [loader, setLoader] = useState(false);
  // handle submit
  const addNotificationSettings = () => {
    setLoader(true);
    createNotificationSettings(notifications)
      .then((res) => {
        successNotify(res.data.message);
        setNewUser(false)
        setLoader(false);
      })
      .catch((err) => {
        errorNotify("Something went wrong");
        setLoader(false);
      });
  };

  // handle update
  const updateNotifications = () => {
    setLoader(true);
    updateNotificationSettings(notifications)
      .then((res) => {
        successNotify(res.data.message);
        setLoader(false);
      })
      .catch((err) => {
        errorNotify("Something went wrong");
        setLoader(false);
      });
  };
  return (
    <Card>
      <Card.Header>
        <h3 className="card-title">Notification Center</h3>
      </Card.Header>
      <Card.Body className="p-4">
        <Card.Title>Email notifications</Card.Title>
        <EmailNotification
          notifications={notifications}
          setNotifications={setNotifications}
        />
        <Card.Title>SMS notifications</Card.Title>
        <SmsNotification
          notifications={notifications}
          setNotifications={setNotifications}
        />
        <Card.Title>DayLearning insights</Card.Title>
        <DayLearningInsight
          notifications={notifications}
          setNotifications={setNotifications}
        />
        {newUser ? (
          <Button
            variant="primary"
            className="me-3"
            onClick={addNotificationSettings}
          >
            {loader ? "Saving..." : "Save Changes"}
          </Button>
        ) : (
          <Button
            variant="primary"
            className="me-3"
            onClick={updateNotifications}
          >
            {loader ? "Updating..." : "Update"}
          </Button>
        )}

        <Button variant="light" className="border">
          Cancel
        </Button>
      </Card.Body>
    </Card>
  );
}

export default Notification;
