import React from "react";

function Email(props) {
  const { user } = props;
  return (
    <div className="card card-primary">
      {" "}
      <div
        id="vert-tabs-email"
        role="tabpanel"
        aria-labelledby="vert-tabs-email-tab"
      >
        <div className="card-header">
          <h3 className="card-title">Email</h3>
        </div>

        <div className="wrapper bg-white p-4">
          <div className="py-2">
            <div className="row py-2">
              <div className="col-md-6">
                <label htmlFor="firstname">Email</label>
                <input
                  value={user?.email}
                  type="email"
                  className="bg-light form-control"
                  placeholder="Steve"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Email;
