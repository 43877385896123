import React from "react";
import { useEffect } from "react";
import "./checkout.css";
import LeftCard from "./leftcard/LeftCard";
import RightCard from "./rightcard/RightCard";
import { useParams, useNavigate } from "react-router-dom";
import CustomSpinner from "../../ui/loaders/CustomSpinner";
import { useState } from "react";
import { getCheckoutDetails } from "../../services/student";
import { errorNotify } from "../../ui/toasters";

function Checkout() {
  const [total, setTotal] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [bookLangauge, setBookLanaguage] = useState(null);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState(null);
  // const
  useEffect(() => {
    if (!uuid) {
      navigate("/");
    }
    getCheckoutDetails(uuid)
      .then((res) => {
        if (res.data.data.payment_status === "1") {
          navigate(-1);
        }
        setData(res.data.data);
        const hourlyRate = res.data.data.hourly_rate;
        const ProcessingFee = (hourlyRate / 100) * 10;
        const total = hourlyRate + ProcessingFee;
        setProcessingFee(ProcessingFee);
        setTotal(total);
        setHourlyRate(hourlyRate);
        setSubTotal(hourlyRate);
        setBookLanaguage(res.data.data.book_tutor.native_language.name);
        setLoader(false);
      })
      .catch((err) => {
        errorNotify("Something went wrong");
      });
    window.scroll(0, 0);
  }, [navigate, uuid]);
  return (
    <div className="container">
      <main className="checkout mt-5 pt-5 pb-5">
        {loader ? (
          <CustomSpinner />
        ) : (
          <div className="row mt-2">
            <div className="col-lg-4">
              <LeftCard
                data={data}
                total={total}
                processingFee={processingFee}
                hourlyRate={hourlyRate}
              />
            </div>
            <div className="col-lg-8">
              <RightCard
                bookLangauge={bookLangauge}
                hourlyRate={hourlyRate}
                total={total}
                subTotal={subTotal}
                processingFee={processingFee}
              />
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default Checkout;
