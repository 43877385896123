import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../features/modal/modalSlice";
import AddNewCard from "../../stripe/AddNewCard";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripe = loadStripe(PUBLIC_KEY);
function AddNewCardModal() {
  // modal states
  const state = useSelector((state) => state.modalState.addNewCardModal);
  const dispatch = useDispatch();
  return (
    <Modal
      id="loginForm"
      show={state?.value}
      onHide={() => dispatch(closeModal())}
      centered
    >
      <Button
        className="btn-close"
        data-bs-dismiss="modal"
        onClick={() => dispatch(closeModal(null))}
        aria-label="Close"
      ></Button>
      <Modal.Body>
        <div className="m-3 mt-0">
          <Elements stripe={stripe}>
            <AddNewCard setCardAdded={state?.data} />
          </Elements>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddNewCardModal;
