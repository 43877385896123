import React from "react";
import { successNotify, errorNotify } from "../ui/toasters";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { contactform } from "../services/common";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import { useEffect } from "react";

function ContactUsPage() {
  useEffect(()=>{
    window.scroll(0,0);
  })
  const navigate = useNavigate();
  // for contact form validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });

  // handle login
  const onSubmit = async (form) => {
    
    // contact_us service
    contactform(form)
      .then(async (response) => {
        const data = await response.data;
        if (data.email_response.sent_status) {
          successNotify(data.message);
          reset();
          setTimeout(() => {
            navigate("/home"); 
          }, 1000);
        } else {
          errorNotify(data.email_response.sent_status);
        }
      })
      .catch((error) => {
        let err;
        if (error.response.status === 400) {
          err = error.response.data.message;
        } else if (error.response.status === 422) {
          err = Object.values(error.response.data?.errors)[0][0];
        } else {
          err = "Something went Wrong";
        }
        errorNotify(err);
      });
  };

  return (
    <section className="contact_us container mt-5">
      <div className="row mt-3">
        <div className="card col-lg-4 p-4 me-3">
          <h4>Our Contact</h4>

          <div className="row mt-5 mb-3">
            <div className="col-lg-1">
              <i className="bi bi-envelope"></i>
            </div>
            <div className="col">
              <div>Email Address</div>
              <div>contact@daylearning.com</div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-1">
              <i className="bi bi-telephone"></i>
            </div>
            <div className="col">
              <div>Phone Number</div>
              <div>1-800-969</div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-1">
              <i className="bi bi-telephone"></i>
            </div>
            <div className="col">
              <div>Fax Number</div>
              <div>1-800-856-2759</div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-1">
              <i className="bi bi-whatsapp"></i>
            </div>
            <div className="col">
              <div>WhatsApp</div>
              <div>1-718-285-0845</div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-1">
              <i className="bi bi-geo-alt"></i>
            </div>
            <div className="col">
              <div>Address</div>
              <div>
                Daylearning. Inc, 477 Madison Avenue Floor 6 New York, New York
                10022
              </div>
            </div>
          </div>

          <h4 className="mt-5">Follow Us</h4>
          <div className="row">
            <div className="col-lg-1">
              <i className="bi bi-facebook"></i>
            </div>
            <div className="col-lg-1">
              <i className="bi bi-twitter"></i>
            </div>
            <div className="col-lg-1">
              <i className="bi bi-linkedin"></i>
            </div>
            <div className="col-lg-1">
              <i className="bi bi-pinterest"></i>
            </div>
            <div className="col-lg-1">
              <i className="bi bi-youtube"></i>
            </div>
          </div>
        </div>
        <div className="card col-lg-7 p-4">
          <h4>Contact us</h4>
          <p className="mb-1">
            Our team is standing by, ready to assist you any day, at any time,
            in any language.
          </p>
          <form
            action=""
            className="signin-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mt-4">
              <div className="row mb-3">
                <div className="col-lg-6">
                  <label htmlFor="name">First Name</label>
                  <input
                    type="text"
                    id="first_name"
                    placeholder="First Name"
                    className="form-control"
                    autoComplete="off"
                    {...register("first_name", {
                      required: true,
                      minLength: 3,
                      maxLength: 100,
                    })}
                  />
                  {errors.first_name?.type === "required" && (
                    <span className="text-danger">First name is required!</span>
                  )}
                  {errors.first_name?.type === "minLength" && (
                    <span className="text-danger">
                      First name is must be grater then 3 characters!
                    </span>
                  )}
                  {errors.first_name?.type === "maxLength" && (
                    <span className="text-danger">
                      First name is must be less than 100 characters!
                    </span>
                  )}
                </div>
                <div className="col-lg-6">
                  <label htmlFor="last">Last Name</label>
                  <input
                    type="text"
                    id="last_name"
                    placeholder="Last Name"
                    className="form-control"
                    autoComplete="off"
                    {...register("last_name", {
                      required: true,
                      minLength: 3,
                      maxLength: 100,
                    })}
                  />
                  {errors.last_name?.type === "required" && (
                    <span className="text-danger">Last name is required!</span>
                  )}
                  {errors.last_name?.type === "minLength" && (
                    <span className="text-danger">
                      Last name is must be grater then 3 characters!
                    </span>
                  )}
                  {errors.last_name?.type === "maxLength" && (
                    <span className="text-danger">
                      Last name is must be less then 100 characters!
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-lg-6">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    className="form-control"
                    autoComplete="off"
                    {...register("email", {
                      required: true,
                      maxLength: 100,
                      pattern:
                        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <span className="text-danger">Email is required!</span>
                  )}
                  {errors.email?.type === "pattern" && (
                    <span className="text-danger">Invalid email!</span>
                  )}
                  {errors.email?.type === "maxLength" && (
                    <span className="text-danger">
                      Maximum length exceeded. You can enter maximum 100
                      characters in email!
                    </span>
                  )}
                </div>
                <div className="col-lg-6">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="number"
                    id="phone"
                    placeholder="Phone"
                    className="form-control"
                    autoComplete="off"
                    {...register("phone")}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-lg-12">
                  <label htmlFor="project_notes">Message</label>
                  <textarea
                    rows="8"
                    name="message"
                    id="message"
                    className="form-control"
                    placeholder="Type Your Message..."
                    autoComplete="off"
                    {...register("message", {
                      required: true,
                      minLength: 3,
                      maxLength: 1000,
                    })}
                  ></textarea>
                  {errors.message?.type === "required" && (
                    <span className="text-danger">Message is required!</span>
                  )}
                  {errors.message?.type === "minLength" && (
                    <span className="text-danger">
                      Last name is must be grater then 3 characters!
                    </span>
                  )}
                  {errors.message?.type === "maxLength" && (
                    <span className="text-danger">
                      Maximum length exceeded. You can enter maximum 1000
                      characters in password!
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                  <button
                    type="submit"
                    className="btn btn-primary form-control"
                  >
                    Submit
                  </button>
                </div>
                <div className="col-lg-4"></div>
              </div>
            </div>
            <Toaster
              toastOptions={{
                style: {
                  background: "red",
                  color: "white",
                },
              }}
            />
          </form>
        </div>
      </div>
    </section>
  );
}

export default ContactUsPage;
