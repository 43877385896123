import React from "react";
function NativeSpeaker({ setDropdown, dropdown }) {
  return (
    <div onClick={(e) => setDropdown("nativeSpeaker")} className="input-group">
      <div
        id="select-speciality"
        className="speciality"
        style={{ padding: "15px" }}
      >
        Native speaker
      </div>
      {dropdown === "nativeSpeaker" && (
        <div className="nativeSpeaker-select-box">
          <div className="custom-checkbox">
            <label className="container-check">
              Only native spanish speakers
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <p>
              By selecting this, we will only show native Spanish speakers,
              otherwise everyone else will be show by default
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default NativeSpeaker;
