import React, { useEffect, useState } from "react";
// import spinner
import CustomSpinner from "../../../ui/loaders/CustomSpinner";
import BlogListing from "./BlogListing/BlogListing";
import BlogSidebar from "./BlogSidebar/BlogSidebar";
import "./details.css";
import { getTutorById } from "../../../services/tutors/index";
import { useLocation } from "react-router-dom";
function Details() {
  // loader state
  const [loader, setLoader] = useState(true);
  // store tutor
  const [tutor, setTutor] = useState([]);
  // get id form params
  const id = new URLSearchParams(useLocation().search).get("id");
  useEffect(() => {
    // get tutor deatisl
    getTutorById(id)
      .then((res) => {
        setTutor(res.data.data[0]);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
    window.scroll(0, 0);
  }, [id]);
  return (
    <>
      <main id="main" className="mt-5">
        {loader ? (
          <CustomSpinner />
        ) : (
          <section className="inner-page">
            <div className="container">
              <div className="row">
                {/* <!-- Blog listing--> */}
                <div className="col-lg-8 mb-5 mb-lg-0">
                  <BlogListing tutor={tutor} />
                </div>
                {/* <!-- Blog sidebar--> */}
                <div className="col-lg-4 sidebar tutor-single-page">
                  {/* <!-- Recent posts--> */}
                  <BlogSidebar tutor={tutor} />
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
}

export default Details;
