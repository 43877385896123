import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// library for phone number with country code
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import react hook form
import { useForm, Controller } from "react-hook-form";
// import services
import {
  timeZones,
  uploadImage,
  updateStudent,
} from "../../../../services/student";
// import redux actions
import { uploading } from "../../../../features/uploading/uploadingSlice";
// if the there is no profile image then show default image;
import defaultProfileImage from "../../../../assets/img/profilepic.png";
// tosters
import { successNotify, errorNotify } from "../../../../ui/toasters";
import Select from "react-select";
import CustomSpinner from "../../../../ui/loaders/CustomSpinner";
import { getStudentProfile } from "../../../../services/student";

function Account(props) {
  const [loader, setLoader] = useState(true);

  // for image upoading percentage
  const { setPercentage } = props;
  // state for disabling button after api hit
  const [disable, setDisable] = useState(false);
  const [user, setUser] = useState(null);
  // redux state
  // url for images
  const imageUrl = process.env.REACT_APP_FILES + user?.image?.path;
  // state for image id and file
  const [file, setFile] = useState({
    file: "",
    path: "",
    id: user?.profile_image_id,
  });
  const dispatch = useDispatch();
  // state for timezones
  const [timezones, setTimezones] = useState([]);
  // get timezones
  useEffect(() => {
    timeZones()
      .then((res) => {
        setTimezones(
          res?.data?.data.map((i) => {
            return {
              value: i?.id,
              label: `${i?.gmt_offset} ${i?.zone}`,
            };
          })
        );
      })
      .catch((err) => {
        errorNotify("Something went wrong");
      });
  }, []);
  // get student data
  useEffect(() => {
    getStudentProfile()
      .then((res) => {
        setUser(res.data.data);
        setLoader(false);
      })
      .catch();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      profile_image_id: user?.profile_image_id,
      timezone_id: user?.timezone_id,
      first_name: user?.first_name,
      last_name: user?.last_name,
      phone: user?.phone,
    },
  });
  // // after first render user is null to rest values we do this
  useEffect(() => {
    reset(user);
  }, [reset, user]);
  // image upload
  const handleImageUpload = (e) => {
    // check for file size
    const fileSize = e.target.files[0].size > 1000000;
    if (fileSize) {
      errorNotify("Image should be less than 1Mb");
      return false;
    }
    // uploading
    dispatch(uploading(true));
    // formdata
    const formdata = new FormData();
    formdata.append("image", e.target.files[0]);
    // service function to upload image
    uploadImage(formdata, setPercentage)
      .then((res) => {
        const file = e.target.files[0];
        const path = res.data.data.path;
        const id = res.data.data.id;
        setFile({
          file: file,
          path: path,
          id: id,
        });
        // uploading end
        dispatch(uploading(false));
        // success
        const toasterMsg = res.data.message;
        successNotify(toasterMsg);
      })
      .catch((err) => {
        setFile({ file: "", path: "", id: "" });
        dispatch(uploading(false));
        // error
        const toasterMsg = Object.values(err?.response?.data?.errors)[0][0];
        errorNotify(toasterMsg);
      });
  };
  //
  // on form submit
  const onSubmit = async (data) => {
    // object to send
    const obj = {
      profile_image_id: file.id ? file.id : user?.profile_image_id,
      timezone_id: data?.timezone_id,
      first_name: data?.first_name,
      last_name: data?.last_name,
      phone: data?.phone,
    };
    // service update fucntion
    setDisable(true);
    updateStudent(obj)
      .then((res) => {
        successNotify(res.data.message);
        setDisable(false);
      })
      .catch((err) => {
        setDisable(false);
        errorNotify("Somthing went wrong");
      });
  };
  // custom styles for search button
  const styles = {
    menuList: (base) => ({
      ...base,
      height: "8rem",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#1a2e9e",
        borderRadius: "3px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#1a2e9e",
      },
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? "#1a2e9e" : "",
      "&:hover": {
        backgroundColor: "#1a2e9e",
        color: "white",
      },
    }),
  };
  return (
    <div>
      {loader ? (
        <CustomSpinner />
      ) : (
        <div className="card card-primary">
          <div
            className="tab-pane text-left fade show active"
            id="vert-tabs-account"
            role="tabpanel"
            aria-labelledby="vert-tabs-account-tab"
          >
            <div className="card-header">
              <h3 className="card-title">Account settings</h3>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="wrapper bg-white p-4">
                <div className="d-flex align-items-start py-3 border-bottom">
                  <img
                    src={
                      file?.file
                        ? URL.createObjectURL(file.file)
                        : user?.image?.path
                        ? imageUrl
                        : defaultProfileImage
                    }
                    className="img"
                    width={150}
                    height={150}
                    style={{ marginRight: "20px" }}
                    alt=""
                  />
                  <div className="pl-sm-4 pl-2" id="img-section">
                    <b>Profile Photo</b>
                    <p>Accepted file type .png. Less than 1MB</p>
                    <label className="btn button border">
                      <b>Upload</b>
                      <input
                        accept=".jpg, .jpeg, .png"
                        {...register("profile_image_id", {
                          required: {
                            value: user?.profile_image_id ? false : true,
                            message: "Required",
                          },
                          onChange: (e) => {
                            handleImageUpload(e);
                          },
                        })}
                        className="display_none"
                        type="file"
                      />
                    </label>
                  </div>
                </div>
                {errors?.profile_image_id?.type === "required" && (
                  <span className="alert-danger">
                    {errors?.profile_image_id?.message}
                  </span>
                )}
                <div className="py-2">
                  <div className="row py-2">
                    <div className="col-md-6">
                      <label htmlFor="firstname">First Name</label>
                      <input
                        {...register("first_name", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                        })}
                        type="text"
                        className="bg-light form-control"
                        placeholder="First Name"
                      />
                      {errors?.first_name?.type === "required" && (
                        <span className="alert-danger">
                          {errors?.first_name?.message}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 pt-md-0 pt-3">
                      <label htmlFor="lastname">Last Name</label>
                      <input
                        {...register("last_name", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                        })}
                        type="text"
                        className="bg-light form-control"
                        placeholder="Last Name"
                      />
                      {errors?.last_name?.type === "required" && (
                        <span className="alert-danger">
                          {errors?.last_name?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row py-2">
                    <div className="col-md-6">
                      <label htmlFor="email">Timezone</label>
                      <Controller
                        control={control}
                        name="timezone_id"
                        defaultValue={{
                          label: `${user?.timezone?.gmt_offset} ${user?.timezone?.zone}`,
                          value: user?.timezone?.id,
                        }}
                        render={() => (
                          <Select
                            options={timezones}
                            defaultValue={{
                              label: `${user?.timezone?.gmt_offset} ${user?.timezone?.zone}`,
                              value: user?.timezone?.id,
                            }}
                            styles={styles}
                            onChange={(e) => setValue("timezone_id", e.value)}
                          />
                        )}
                      />
                      {/* <Select
                        options={timezones}
                        defaultValue={{
                          label: `${user?.timezone?.gmt_offset} ${user?.timezone?.zone}`,
                          value: user?.timezone?.id,
                        }}
                        styles={styles}
                      /> */}
                    </div>
                    <div className="col-md-6 pt-md-0 pt-3">
                      <label htmlFor="phone">Phone Number</label>
                      <Controller
                        control={control}
                        name="phone"
                        rules={{
                          required: {
                            value: true,
                            message: "Required",
                          },
                        }}
                        render={({ field: { ref, ...field } }) => (
                          <PhoneInput
                            {...field}
                            inputExtraProps={{
                              ref,
                              required: true,
                            }}
                            country={"us"}
                            countryCodeEditable={true}
                          />
                        )}
                      />
                      {errors?.phone?.type === "required" && (
                        <span className="alert-danger">
                          {errors?.phone?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="py-3 pb-4">
                    <button
                      disabled={disable}
                      type="submit"
                      className="btn btn-primary me-3"
                    >
                      Save Changes
                    </button>
                    <button type="button" className="btn border button">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Account;
