import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { step3 } from "../../../../features/Tutor/stepsSlice";
import { certification } from "../../../../services/tutors";
import { successNotify, errorNotify } from "../../../../ui/toasters";

function Step3(props) {
  const [noTeachingCertificate, setNoTeachingCertificate] = useState(false);
  // error state for date validation
  const [dateError, setDateError] = useState({ error: null, message: null });
  // for scroll to top on render the home component
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const { steps, setSteps, user } = props;
  const state = useSelector((state) => state?.steps?.step3);

  // for year list
  const [years, setYears] = useState([]);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      subject: user?.teacher_certificate?.subject,
      certificate: user?.teacher_certificate?.certificate,
      description: user?.teacher_certificate?.description,
      issue_by: user?.teacher_certificate?.issue_by,
      start_study_year: {
        label: user?.teacher_certificate?.start_study_year,
        value: user?.teacher_certificate?.start_study_year,
      },
      end_study_year: {
        label: user?.teacher_certificate?.end_study_year,
        value: user?.teacher_certificate?.end_study_year,
      },
    },
  });
  const onSubmit = async (data) => {
    dispatch(step3(data));
    const object = {
      subject: data.subject,
      certificate: data.certificate,
      description: data.description,
      issue_by: data.issue_by,
      start_study_year: data.start_study_year.value,
      end_study_year: data.end_study_year.value,
    };
    // disbling next button so user can't hit API more than once
    setDisableNextBtn(true);

    certification(object)
      .then((res) => {
        if (res.status === 200) {
          successNotify(res.data.message);

          setSteps({
            ...steps,
            step5: { value: true, display: "show" },
            step4: { value: true, display: "none" },
          });
        }
      })
      .catch((err) => {
        errorNotify("Something went wrong");
        setDisableNextBtn(false);
      });
  };

  // setting year list
  useEffect(() => {
    let yearArray = [];
    for (var i = 1976; i <= new Date().getFullYear(); i++) {
      yearArray.push(i);
    }
    setYears(
      yearArray.map((i) => {
        return {
          label: i,
          value: i,
        };
      })
    );
  }, []);
  // handle date check
  const handleDateCheck = (e) => {
    const start_date = getValues("start_study_year").value;
    const end_date = getValues("end_study_year").value;
    // for start date
    if (e === "startDate") {
      if (start_date < end_date) {
        setDateError({ error: null, message: null });
        return true;
      } else if (start_date >= end_date) {
        setDateError({
          error: true,
          message: "Start date cannot be equal or bigger than end date",
        });
        return "Error";
      } else {
        setDateError({ error: null, message: null });
        return true;
      }
    }
    // for end date
    else {
      if (end_date > start_date) {
        setDateError({ error: null, message: null });
        return true;
      } else if (end_date <= start_date) {
        setDateError({
          error: true,
          message: "End date cannot be equal or smaller than start date",
        });
        return "Error";
      } else {
        setDateError({ error: null, message: null });
        return true;
      }
    }
  };
  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-6">
          <div className="custom-checkbox">
            <label className="container-check">
              I don't have any teaching certificate
              <input
                type="checkbox"
                onClick={(e) => setNoTeachingCertificate(e.target.checked)}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      {noTeachingCertificate ? (
        <div className="col-md-6">
          <input
            name="login"
            id="login"
            className="btn btn-primary btn-block login-btn nextBtn"
            type="button"
            value="Next"
            onClick={() => {
              setSteps({
                ...steps,
                step5: { value: true, display: "show" },
                step4: { value: true, display: "none" },
              });
            }}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="panel panel-primary setup-content pb-5" id="step-3">
            <div className="panel-body">
              <div className="form-row custom-form-mt">
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail4">Subject</label>
                  <input
                    className="form-control"
                    {...register("subject", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                    type={"text"}
                    placeholder="Enter Subject"
                  />
                  {errors?.subject?.type === "required" && (
                    <span className="text-danger">
                      {errors?.subject?.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputPassword4">Certificate</label>
                  <input
                    defaultValue={state?.certificate}
                    className="form-control"
                    type="text"
                    {...register("certificate", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  />
                  {errors?.certificate?.type === "required" && (
                    <span className="text-danger">
                      {errors?.certificate?.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="experienceteaching">Description</label>
                  <textarea
                    type="expteaching"
                    name="expteaching"
                    id="expteaching"
                    className="form-control"
                    {...register("description", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                    defaultValue={state?.description}
                  ></textarea>
                  {errors?.description?.type === "required" && (
                    <span className="text-danger">
                      {errors?.description?.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="currentsituation">Issued By</label>
                  <input
                    type="currentsituation"
                    name="currentsituation"
                    id="currentsituation"
                    className="form-control"
                    {...register("issue_by", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                    defaultValue={state?.issue_by}
                  />
                  {errors?.issue_by?.type === "required" && (
                    <span className="text-danger">
                      {errors?.issue_by?.message}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="country">Year of Study</label>
                  <div className="row">
                    <div className="col">
                      <label>Start Date</label>
                      <Controller
                        control={control}
                        name="start_study_year"
                        rules={{
                          required: {
                            value: true,
                            message: "Required",
                          },
                          // date validation check for start date
                          validate: () => handleDateCheck("startDate"),
                        }}
                        defaultValue={state?.start_study_year}
                        render={({ field: { ref, ...field } }) => (
                          <Select
                            {...field}
                            placeholder="Select Year"
                            inputExtraProps={{
                              ref,
                              required: true,
                            }}
                            options={years.sort((a, b) => b.value - a.value)}
                          />
                        )}
                      />
                      {errors?.start_study_year?.type === "required" && (
                        <span className="text-danger">
                          {errors?.start_study_year?.message}
                        </span>
                      )}
                    </div>
                    <div className="col">
                      <label>End Date</label>
                      <Controller
                        control={control}
                        name="end_study_year"
                        rules={{
                          required: {
                            value: true,
                            message: "Required",
                          },
                          // to do
                          validate: () => handleDateCheck("endDate"),
                        }}
                        defaultValue={state?.end_study_year}
                        render={({ field: { ref, ...field } }) => (
                          <Select
                            {...field}
                            placeholder="Select Year"
                            inputExtraProps={{
                              ref,
                              required: true,
                            }}
                            options={years.sort((a, b) => b.value - a.value)}
                          />
                        )}
                      />
                      {errors?.end_study_year?.type === "required" && (
                        <span className="text-danger">
                          {errors?.end_study_year?.message}
                        </span>
                      )}
                    </div>
                    {dateError.error && (
                      <span className="text-danger">{dateError?.message}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <input
                  name="login"
                  id="login"
                  className="btn btn-primary btn-block login-btn nextBtn mt-2"
                  type="submit"
                  value="Next"
                  disabled={disableNextBtn}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default Step3;
