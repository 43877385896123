import React from "react";
import { useEffect } from "react";

function AboutUsPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <section className="about-us container mt-5">
      <div className="mt-3">
        <h2>
          <b>About Daylearning</b>
        </h2>
        <p>
          Helping improve worldwide communication through accurate, localized
          translations, interpretation services, and a wide variety of tailored
          language solutions for individuals, organizations, and businesses of
          all sizes.
        </p>
      </div>

      <div className="mt-5">
        <h4>
          <b>What We Stand For</b>
        </h4>
        <p>
          <b>We specialize in languages, and we love what we do.</b>
        </p>
        <p>
          Our global team is driven by a passion for languages that transcends
          every word we translate and every single customer experience we build.
          Our mission is to help preserve languages and heritage around the
          globe, improving worldwide communication through accurate, localized
          translation and interpretation services.
        </p>
        <p>
          We strive daily to uphold the core values of intercultural respect and
          continued learning, developing opportunities for greater humanity. At
          Day Translations, we continue to grow vertically and thrive off of the
          challenges that present.
        </p>
      </div>

      <div className="mt-3">
        <h6>
          <b>Words from our president</b>
        </h6>
        <p>
          “I decided to set up Day Translations, Inc. from the ground up in 2007
          because, after learning all about the translations business through
          entrepreneurial courses and my own personal experiences, I strongly
          believed that my company could be of great service to individuals and
          corporations throughout the world. And I finally had the knowledge and
          courage to go it alone. I always knew that I wanted to start my own
          business and work towards my dream of promoting world peace through
          education, tolerance and cultural awareness…”
        </p>
      </div>

      <div className="mt-3">
        <h6>
          <b>Charities we’ve supported.</b>
        </h6>
        <p>
          <b>
            Helping preserve languages and improve global communications is
            about more than providing quality translation and interpretation
            services.
          </b>
        </p>
        <p>
          At Day Translations, we believe in values, not hierarchies. Treating
          others with respect, giving back to the community and uniting people
          from all cultures forms part of our company’s DNA. We’re proud to
          collaborate with institutions that share our values.
        </p>
      </div>
    </section>
  );
}

export default AboutUsPage;
