import React from "react";
import Header from "./Header";
import DateAndTime from "./DateAndTime";
import ServiceDetails from "./ServiceDetails";
import Total from "./Total";
function LeftCard({ data,total,processingFee,hourlyRate }) {
  const { book_tutor, book_time, date } = data;
  return (
    <div className="card p-3">
      <Header book_tutor={book_tutor} />
      <hr />
      <DateAndTime book_tutor={book_tutor} book_time={book_time} date={date} />
      <hr />
      <ServiceDetails
        hourly_rate={hourlyRate}
        processingFee={processingFee}
      />
      <hr />
      <Total total={total} />
    </div>
  );
}

export default LeftCard;
