import React from 'react'

function DateAndTime({date,book_time,book_tutor}) {
  const dateAndTime = `${new Date(date).toDateString()} at ${book_time.slice(0,5)}`;
  const timezone = book_tutor?.timezone?.gmt_offset;
  return (
    <div>
        <p>Date and time</p>
        <p><b>{dateAndTime}</b></p>
        <p>{timezone}</p>
    </div>
  )
}

export default DateAndTime