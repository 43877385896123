import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import bookmark from "../../assets/img/bookmark.svg";
import hour from "../../assets/img/24-hour.svg";
import team from "../../assets/img/team.svg";
import network from "../../assets/img/network.svg";
import { Link } from "react-router-dom";

import "./aboutus.css";

function Aboutus() {
  return (
    <>
      <section id="services" className="services">
        <Container>
          <div className="section-title">
            <h2>
              Start your language journey with us and <br /> express yourself
              with confidence
            </h2>
          </div>
          <Row>
            <Col
              md="3"
              className="col-lg-3 align-items-stretch order-1 order-lg-1 img"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon-box">
                <div className="icon">
                  <img src={bookmark} className="img-fluid" alt="" />
                </div>
                <h4>
                  <Link to="">Find the best tutor</Link>
                </h4>
                <p>
                  Choose from over 1,000 online tutors. Use filters to narrow
                  your search and find the perfect fit.
                </p>
              </div>
            </Col>
            <Col
              md="3"
              className="col-lg-3 align-items-stretch order-1 order-lg-1 img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="icon-box">
                <div className="icon">
                  <img src={hour} className="img-fluid" alt="" />
                </div>
                <h4>
                  <Link to="">Take lessons anytime</Link>
                </h4>
                <p>
                  Find the perfect time for your busy schedule. Book lessons in
                  via an intuitive dashboard.
                </p>
              </div>
            </Col>
            <Col
              md="3"
              className="col-lg-3 align-items-stretch order-1 order-lg-1 img"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="icon-box">
                <div className="icon">
                  <img src={team} className="img-fluid" alt="" />
                </div>
                <h4>
                  <Link to="">Enter virtual classroom</Link>
                </h4>
                <p>
                  When it’s lesson time, connect with your tutor through our
                  comprehensive video platform.
                </p>
              </div>
            </Col>
            <Col
              md="3"
              className="col-lg-3 align-items-stretch order-1 order-lg-1 img"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div className="icon-box">
                <div className="icon">
                  <img src={network} className="img-fluid" alt="" />
                </div>
                <h4>
                  <Link to="">Enjoy structured learning</Link>
                </h4>
                <p>
                  Keep track of your learning progress. Improve your speaking
                  and vocabulary.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Aboutus;
