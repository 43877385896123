const PageNotFound = ()=>{
    return (
        <>
      <main id="main" className="bg-inner">
        <section className="student-signup-page signup-congrats">
          <div className="container">
            <div className="row d-flex flex-column justify-content-center align-items-center">
              <div className="col-lg-6  order-1 order-lg-1">
                <div className="content">
                    <h3>404</h3>
                  <h3 className="mb-4">Page not Found</h3>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
    )
}

export default PageNotFound;