import React from "react";

function SmsNotification({ notifications, setNotifications }) {
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setNotifications({
        ...notifications,
        [name]: "1",
      });
    } else {
      setNotifications({
        ...notifications,
        [name]: "0",
      });
    }
  };
  return (
    <div className="col-md-12 mb-4">
      <div className="form-check mt-sm-2">
        <input
          className="form-check-input"
          defaultChecked={notifications.lessons_messages === "1"}
          type="checkbox"
          name="lessons_messages"
          onChange={handleCheckbox}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Lessons and messages
        </label>
      </div>
    </div>
  );
}

export default SmsNotification;
