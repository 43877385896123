import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step1: "",
  step2: "",
  step3: "",
  step4: "",
  step5: "",
  step6: "",
  step7: "",
};

export const stepSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    step1: (state, action) => {
      return {
        ...state,
        step1: action.payload,
      };
    },
    step2: (state, action) => {
      return {
        ...state,
        step2: action.payload,
      };
    },
    step3: (state, action) => {
      return {
        ...state,
        step3: action.payload,
      };
    },
    step4: (state, action) => {
      return {
        ...state,
        step4: action.payload,
      };
    },
    step5: (state, action) => {
      return {
        ...state,
        step5: action.payload,
      };
    },
    step6: (state, action) => {
      return {
        ...state,
        step6: action.payload,
      };
    },
    step7: (state, action) => {
      return {
        ...state,
        step7: action.payload,
      };
    },
  },
});

export const { step1, step2, step3, step4, step5, step6, step7 } =
  stepSlice.actions;
export default stepSlice.reducer;
