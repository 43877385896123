import React, { useState, useEffect } from "react";
import Notification from "./Notification";
import { getNotificationSettings } from "../../../../../services/student";
import CustomSpinner from "../../../../../ui/loaders/CustomSpinner";
function Notifications() {
  const [loader, setLoader] = useState(true);
  const [notifications, setNotifications] = useState({
    lesson_scheduling: "0",
    general_reminders: "0",
    updates_tips: "0",
    daylearning_blog: "0",
    qa: "0",
    lessons_messages: "0",
    product_improvements: "0",
  });
  const [newUser, setNewUser] = useState(true);
  useEffect(() => {
    getNotificationSettings().then((res) => {
      const data = res.data.data[0];
      if (data) {
        setNewUser(false);
        setNotifications({
          lesson_scheduling: data.lesson_scheduling,
          general_reminders: data.general_reminders,
          updates_tips: data.updates_tips,
          daylearning_blog: data.daylearning_blog,
          qa: data.qa,
          lessons_messages: data.lessons_messages,
          product_improvements: data.product_improvements,
        });
      }
      setLoader(false);
    });
  }, []);
  return loader ? (
    <CustomSpinner />
  ) : (
    <Notification
      notifications={notifications}
      setNotifications={setNotifications}
      newUser={newUser}
      setNewUser={setNewUser}
    />
  );
}

export default Notifications;
