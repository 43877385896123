import React from "react";
import { Link } from "react-router-dom";

// message component
import MessageComponent from "./Message";

function Message() {
  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-dark"
        style={{ marginTop: "10%" }}
      >
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link
                  to="/student/dashboard/message"
                  className="nav-link"
                  name="message"
                >
                  Message
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/student/dashboard/my-lesson"
                  className="nav-link"
                  name="myLesson"
                >
                  My Lesson
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/student/dashboard/setting"
                  className="nav-link"
                  name="setting"
                >
                  Settings
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container mt-5 mb-5">
        <MessageComponent />
      </div>
    </>
  );
}

export default Message;
