import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getTutor } from "../../../services/tutors";
import CustomSpinner from "../../../ui/loaders/CustomSpinner";
import Step7Copy from "../Profile/steps/Step7Copy";
import { Card } from "react-bootstrap";

function AvailabilityTab() {
  const [user, setUser] = useState();
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getTutor()
      .then((res) => {
        setUser(res.data.data);
        setLoader(false);
      })
      .catch((err) => setLoader(false));
  }, []);
  return (
    <Card>
      <Card.Header>Availabilty</Card.Header>
      <Card.Body>
        {loader ? <CustomSpinner /> : <Step7Copy user={user} />}
      </Card.Body>
    </Card>
  );
}

export default AvailabilityTab;
