import React from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import "./tutorSignup.css";
import axios from "axios";
import { Link } from "react-router-dom";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const errorNotify = (msg) =>
  toast(msg, {
    duration: 3000,
    position: "top-right",
  });

function TutorSignup(props) {
  const { setEmail } = props;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });
  const onSubmit = async (data) => {
    const url = baseUrl + "/register";
    // tutor role ID
    const role_id = { role_id: 2 };
    data = Object.assign(data, role_id);

    const headers = { Accept: "application/json" };
    await axios
      .post(url, data, { headers })
      .then(async (response) => {
        setEmail({
          email: data.email,
          type: 2,
        });
        navigate("/congratulation");
      })
      .catch((error) => {
        let err;
        if (error.response.status === 422) {
          err = Object.values(error.response.data.errors)[0][0];
        } else if (error.response.status === 400) {
          err = error.response.data.message;
        } else {
          err = "Something went wrong";
        }
        errorNotify(err);
      });
  };
  return (
    <>
      <main id="main" className="bg-inner">
        <section className="student-signup-page why-us">
          <div className="container" style={{ paddingTop: "30px" }}>
            <div className="row">
              <div className="col-lg-6 d-flex flex-column align-items-stretch  order-1 order-lg-1">
                <div className="content">
                  <h3>
                    Become a professonal tutor with DayLearning & teach the
                    World.
                  </h3>
                  <p>
                    When you help others along their creative journey, it’s
                    rewarding in more ways than one. By teaching on Daylearning,
                    you can grow your online following, give back, and earn
                    money. Top earning teachers make $100,000+ each year.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 align-items-stretch order-2 order-lg-2">
                <div className="student-signup-wrapper">
                  <div className="student-form-header">
                    <div className="w-100">
                      <h3 className="title mb-3">Sign up as a Tutor </h3>
                    </div>
                    <div className="w-100">
                      <p className="subtitle mb-4">
                        Join the world biggest community.
                      </p>
                    </div>
                  </div>
                  <form
                    action=""
                    className="signin-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="first_name">
                        First name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter first name"
                        autoComplete="off"
                        {...register("first_name", {
                          required: true,
                          minLength: 3,
                          maxLength: 100,
                        })}
                      />
                      {errors.first_name?.type === "required" && (
                        <span className="text-danger">First name is required</span>
                      )}
                       {errors.first_name?.type ==="minLength"  && (
                        <span className="text-danger">First name is must be grater then 3 characters!</span>
                      )}
                      {errors.first_name?.type ==="maxLength"  && (
                        <span className="text-danger">First name is must be less than 100 characters!</span>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="last_name">
                        Last name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter last name"
                        autoComplete="off"
                        {...register("last_name", {
                          required: true,
                          minLength: 3,
                          maxLength: 100,
                        })}
                      />
                      {errors.last_name?.type === "required" && (
                        <span className="text-danger">Last name is required</span>
                      )}
                      {errors.last_name?.type ==="minLength"  && (
                        <span className="text-danger">Last name is must be grater then 3 characters!</span>
                      )}
                      {errors.last_name?.type ==="maxLength"  && (
                        <span className="text-danger">Last name is must be less then 100 characters!</span>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter email address"
                        autoComplete="off"
                        {...register("email", {
                          required: true,
                          maxLength: 100,
                          pattern:
                            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                        })}
                      />
                      {errors.email?.type === "required" && (
                        <span className="text-danger">Email is required!</span>
                      )}
                      {errors.email?.type === "pattern" && (
                        <span className="text-danger">Invalid email!</span>
                      )}
                      {errors.email?.type === "maxLength" && (
                        <span className="text-danger">
                          Maximum length exceeded. You can enter maximum 100 characters in email!
                        </span>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter password"
                        autoComplete="off"
                        {...register("password", {
                          required: true,
                          maxLength: 100,
                          pattern:
                            /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,100}/,
                        })}
                      />
                      {errors.password?.type === "required" && (
                        <span className="text-danger">
                          Password is required!
                        </span>
                      )}
                      {errors.password?.type === "pattern" && (
                        <span className="text-danger">
                           Password must be alteast 8 character long must contain
                          1 upper letter, 1 lower letter, 1 number and 1 special character!
                        </span>
                      )}
                      {errors.password?.type === "maxLength" && (
                        <span className="text-danger">
                           Maximum length exceeded. You can enter maximum 100 characters in password!
                        </span>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="confirm_password">
                        Confirm password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter confirm password"
                        autoComplete="off"
                        {...register("confirm_password", {
                          required: true,
                          maxLength: 100,
                          pattern:
                            /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,100}/,
                        })}
                      />
                      {errors.confirm_password?.type === "required" && (
                        <span className="text-danger">
                          Confirm password is required!
                        </span>
                      )}
                      {watch("confirm_password") !== watch("password") && (
                        <span className="text-danger">
                          Confirm password must be same!
                        </span>
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <button
                        type="submit"
                        className="form-control btn btn-primary rounded submit px-3"
                        disabled={!isDirty || !isValid}
                      >
                        Sign up as a Tutor
                      </button>
                    </div>
                    <Toaster
                      toastOptions={{
                        style: {
                          background: "red",
                          color: "white",
                        },
                      }}
                    />
                    <p className="terms">
                      By Clicking "Sign Up" button I agree to DayLearing <Link to="/terms-and-conditions">Terms
                      Of Use</Link> and <Link to="/privacy-policy">Privacy Statement</Link>.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default TutorSignup;
