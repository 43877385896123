import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import logo1 from "../../../../assets/img/Logo-1.svg";
import { forgotPassword } from "../../../../services/student";
function ForgotPasswordModal(props) {
  const { show, setShow } = props;
  const [showEmailModal, setShowEmailModal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  // on submit fucntion
  const onSubmitForgotForm = (data) => {
    forgotPassword(data).then((res) => {
      setShowEmailModal(true);
      setShow(false);
    });
  };
  return (
    <>
      {/* forgot modal */}
      <Modal id="loginForm" show={show} onHide={() => setShow(false)}>
        <Button
          type="button"
          onClick={() => setShow(false)}
          className="btn-close"
          data-bs-dismiss="modal"
        ></Button>
        <Modal.Header className="m-2">
          <Link to="" className="logo">
            <img src={logo1} alt="" className="img-fluid" />
          </Link>
        </Modal.Header>
        <Modal.Body>
          <div className="m-3">
            <h2>Forgot your password</h2>
            <p>We'll email you instructions on how to reset it.</p>
            {/* forgot  form */}
            <form
              className="signin-form"
              onSubmit={handleSubmit(onSubmitForgotForm)}
            >
              <div className="form-group mb-3">
                <label className="" htmlFor="email" style={{ marginBottom: "0.5rem" }}>
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter email address"
                  autoComplete="off"
                  {...register("email", {
                    required: true,
                    pattern:
                      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                  })}
                />
                {errors.forgot_email?.type === "required" && (
                  <p className="text-danger">Email is required.</p>
                )}
                {errors.forgot_email?.type === "pattern" && (
                  <span className="text-danger">
                    Please enter a valid email address
                  </span>
                )}
              </div>
              <div className="form-group mb-4">
                <button
                  type="submit"
                  className="form-control btn btn-primary rounded submit px-3"
                  style={{
                    backgroundColor: "#1a2e9e",
                  }}
                >
                  Recover Password
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* modal to show when email is sent successfully */}
      <Modal
        id="loginForm"
        show={showEmailModal}
        onHide={() => setShowEmailModal(false)}
      >
        <Button
          className="btn-close"
          data-bs-dismiss="modal"
          onClick={() => setShowEmailModal(false)}
          aria-label="Close"
        ></Button>
        <Modal.Header className="m-2">
          <Link to="#" className="logo">
            <img src={logo1} alt="" className="img-fluid" />
          </Link>
        </Modal.Header>
        <Modal.Body>
          <div className="m-3">
            <h2>Successful!</h2>
            <p>
              An email has been sent with a link to reset your account password.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ForgotPasswordModal;
