import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookLessonListing } from "../../../services/tutors";
import { errorNotify } from "../../../ui/toasters";
import Pagination from "react-bootstrap/Pagination";
import { Modal, Button } from "react-bootstrap";
// custom spinner
import CustomSpinner from "../../../ui/loaders/CustomSpinner";
import Listing from "./Listing";
import Card from "react-bootstrap/Card";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import { openEditBookingSlotsModal } from "../../../features/modal/modalSlice";
import { getTutor } from "../../../services/tutors";
import Spinner from "react-bootstrap/Spinner";
import momentPlugin from "@fullcalendar/moment";
import interactionPlugin from "@fullcalendar/interaction";
function BookingSlot() {
  const state = useSelector((state) => state.modalState.editBookingModal.value);

  //const availableSlots = useState(true);
  const [loader, setLoader] = useState(true);
  const [availableSlots, setAvailableSlots] = useState([]);
  useEffect(() => {
    getTutor()
      .then((res) => {
        const data = res?.data?.data;
        setAvailableSlots(
          data?.avaiability?.map((i) => {
            return {
              title: i?.start_time_utc?.slice(0, 5),
              start: i?.slot_date,
              color: i?.booking==="0" ? "blue" : "#ccc",
            };
          })
        );
        setLoader(false);
      })
      .catch((err) => errorNotify("Something went wrong"));
  }, [state]);
  const dispatch = useDispatch();
  return (
    <>
      <Card>
        <Card.Header>
          Bookings Slots{" "}
          <Button
            className="float-right"
            onClick={() => {
              dispatch(openEditBookingSlotsModal());
            }}
          >
            Add slots
          </Button>
        </Card.Header>
        <>
          <Card.Body>
            {loader ? (
              <div className="text-center">
                <Spinner
                  className="text-center"
                  animation="border"
                  role="status"
                >
                  <span className="visually-hidden text-center">
                    Loading...
                  </span>
                </Spinner>
              </div>
            ) : (
              <>
                <FullCalendar
                  plugins={[dayGridPlugin, momentPlugin, interactionPlugin]}
                  weekends={true}
                  events={availableSlots}
                  selectable={true} 
                  editable={false} 
                />
              </>
            )}
          </Card.Body>
        </>
      </Card>
    </>
  );
}

export default BookingSlot;
