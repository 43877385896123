import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { step4 } from "../../../../features/Tutor/stepsSlice";
import Select from "react-select";
import { education, document } from "../../../../services/tutors";
import { successNotify, errorNotify } from "../../../../ui/toasters";
import { uploading } from "../../../../features/uploading/uploadingSlice";
import UploadFile from "./upload-file/UploadFile";
function Step4(props) {
  // error state for date validation
  const [dateError, setDateError] = useState({ error: null, message: null });

  const [degreeType, setDegreeType] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    setDegreeType([
      { value: "Teaching degree", label: "Teaching degree" },
      {
        value: "Degree in the subject you teach",
        label: "Degree in the subject you teach",
      },
      { value: "Other degree", label: "Other degree" },
    ]);
  }, []);
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const { steps, setSteps, user } = props;
  // required error for document
  const [required, setRequired] = useState("");
  // for year list
  const [years, setYears] = useState([]);
  const state = useSelector((state) => state?.steps?.step4);
  const [file, setFile] = useState({
    document:
      state?.diploma?.document ||
      user?.teacher_education?.teacher_document?.name,
    document_id:
      state?.diploma?.document_id ||
      user?.teacher_education?.teacher_document?.id,
  });
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      university: user?.teacher_education?.university,
      degree: user?.teacher_education?.degree,
      degree_type: {
        label: user?.teacher_education?.degree_type,
        value: user?.teacher_education?.degree_type,
      },
      specialization: user?.teacher_education?.specialization,
      start_study_year: {
        label: user?.teacher_education?.start_study_year,
        value: user?.teacher_education?.start_study_year,
      },
      end_study_year: {
        label: user?.teacher_education?.end_study_year,
        value: user?.teacher_education?.end_study_year,
      },
      diploma: user?.teacher_education?.teacher_document?.name,
    },
  });

  // sumitting the form
  const onSubmit = async (data) => {
    data.diploma = file;
    if (data.diploma.document) {
      dispatch(step4(data));
      const object = {
        university: data.university,
        degree: data.degree,
        degree_type: data.degree_type.value,
        specialization: data.specialization,
        start_study_year: data.start_study_year.value,
        end_study_year: data.end_study_year.value,
        document_id: data.diploma.document_id,
      };
      // disbling next button so user can't hit API more than once
      setDisableNextBtn(true);

      education(object)
        .then((res) => {
          if (res.status === 200) {
            successNotify(res.data.message);
            setSteps({
              ...steps,
              step4: { value: true, display: "show" },
              step3: { value: true, display: "none" },
            });
          }
        })
        .catch((err) => {
          errorNotify("Something went wrong");
          setDisableNextBtn(false);
        });
    }
  };

  // handle image upload
  const handleChange = (event) => {
    dispatch(uploading(true));
    const formData = new FormData();
    formData.append("document", event.target.files[0]);
    document(formData)
      .then((res) => {
        dispatch(uploading(false));
        setRequired(false);
        setFile({
          ...file,
          document: res.data.data.name,
          document_id: res.data.data.id,
        });
      })
      .catch((err) => {
        dispatch(uploading(false));
      });
  };

  // handle image reset
  const resetImage = () => {
    setRequired(true);
    reset({ diploma: "" });
    setFile({
      document: null,
      document_id: null,
    });
  };

  // setting year list
  useEffect(() => {
    let yearArray = [];
    for (var i = 1976; i <= new Date().getFullYear(); i++) {
      yearArray.push(i);
    }
    setYears(
      yearArray.map((i) => {
        return {
          label: i,
          value: i,
        };
      })
    );
  }, []);
  // handle date check
  const handleDateCheck = (e) => {
    const start_date = getValues("start_study_year").value;
    const end_date = getValues("end_study_year").value;
    // for start date
    if (e === "startDate") {
      if (start_date < end_date) {
        setDateError({ error: null, message: null });
        return true;
      } else if (start_date >= end_date) {
        setDateError({
          error: true,
          message: "Start date cannot be equal or bigger than end date",
        });
        return "Error";
      } else {
        setDateError({ error: null, message: null });
        return true;
      }
    }
    // for end date
    else {
      if (end_date > start_date) {
        setDateError({ error: null, message: null });
        return true;
      } else if (end_date <= start_date) {
        setDateError({
          error: true,
          message: "End date cannot be equal or smaller than start date",
        });
        return "Error";
      } else {
        setDateError({ error: null, message: null });
        return true;
      }
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="panel panel-primary setup-content pb-5" id="step-4">
          <div className="panel-body">
            <div className="form-row custom-form-mt">
              <div className="form-group">
                <label htmlFor="experienceteaching">University</label>
                <input
                  type="university"
                  name="university"
                  id="university"
                  className="form-control"
                  defaultValue={state?.university}
                  {...register("university", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
                {errors?.university?.type === "required" && (
                  <span className="text-danger">
                    {errors?.university?.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="experienceteaching">Degree</label>
                <input
                  type="degree"
                  name="degree"
                  id="degree"
                  defaultValue={state?.degree}
                  className="form-control"
                  {...register("degree", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
                {errors?.degree?.type === "required" && (
                  <span className="text-danger">{errors?.degree?.message}</span>
                )}
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail4">Degree Type</label>
                  <Controller
                    control={control}
                    name="degree_type"
                    rules={{
                      required: {
                        value: true,
                        message: "Required",
                      },
                    }}
                    defaultValue={state?.degree_type}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        placeholder="Degree Type"
                        inputExtraProps={{
                          ref,
                          required: true,
                        }}
                        options={degreeType}
                      />
                    )}
                  />
                  {errors?.degree_type?.type === "required" && (
                    <span className="text-danger">
                      {errors?.degree_type?.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="experienceteaching">Specialization</label>
                  <input
                    type="specialization"
                    name="specialization"
                    id="specialization"
                    className="form-control"
                    defaultValue={state?.specialization}
                    {...register("specialization", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  />
                  {errors?.specialization?.type === "required" && (
                    <span className="text-danger">
                      {errors?.specialization?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div
                  className="col"
                  style={{ display: "grid", marginRight: "20px" }}
                >
                  <label htmlFor="country">Year of Study</label>
                  <Controller
                    control={control}
                    name="start_study_year"
                    rules={{
                      required: {
                        value: true,
                        message: "Required",
                      },
                      // date validation check for start date
                      validate: () => handleDateCheck("startDate"),
                    }}
                    defaultValue={state?.start_study_year}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        placeholder="Select Year"
                        inputExtraProps={{
                          ref,
                          required: true,
                        }}
                        options={years.sort((a, b) => b.value - a.value)}
                      />
                    )}
                  />
                  {errors?.start_study_year?.type === "required" && (
                    <span className="text-danger">
                      {errors?.start_study_year?.message}
                    </span>
                  )}
                </div>
                <div className="col">
                  <label htmlFor="Level"></label>
                  <Controller
                    control={control}
                    name="end_study_year"
                    rules={{
                      required: {
                        value: true,
                        message: "Required",
                      },
                      // date validation check for end date
                      validate: () => handleDateCheck("endDate"),
                    }}
                    defaultValue={state?.start_study_year}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        placeholder="Select Year"
                        inputExtraProps={{
                          ref,
                          required: true,
                        }}
                        options={years.sort((a, b) => b.value - a.value)}
                      />
                    )}
                  />
                  {errors?.end_study_year?.type === "required" && (
                    <span className="text-danger">
                      {errors?.end_study_year?.message}
                    </span>
                  )}
                </div>
                {dateError.error && (
                  <span className="text-danger">{dateError?.message}</span>
                )}
              </div>
              {/* upload file */}
              <div className="row">
                <div className="col-lg-3">
                  <label>Upload File</label>
                  <label className="upload_file_container">
                    {file?.document
                      ? file.document
                      : "Get a Diploma Verified Badge."}
                    <input
                      onChange={(e) => handleChange(e)}
                      type={"file"}
                      className="upload_input"
                      accept=".pdf,.doc"
                    />
                  </label>
                </div>
                <div className="col-lg-3 reset_button_container">
                  <button
                    type="button"
                    className="btn btn-primary form-control"
                    onClick={resetImage}
                  >
                    Reset
                  </button>
                </div>
              </div>

              {/* <UploadFile
                required={required}
                handleChange={handleChange}
                resetImage={resetImage}
                fileName={file.document}
              /> */}
              {required && <span className="text-danger">Required</span>}

              {/* <div className="form-group">
                <label className="control-label">Upload File</label>

                <div className="row">
                  <div className="col-lg-3">
                    <div className="dropzone-wrapper d-flex justify-content-center align-items-center">
                      <label>
                        Get a Diploma Verified Badge.
                        <input
                          onChange={(e) => handleChange(e)}
                          type="file"
                          accept=".pdf,.doc"
                          className="document_input"
                        />
                      </label>
                    </div>
                    {required && <span className="text-danger">Required</span>}
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="row mt-2">
              <div className="col-lg-3">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    resetImage();
                  }}
                >
                  <i className="fa fa-times"></i> Reset The Field
                </button>
              </div>
            </div> */}
            <input
              name="login"
              id="login"
              className="btn btn-primary btn-block login-btn nextBtn mt-2"
              type="submit"
              value="Next"
              disabled={disableNextBtn}
            />
          </div>
        </div>
      </form>
    </>
  );
}

export default Step4;
