import React from "react";
import { Slider } from "react-semantic-ui-range";

function RatePerHour({ setDropdown, range, dropdown, setRange }) {
  // range slider setting
  const settings = {
    start: [5, 50],
    min: 0,
    max: 200,
    step: 5,
    onChange: (value) => {
      setRange(value);
    },
  };
  return (
    <div
      className="input-group"
      style={{ position: "relative" }}
      onClick={(e) => setDropdown("range")}
    >
      <label className="label">Rate per hour</label>
      <div>
        <div id="select-range">
          {range.length > 0 ? (
            <p className="pt-2">
              ${range[0]} - ${range[1]}
            </p>
          ) : (
            <p className="pt-2">$0 - $50</p>
          )}
        </div>
        {dropdown === "range" && (
          <div className="range-select-box">
            {range.length > 0 ? (
              <p className="pt-2">
                ${range[0]} - ${range[1]}
              </p>
            ) : (
              <p className="pt-2">$0 - $50</p>
            )}
            <>
              <Slider
                style={{
                  boxShadow: "none",
                  thumb: { border: "2px solid orange" },
                }}
                color="yellow"
                multiple
                settings={settings}
              />
            </>
          </div>
        )}
      </div>
    </div>
  );
}

export default RatePerHour;
