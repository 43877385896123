import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import "./resetPassword.css";
import axios from "axios";
import { useParams } from "react-router-dom";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

// toaster for success
const successNotify = (msg) =>
  toast(msg, {
    duration: 3000,
    position: "top-right",
    style: {
      background: "green",
      color: "white",
    },
  });
// toaster on error
const errorNotify = (msg) =>
  toast(msg, {
    duration: 3000,
    position: "top-right",
    style: {
      background: "red",
      color: "white",
    },
  });

function ResetPassword(props) {
  // after success then navigate to home page
  const navigate = useNavigate();
  // reset form validation
  // form validation rules
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,50}/,
        "Password must be alteast 8 character long must contain 1 letter , 1 number and 1 special character"
      )
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  // get token from URL
  const { token } = useParams();
  const onSubmit = async (form) => {
    const obj = {
      token: token,
      password: form.password,
    };
    const url = baseUrl + "/password/reset";
    const headers = { Accept: "application/json" };
    await axios
      .post(url, obj, { headers })
      .then((response) => {
        successNotify("Password Reset successfully, Try Login again.");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((error) => {
        let err;
        if (error.response.status === 422) {
          err = Object.values(error.response.data.errors)[0][0];
        } else if (error.response.status === 400) {
          err = error.response.data.message;
        } else {
          err = "Something went wrong";
        }
        errorNotify(err);
      });
  };
  return (
    <>
      <main id="main" className="bg-inner">
        <section className="student-signup-page signup-congrats reset-password-container">
          <div
            className="container"
            style={{
              paddingRight: "13rem",
              paddingLeft: "13rem",
              marginTop: "4rem",
            }}
          >
            <div className="student-signup-wrapper">
              <div className="student-form-header">
                <div className="w-100">
                  <h3 className="title mb-3">Reset Password </h3>
                </div>
              </div>
              <form
                action=""
                className="signin-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group mb-3">
                  <label className="label" for="password">
                    New password
                  </label>
                  <input
                    name="password"
                    type="password"
                    {...register("password")}
                    className="form-control"
                    placeholder="Enter password"
                    autoComplete="off"
                  />
                  <span className="text-danger">
                    {errors.password?.message}
                  </span>
                </div>
                <div className="form-group mb-3">
                  <label className="label" for="password">
                    Confirm password
                  </label>
                  <input
                    name="confirmPassword"
                    type="password"
                    {...register("confirmPassword")}
                    className="form-control"
                    placeholder="Enter password"
                    autoComplete="off"
                  />
                  <span className="text-danger">
                    {errors.confirmPassword?.message}
                  </span>
                </div>
                <div className="form-group mb-4">
                  <button
                    type="submit"
                    className="form-control btn btn-primary rounded submit px-3"
                  >
                    Reset password
                  </button>
                </div>
                <Toaster />
              </form>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ResetPassword;
