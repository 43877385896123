import React from "react";
import badge from "../../assets/img/badge.svg";
import times from "../../assets/img/time.svg";
import language from "../../assets/img/language.svg";

function Info() {
  return (
    <section id="info" className="d-flex align-items-center">
      <div className="row">
        <h1>
          Why DayLearning is the best site for finding online Spanish teachers?
        </h1>
        <div className="col-lg-4">
          <div className="card">
            <div className="card-body">
              <img className="card-img-top w-23" src={badge} alt="Card-img" />
              <h4 className="card-title">Best Rated</h4>
              <p className="card-text">
                Learn Spanish in a dynamic and fun way with a tutor ¡Hola! My
                name is Marta.
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top w-30"
                src={language}
                alt="Card-img"
              />
              <h4 className="card-title">know 2+ Languages</h4>
              <p className="card-text">
                Learn Spanish in a dynamic and fun way with a tutor ¡Hola! My
                name is Marta.
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="card">
            <div className="card-body">
              <img className="card-img-top w-30" src={times} alt="Card-img" />
              <h4 className="card-title">10+ Hours</h4>
              <p className="card-text">
                Learn Spanish in a dynamic and fun way with a tutor ¡Hola! My
                name is Marta.
              </p>
            </div>
          </div>
        </div>

        <h3>Average rate for private Spanish tutors</h3>
        <div className="col-lg-6">
          <div className="card rate">
            <div className="card-body d-flex justify-content-between">
              <p className="card-text">Native Spanish speakers</p>
              <p className="card-text">$13/hr</p>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card rate">
            <div className="card-body d-flex justify-content-between">
              <p className="card-text">Non native Spanish speakers</p>
              <p className="card-text">$17/hr</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Info;
