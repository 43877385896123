import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import "./result.css";
import ClickAwayListener from "react-click-away-listener";
// import info,Faq,sidebar component
import Info from "./Info";
import Faq from "./Faq";
import SideBar from "./SideBar";

import { Spinner } from "react-bootstrap";
// import services
import { getTutors } from "../../services/tutors";
// helper function
import { getLists } from "../../utils/helper";
// pagination compoenent
import Pagination from "react-bootstrap/Pagination";
import TeacherCard from "./TeacherCard";
// select language component
import SelectLanguage from "./SelectLanguage";
// rate per hour component
import RatePerHour from "./RatePerHour";
// tutor origin component
import TutorOrigin from "./TutorOrigin";
// i'm available component
import ImAvailable from "./ImAvailable";
// speciality component
import Speciality from "./Speciality";
// also speak component
import AlsoSpeaks from "./AlsoSpeaks";
// native speaker component
import NativeSpeaker from "./NativeSpeaker";
// Teaches to component
import TeachesTo from "./TeachesTo";
// search bar component
import SearchBar from "./SearchBar";
import { useSelector } from "react-redux";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

function Result() {
  // for navigation
  const navigate = useNavigate();
  // to get langauage id from url
  const { state } = useLocation();
 
  // states for language
  // language state
  const [language, setLanguage] = useState(state);
  // language list state
  const [languages, setLanguages] = useState([]);

  // state from hour per rate
  const [range, setRange] = useState([0, 50]);

  // states for country
  // country state
  const [country, setCountry] = useState(null);
  // country list state
  const [countries, setCountries] = useState([]);

  // states for availability time
  // time states
  const [time, setTime] = useState([
    {
      id: 1,
      label1: "6-9",
      label2: "Morning",
      value: "00:00:00-03:00:00",
      checked: false,
      class: "morning",
    },
    {
      id: 2,
      label1: "9-12",
      label2: "Late Morning",
      value: "03:00:00-06:00:00",
      checked: false,
    },
    {
      id: 3,
      label1: "12-15",
      label2: "Afternoon",
      value: "06:00:00-09:00:00",
      checked: false,
    },
    {
      id: 4,
      label1: "15-18",
      label2: "Late Afternoon",
      value: "09:00:00-12:00:00",
      checked: false,
      class: "late_afternoon",
    },
    {
      id: 5,
      label1: "6-9",
      label2: "Evening",
      value: "12:00:00-15:00:00",
      checked: false,
      class: "evening",
    },
    {
      id: 6,
      label1: "9-12",
      label2: "Late Evening",
      value: "15:00:00-18:00:00",
      checked: false,
    },
    {
      id: 7,
      label1: "12-15",
      label2: "Night",
      value: "18:00:00-21:00:00",
      checked: false,
    },
    {
      id: 8,
      label1: "15-18",
      label2: "Late Night",
      value: "21:00:00-24:00:00",
      checked: false,
      class: "late_night",
    },
  ]);
  // day state
  const [days, setDays] = useState([
    { id: 1, label: "SUN", value: "Sunday", class: "sun", checked: false },
    { id: 2, label: "MON", value: "Monday", checked: false },
    { id: 3, label: "TUE", value: "Tuesday", checked: false },
    { id: 4, label: "WED", value: "Wednesday", checked: false },
    { id: 5, label: "THU", value: "Thursday", checked: false },
    { id: 6, label: "FRI", value: "Friay", checked: false },
    { id: 7, label: "SAT", value: "Saturday", class: "sat", checked: false },
  ]);
  // speciality state
  const [specialities, setSpecialities] = useState([]);

  // also speak state
  const [alsoSpeaks, setAlsoSpeaks] = useState([]);

  // teaches to state
  const [teachesTo, setTeachesTo] = useState([
    { id: 1, name: "All", checked: false, value: 1 },
    { id: 2, name: "Young Adult", checked: false, value: 2 },
    { id: 3, name: "Adult", checked: false, value: 3 },
    { id: 4, name: "Elder", checked: false, value: 4 },
  ]);

  // search state
  const [search, setSearch] = useState(null);

  // dropdown state
  const [dropdown, setDropdown] = useState("");

  // for pagination
  const [currentPage, setCurrentPage] = useState(1);
  // active page
  const [activePage, setActivePage] = useState(1);
  // total number of teacher
  const [totalteachers, setTotalTeachers] = useState(0);
  // pages
  const pages = Math.ceil(totalteachers / 5);
  const pageNum = [];
  // teachers
  const [teachers, setTeachers] = useState([]);
  for (let number = 1; number <= pages; number++) {
    pageNum.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => setActivePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  //for spinner
  const [loading, setLoading] = useState(true);

  // function to handle click away from dropdowns
  const handleClickAway = () => {
    setDropdown("");
  };
  const Nodata = () => {
    return <div className="no-data-container">No Data was Found</div>;
  };

  // to check what user role is
  useEffect(() => {
    const role = localStorage.getItem("user_role");
    if (role === "2") {
      navigate("/tutor/profile");
    }
  }, [navigate]);

  // get lists of languages,countris,specialities
  useEffect(() => {
    const fetch = async () => {
      const lists = await getLists();
      setLanguages(lists.languages);
      setCountries(lists.countries);
      setSpecialities(lists.specialties);
    };
    fetch();
  }, []);

  // get filtered teachers
  useEffect(() => {
    setLoading(true);
    let url = baseUrl + `/teachers?per_page=${5}&page=${activePage}`;
    const fetch = async () => {
      // send language id
      if (language) {
        url += "&language_id=" + language.value;
      }
      // send rate per hour
      if (range) {
        url += `&hourly_rate=${range[0]}-${range[1]}`;
      }
      // send countries id
      if (country) {
        const country_id = country.map((i) => {
          return i.value;
        });
        if (country_id.length > 0) {
          url += "&country_id=" + country_id.toString();
        }
      }
      // send available time
      if (time) {
        let available_time = time.filter((i) => {
          return i.checked;
        });
        if (available_time?.length > 0) {
          available_time = available_time.map((i) => {
            return i.value;
          });
          url += "&available_time=" + available_time.toString();
        }
      }
      // send days
      if (days) {
        let available_days = days.filter((i) => {
          return i.checked;
        });
        if (available_days?.length > 0) {
          available_days = available_days.map((i) => {
            return i.value;
          });
          url += "&available_days=" + available_days.toString();
        }
      }
      // send Speciality
      if (specialities) {
        let speciality = specialities.filter((i) => {
          return i.checked;
        });
        if (speciality.length > 0) {
          speciality = speciality.map((i) => {
            return i.value;
          });
          url += "&speciality=" + speciality.toString();
        }
      }
      // send also speaks
      if (alsoSpeaks) {
        let also_speaks = alsoSpeaks.map((i) => {
          return i.value;
        });
        if (also_speaks.length > 0) {
          url += "&also_speaks=" + also_speaks.toString();
        }
      }
      // send teaches to
      if (teachesTo) {
        let teaches_to = teachesTo.filter((i) => {
          return i.checked;
        });
        if (teaches_to.length > 0) {
          teaches_to = teaches_to.map((i) => {
            return i.value;
          });
          url += "&teaches_to=" + teaches_to.toString();
        }
      }
      // send search
      if (search) {
        url += "&search_by=" + search;
      }
      await getTutors(url).then((res) => {
         
        const data = res.data.data;

        setTotalTeachers(res.data.total);
        setCurrentPage(res.data.current_page);
        setTeachers(data);
        setLoading(false);
        setTeachers(data);
      });
    };
    fetch();
  }, [
    language,
    range,
    activePage,
    country,
    time,
    days,
    specialities,
    alsoSpeaks,
    teachesTo,
    search,
  ]);
  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1 text-center">
              <h1>Find the best {language?.label} tutors for online lessons</h1>
              <h2 className="mb-5">
                Find the best Spanish tutor for you. Choose from our experienced
                tutors online and get the best learning experience.
              </h2>
              <div className="card card-7">
                <div className="card-body">
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Form className="form advance-search max-width">
                      <div className="search-upper-section">
                        {/* select lanaguage */}
                        <SelectLanguage
                          language={language}
                          setLanguage={setLanguage}
                          languages={languages}
                          setDropdown={setDropdown}
                          dropdown={dropdown}
                        />
                        {/* rate per hour */}
                        <RatePerHour
                          setDropdown={setDropdown}
                          range={range}
                          dropdown={dropdown}
                          setRange={setRange}
                        />
                        {/* search-box tuitor origin */}
                        <TutorOrigin
                          setDropdown={setDropdown}
                          dropdown={dropdown}
                          countries={countries}
                          setCountry={setCountry}
                        />
                        {/* im available */}
                        <ImAvailable
                          setDropdown={setDropdown}
                          dropdown={dropdown}
                          time={time}
                          setTime={setTime}
                          days={days}
                          setDays={setDays}
                        />
                      </div>
                      <div className="lower-section d-flex justify-content-between">
                        <div className="left-sec d-flex">
                          {/* speciality */}
                          <Speciality
                            setDropdown={setDropdown}
                            dropdown={dropdown}
                            specialities={specialities}
                            setSpecialities={setSpecialities}
                          />
                          {/* Also speaks  */}
                          <AlsoSpeaks
                            setDropdown={setDropdown}
                            dropdown={dropdown}
                            languages={languages}
                            setAlsoSpeaks={setAlsoSpeaks}
                          />
                          {/* native speaker */}
                          <NativeSpeaker
                            setDropdown={setDropdown}
                            dropdown={dropdown}
                          />
                          {/* teaches to */}
                          <TeachesTo
                            setDropdown={setDropdown}
                            dropdown={dropdown}
                            teachesTo={teachesTo}
                            setTeachesTo={setTeachesTo}
                          />
                        </div>
                        <div className="right-sec d-flex">
                          {/* search box */}
                          <SearchBar setSearch={setSearch} />
                        </div>
                      </div>
                    </Form>
                  </ClickAwayListener>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section className="inner-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mb-3 filters">
                <div className="d-flex justify-content-between">
                  <h3>
                    Found {totalteachers}+ {language?.label} teachers for you
                  </h3>
                  {/* <p>
                    <i className="bi bi-filter"></i> Relevance
                  </p> */}
                </div>
              </div>
              {/* search result */}
              <div className="col-lg-8 mb-5 mb-lg-0">
                {loading ? (
                  <div style={{ textAlign: "center" }}>
                    <Spinner animation="border" />
                    <b style={{ marginLeft: "1rem", verticalAlign: "super" }}>
                      Loading...
                    </b>
                  </div>
                ) : totalteachers !== 0 ? (
                  <div>
                    {teachers?.map((i, index) => {
                      return (
                        <TeacherCard
                          teacher={i}
                          key={index}
                          language={language}
                        />
                      );
                    })}
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => setActivePage((prev) => prev - 1)}
                        disabled={currentPage === 1}
                      />
                      {pageNum}
                      <Pagination.Next
                        onClick={() => setActivePage((prev) => prev + 1)}
                        disabled={currentPage === pages}
                      />
                    </Pagination>
                  </div>
                ) : (
                  <Nodata />
                )}
                {/* info section */}
                <Info />
                {/* faq section */}
                <Faq />
              </div>
              {/* sidebar */}
              <SideBar />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Result;
