import React from 'react';
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo1 from "../../assets/img/Logo-1.svg";
import { useForm } from "react-hook-form";
import { openLoginModal,openCongratulationModal,closeModal } from "../../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
// import toasters
import { errorNotify } from "../../ui/toasters";
// library  for API
import axios from "axios";

const baseURL = process.env.REACT_APP_API_ENDPOINT;


function ForgotPassword() {
  const state = useSelector(state=>state.modalState.forgotModal);
  const dispatch =useDispatch();
  const {
    register: register2,
    reset,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onChange",
  });
   // on submit forgot form
   const onSubmitForgotForm = async (form) => {
    const email = { email: form.forgot_email };
    const url = baseURL + "/password/forgot-password";
    const headers = {
      Accept: "application/json",
    };
    await axios
      .post(url, email, { headers })
      .then(async (response) => {
        // loginFormShow(false);
        // setForgotModal(false);
        // setEmailSuccessModal(true);
        dispatch(openCongratulationModal())
        reset();
      })
      .catch((error) => {
        let err;
        if (error.response.status === 400) {
          err = error.response.data.message;
        } else if (error.response.status === 422) {
          err = Object.values(error.response.data?.errors)[0][0];
        } else {
          err = "Something went Wrong";
        }
        errorNotify(err);
      });
  };
  return (
    <Modal id="loginForm" show={state} onHide={()=>dispatch(closeModal())}>
    <Button
      className="btn-close"
      data-bs-dismiss="modal"
      onClick={()=>dispatch(closeModal())}
      aria-label="Close"
    ></Button>
    <Modal.Header className="m-2">
      <Link to="#" className="logo">
        <img src={logo1} alt="" className="img-fluid" />
      </Link>
    </Modal.Header>
    <Modal.Body>
      <div className="m-3">
        <h2>Forgot your password</h2>
        <p>We'll email you instructions on how to reset it.</p>
        {/* forgot  form */}
        <form
          action=""
          className="signin-form"
          onSubmit={handleSubmit2(onSubmitForgotForm)}
        >
          <div className="form-group mb-3">
            <label
              className=""
              htmlFor="email"
              style={{ marginBottom: "0.5rem" }}
            >
              Email
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter email address"
              autoComplete="off"
              {...register2("forgot_email", {
                required: true,
                pattern:
                  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
              })}
            />
            {errors2.forgot_email?.type === "required" && (
              <p className="text-danger">Email is required.</p>
            )}
            {errors2.forgot_email?.type === "pattern" && (
              <span className="text-danger">
                Please enter a valid email address
              </span>
            )}
          </div>
          <div className="form-group mb-4">
            <button
              type="submit"
              className="form-control btn btn-primary rounded submit px-3"
              style={{
                backgroundColor: "#1a2e9e",
              }}
            >
              Recover Password
            </button>
            <div
              onClick={()=>dispatch(openLoginModal())}
              style={{
                marginTop: "1rem",
                color: "blue",
                cursor: "pointer",
              }}
            >
              <p>
                <i className="bi bi-arrow-left"></i> Back to login page
              </p>
            </div>
          </div>
        </form>
      </div>
    </Modal.Body>
  </Modal>
  )
}

export default ForgotPassword