import React from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../features/modal/modalSlice";
import { successNotify, errorNotify } from "../ui/toasters";
import { updateCard } from "../services/student";
function UpdateCard({ setUpdateCard }) {
  // states
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    await stripe
      .createToken(
        elements.getElement(
          CardNumberElement,
          CardExpiryElement,
          CardCvcElement
        )
      )
      .then((res) => {
        setLoading(true);
        if (res.error) {
          setError(res.error);
          setLoading(false);
        } else {
          const obj = {
            stripe_token: res.token.id,
          };
          updateCard(obj)
            .then((res) => {
              setLoading(false);
              successNotify(res.data.message);
              dispatch(closeModal());
              setUpdateCard((prev) => !prev);
            })
            .catch((err) => {
              setLoading(false);
              errorNotify("Something went wrong");
            });
          setError(null);
        }
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row mb-4">
        <div className="col-lg-12 mb-2">
          <label>Card Number</label>
          <CardNumberElement className="form-control" />
        </div>
        <div className="col-lg-6">
          <label>Expiration</label>
          <CardExpiryElement className="form-control" />
        </div>
        <div className="col-lg-6">
          <label>CVC</label>
          <CardCvcElement className="form-control" />
        </div>
        {error && <span className="text-danger">{error?.message}</span>}
      </div>
      <button className="btn btn-primary form-control">
        {loading ? "Updating..." : "Update Card"}
      </button>
    </form>
  );
}

export default UpdateCard;
