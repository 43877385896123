import React from "react";

function Education({ teacher_education }) {
  if (teacher_education) {
    return (
      <div
        className="tab-pane active"
        id="Education"
        role="tabpanel"
        aria-labelledby="Education-tab"
      >
        <div className="row">
          <div className="col-6 col-md-3">
            <p>
              {teacher_education?.start_study_year} —{" "}
              {teacher_education?.end_study_year}
            </p>
          </div>
          <div className="col-12 col-md-9">
            <p>
              {teacher_education?.university} {teacher_education?.degree}
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="tab-pane active"
        id="Education"
        role="tabpanel"
        aria-labelledby="Education-tab"
      >
        <div className="row">
          <p>No data on education</p>
        </div>
      </div>
    );
  }
}

export default Education;
