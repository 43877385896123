import React, { useEffect, useState } from "react";
import Profile from "./Profile";
import Spinner from "react-bootstrap/Spinner";
import { getTutor } from "../../../services/tutors";
import { useDispatch } from "react-redux";
import {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
} from "../../../features/Tutor/stepsSlice";
import { errorNotify } from "../../../ui/toasters";

function TutorProfile() {
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    getTutor()
      .then((res) => {
        const data = res.data.data;
        if (data.profile_image_id) {
          dispatch(step1(true));
          dispatch(step2(true));
        }
        if (data.teacher_certificate) {
          dispatch(step3(true));
        }
        if (data.teacher_education) {
          dispatch(step4(true));
        }
        if (data.teacher_description) {
          dispatch(step5(true));
        }
        if (data.video_url) {
          dispatch(step6(true));
        }
        setLoader(false);
      })
      .catch((err) => errorNotify("Something went wrong"));
  }, [dispatch]);
  if (loader) {
    return (
      <div className="d-flex align-items-center profile_spinner">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return <Profile />;
  }
}

export default TutorProfile;
