import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./congratulation.css";
import axios from "axios";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;
// toaster for success
const successNotify = (msg) =>
  toast(msg, {
    duration: 3000,
    position: "top-right",
    style: {
      background: "green",
      color: "white",
    },
  });
// toaster on error
const errorNotify = (msg) =>
  toast(msg, {
    duration: 3000,
    position: "top-right",
    style: {
      background: "red",
      color: "white",
    },
  });

function Congratulation(props) {
  // for making user not to access this page without registering a email
  const navigate = useNavigate();
  if (!props.email) {
    navigate("/");
  }
  // state to manage button enable and disable
  const [resendBtn, setresendBtn] = useState(true);
  // response state
  const [response, setResponse] = useState(false);
  const { email } = props;
  // handle buttonclick
  const handleClick = async () => {
    const url = baseUrl + "/register/resend";
    const headers = { Accept: "application/json" };
    const obj = {
      email: email.email,
      type:email.type
    };
    await axios
      .post(url, obj, { headers })
      .then((response) => {
        setresendBtn(false);
        setNum(60);
        setResponse(true);
        successNotify("Email resend successfully, check your mail inbox");
        setTimeout(() => {
          setresendBtn(true);
        }, 60000);
      })
      .catch((error) => {
        let err;
        if (error.response.status === 422) {
          err = Object.values(error.response.data.errors)[0][0];
        } else if (error.response.status === 400) {
          err = error.response.data.message;
        } else {
          err = "Something went wrong";
        }
        errorNotify(err);
      });
  };

  // making 60 seconds countdown
  const [num, setNum] = useState(60);
  let intervalRef = useRef();
  const decreaseNum = () => setNum((prev) => prev - 1);
  useEffect(() => {
    intervalRef.current = setInterval(decreaseNum, 1000);
    return () => clearInterval(intervalRef.current);
  }, [response]);

  return (
    <>
      <main id="main" className="bg-inner">
        <section className="student-signup-page signup-congrats">
          <div className="container">
            <div className="row d-flex flex-column justify-content-center align-items-center">
              <div className="col-lg-6  order-1 order-lg-1">
                <div className="content">
                  <h3 className="mb-4">
                    Congratulations! <br /> Your account has been created.
                  </h3>
                  <p>
                    We have sent a registration email to{" "}
                    <b>{email.email ? `${email.email} ` : "noreplymail.com "}</b>
                  </p>
                  <p>Follow the instructions in the email to activate your</p>
                  <p> Daylnterpreting account.</p>
                  <div
                    style={{
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <p>
                      To ensure delivery, please add{" "}
                      <b>Support@daylearning.com</b> to your address book. lf
                      you can't find your confirmation email, please check your
                      spam folder, or click
                      <br />
                      'Resend Email' button below.
                    </p>
                  </div>
                </div>
                {resendBtn ? (
                  <button onClick={handleClick} to="#" className="mb-4 theme-btn">
                    Resend Email
                  </button>
                ) : (
                  <p style={{ marginBottom: "2rem" }}>
                    [ Please wait for{" "}
                    <b style={{ color: "blue" }}>{num} seconds</b> before
                    requesting again ]
                  </p>
                )}

                <p>
                  Didn’t get an email or having trouble?
                  <br />
                  <Link to="#">Contact Support</Link>
                </p>
              </div>
            </div>
          </div>
          <Toaster />
        </section>
      </main>
    </>
  );
}

export default Congratulation;
