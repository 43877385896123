import React from "react";

function TeachesTo({ setDropdown, dropdown, teachesTo, setTeachesTo }) {
  // handle teaches to change
  const handleTeachesTo = (id, e) => {
    // if user select all
    if (id === 1) {
      // to select all checkboxes
      if (e.target.checked) {
        setTeachesTo((s) => {
          return s.map((i) => {
            return {
              ...i,
              checked: true,
            };
          });
        });
      }
      // to deselect all checkboxes
      else {
        setTeachesTo((s) => {
          return s.map((i) => {
            return {
              ...i,
              checked: false,
            };
          });
        });
      }
    } else {
      setTeachesTo((s) => {
        return s.map((i) => {
          if (i.id === id) {
            return {
              ...i,
              checked: !i.checked,
            };
          } else {
            return i;
          }
        });
      });
    }
  };
  return (
    <div onClick={(e) => setDropdown("teachesTo")} className="input-group">
      <div id="select-speciality" className="speciality">
        Teaches to
      </div>
      {dropdown === "teachesTo" && (
        <div className="teachesTo-select-box">
          {teachesTo.map((i, index) => (
            <div className="custom-checkbox" key={index}>
              <label className="container-check">
                {i.name}
                <input
                  type="checkbox"
                  name={i.name}
                  onChange={(e) => handleTeachesTo(i.id, e)}
                  checked={i.checked}
                  value={i.id}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default TeachesTo;
