import React from "react";

function EmailNotification({ notifications, setNotifications }) {
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    if(checked){
      setNotifications({
        ...notifications,
        [name]: '1'
      })
    }
    else{
      setNotifications({
        ...notifications,
        [name]: '0'
      })
    }
  };
  return (
    <div className="col-md-12 mb-4">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={notifications.lesson_scheduling==='1'}
          name="lesson_scheduling"
          onChange={handleCheckbox}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Lesson scheduling
        </label>
      </div>
      <div className="form-check mt-sm-3">
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={notifications.general_reminders==='1'}
          name="general_reminders"
          onChange={handleCheckbox}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          General reminders
        </label>
      </div>
      <div className="form-check mt-sm-3">
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={notifications.updates_tips==='1'}
          name="updates_tips"
          onChange={handleCheckbox}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Updates, tips and offers
        </label>
      </div>
      <div className="form-check mt-sm-3">
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={notifications.daylearning_blog==='1'}
          name="daylearning_blog"
          onChange={handleCheckbox}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Daylearning Blog
        </label>
      </div>
      <div className="form-check mt-sm-3">
        <input
          className="form-check-input"
          defaultChecked={notifications.qa==='1'}
          type="checkbox"
          name="qa"
          onChange={handleCheckbox}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Q&A section
        </label>
      </div>
    </div>
  );
}

export default EmailNotification;
