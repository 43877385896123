import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const uploadingSlice = createSlice({
  name: "uploading",
  initialState,
  reducers: {
    uploading: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { uploading } = uploadingSlice.actions;
export default uploadingSlice.reducer;
