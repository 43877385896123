import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo1 from "../../assets/img/Logo-1.svg";
import { useForm } from "react-hook-form";
import {
  openForgotModal,
  openSignupModal,
  closeModal,
  openBookingCalendarModal,
} from "../../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import toasters
import { errorNotify } from "../toasters";
// import login service
import { login } from "../../services/common";

function BookingLoginModal() {
  // for navigate
  const navigate = useNavigate();
  // modal states
  const state = useSelector(
    (state) => state.modalState.bookingLoginModal.value
  );
  const dispatch = useDispatch();
  // for login form validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  // handle login
  const onSubmit = async (form) => {
    // login service
    login(form)
      .then(async (response) => {
        //dispatch(closeModal());
        const data = await response.data;
        if (data.access_token) {
          localStorage.setItem("token", JSON.stringify(data.access_token));
          localStorage.setItem("username", JSON.stringify(data.user.username));
          localStorage.setItem("name", JSON.stringify(data.user.name));
          localStorage.setItem("user_email", JSON.stringify(data.user.email));
          localStorage.setItem("user_role", JSON.stringify(data.user.role_id));
          reset();
          dispatch(openBookingCalendarModal());
        } else {
        }
      })
      .catch((error) => {
        let err;
        if (error.response.status === 400) {
          err = error.response.data.message;
        } else if (error.response.status === 422) {
          err = Object.values(error.response.data?.errors)[0][0];
        } else {
          err = "Something went Wrong";
        }
        errorNotify(err);
      });
  };
  return (
    <Modal id="loginForm" show={state} onHide={() => dispatch(closeModal())}>
      <Button
        className="btn-close"
        data-bs-dismiss="modal"
        onClick={() => dispatch(closeModal(null))}
        aria-label="Close"
      ></Button>
      <Modal.Header className="m-2">
        <Link to="#" className="logo">
          <img src={logo1} alt="" className="img-fluid" />
        </Link>
      </Modal.Header>
      <Modal.Body>
        <div className="m-3 mt-0">
          <p>Welcome back Booking login modal</p>
          <h2>Login to your account</h2>
          {/* login form */}
          <form
            action=""
            className="signin-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-group mb-3">
              <label className="" htmlFor="email">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter email address"
                autoComplete="off"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <p className="text-danger">Email is required.</p>
              )}
            </div>
            <div className="form-group mb-3">
              <label className="" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                autoComplete="off"
                {...register("password", { required: true })}
              />
              {errors.password && (
                <p className="text-danger">Password is required.</p>
              )}
            </div>
            <div className="form-group d-md-flex mb-4 mt-2">
              <div className="w-100 text-left">
                <label className="checkbox-wrap checkbox-primary mb-0">
                  Remember Me
                  <input type="radio" id="f-option" name="selector" />
                  <span className="check"></span>
                </label>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="w-100 text-right"
                onClick={() => dispatch(openForgotModal())}
              >
                <Link to="#" className="forgot-link">
                  Forgot password ?
                </Link>
              </div>
            </div>
            <div className="form-group mb-4">
              <button
                type="submit"
                className="form-control btn btn-primary rounded submit px-3"
                style={{
                  backgroundColor: "#1a2e9e",
                }}
              >
                Login
              </button>
            </div>
            <div>
              Don't have an account?{" "}
              <Link to="#" onClick={() => dispatch(openSignupModal())}>
                Signup
              </Link>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BookingLoginModal;
