import React from "react";

function About({ teaching_experience }) {
  return (
    <section id="about" className="tutor-about card-body-section">
      <h3>About the tutor</h3>
      <p>{teaching_experience}</p>
    </section>
  );
}

export default About;
