import axios from "axios";

// api endpoint
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

// headers
const headers = {
  Accept: "application/json",
};
// login
export const login = async (data) => {
  const url = baseUrl + "/login";
  return await axios.post(url, data, { headers });
};
// get countries list
export const countries = async () => {
  const url = baseUrl + `/countries?per_page=258`;
  return await axios.get(url, { headers });
};

// get language list
export const languages = async () => {
  const url = baseUrl + `/languages?per_page=131`;
  return await axios.get(url, { headers });
};

// get speciality list
export const specialties = async () => {
  const url = baseUrl + "/specialties";
  return await axios.get(url, { headers });
};


export const contactform = async (data) => {
  const url = baseUrl + "/contact-us";
  return await axios.post(url, data, { headers });
};