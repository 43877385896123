import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/img/Logo.svg";
import playstore from "../../assets/img/playstore.svg";
import apple from "../../assets/img/apple.svg";
import { Link } from "react-router-dom";

import "./footer.css";

function Footer() {
  return (
    <>
      <footer id="footer" className="footer-top ">
        <Container>
          <Row>
            <Col md="3" className="footer-contact">
              <h3>
                <Link to="" className="logo me-auto">
                  <img src={logo} alt="" className="img-fluid" />
                </Link>
              </h3>
              <p>
                Copyright © {(new Date().getFullYear())} DayLearning.
                <br />
                All rights reserved
                <br />
              </p>
              <div className="social-links mt-3">
                <Link to="#" className="instagram">
                  <i className="bi bi-instagram"></i>
                </Link>
                <Link to="#" className="google-plus">
                  <i className="bi bi-skype"></i>
                </Link>
                <Link to="#" className="twitter">
                  <i className="bi bi-twitter"></i>
                </Link>
                <Link to="#" className="youtube">
                  <i className="bi bi-youtube"></i>
                </Link>
              </div>
            </Col>
            <Col md="3" className="footer-links">
              <h4>Company</h4>
              <ul>
                <li>
                  {" "}
                  <Link to="/about-us">About us</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#">Forum</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/contact-us">Contact us</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#">Pricing</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#">Testimonials</Link>
                </li>
              </ul>
            </Col>
            <Col md="3" className="footer-links">
              <h4>Support</h4>
              <ul>
                <li>
                  {" "}
                  <Link to="#">Help center</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/terms-and-conditions">Terms of service</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#">Legal</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  {" "}
                  <Link to="#">Status</Link>
                </li>
              </ul>
            </Col>

            <Col md="3" className="footer-links">
              <h4>Install App</h4>
              <div className="app-icons mt-3">
                <Link to="#" className="logo me-auto">
                  <img src={playstore} alt="" className="img-fluid" />
                </Link>
                <Link to="" className="logo me-auto">
                  <img src={apple} alt="" className="img-fluid" />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
