import { Navigate, Outlet } from "react-router-dom";
const useAuth = () => {
  let user = { loggedIn: false };
  if (localStorage.getItem("token")) {
    user = { loggedIn: true };
  }
  return user && user.loggedIn;
};
const Protected = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/" />;
};

export default Protected;
