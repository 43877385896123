import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "",
};

export const regiterEmailSlice = createSlice({
  name: "registerEmail",
  initialState,
  reducers: {
    registerEmail: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { registerEmail } = regiterEmailSlice.actions;
export default regiterEmailSlice.reducer;
