import React from "react";

function Schedule() {
  return (
    <section id="schedule" className="tutor-calender card-body-section">
      <h3>Schedule</h3>
      <div className="">
        <div className="row justify-content-center mx-0">
          <div className="col-lg-12">
            <div className="card border-0">
              <form autoComplete="off">
                <div className="card-header bg-dark">
                  <div className="mx-0 mb-0 justify-content-center px-1">
                    <input
                      type="date"
                      id="dp1"
                      className="datepicker"
                      placeholder="Pick Date"
                      name="date"
                      readOnly
                    />
                    <i className="bi bi-calendar3"></i>
                  </div>
                </div>
                <div className="card-body p-1 p-sm-4">
                  <div className="row text-center mx-0">
                    <div className="row p-1">
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                    </div>
                    <div className="row p-1">
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                    </div>
                    <div className="row p-1">
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                      <div className="cell col">9:00AM</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Schedule;
