import axios from "axios";

// api endpoint
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

// headers
const headers = {
  Accept: "application/json",
  Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
};

// upload profile pic
export const uploadImage = async(file,setPercentage)=>{
    const url = baseUrl + '/image-upload'
    const config = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setPercentage(percent)
        },
        headers:headers
      };
      return await axios.post(url, file,config);
}