import React from "react";

function SearchBar({setSearch}) {
  return (
    <div className="input-group">
      <i className="bi bi-search"></i>
      <input
        className="input--style-1 search-box"
        type="text"
        name="Search"
        placeholder="Search by name or keyword"
        autoComplete="off"
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
}

export default SearchBar;
