import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { timeZones } from "../../../services/tutors";
import TimePicker from "../../../ui/timepicker/TimePicker";
import moment from "moment";
import { availability } from "../../../services/tutors";
import { successNotify, errorNotify } from "../../../ui/toasters";
import Card from "react-bootstrap/Card";
import CustomSpinner from "../../../ui/loaders/CustomSpinner";
import { getTutor } from "../../../services/tutors";
function Availability(props) {
  const { user,setSteps, steps } = props;
  // loader state
  const [loader, setLoader] = useState(true);
  // user default time zone
  const [userDefaultTimeZone, setUserDefaultTime] = useState(user?.timezone);
  // start and end date
  const [startEndDates, setStartEndDates] = useState({
    start_date: user?.avaiability[0]?.start_date,
    end_date: user?.avaiability[0]?.end_date,
  });
  // for handling date errors
  const [dateError, setDateError] = useState({
    error: false,
    message: null,
  });
  // for scroll to top on render the home component
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [timeZone, setTimeZone] = useState([]);
  useEffect(() => {
    timeZones().then((res) => {
      setTimeZone(res.data.data);
    });
  }, []);
  // state for timezome id
  const [timezoneID, setTimezoneID] = useState(user?.timezone_id);
  // state for time slots
  const [availableTime, setAvailableTime] = useState({
    mon:
      user?.avaiability?.filter((item) => item.day === "1").length > 0
        ? {
            checked: true,
            timeSlots: user.avaiability
              .filter((item) => item.day === "1")
              .map((i, index) => {
                return {
                  day: 1,
                  start: i.start_time_utc.slice(0, 5),
                  end: i.end_time_utc.slice(0, 5),
                  start_time: i.start_time_utc.slice(0, 5),
                  end_time: i.end_time_utc.slice(0, 5),
                  id: index,
                  showPlusBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "1").length -
                      1
                      ? true
                      : false,
                  showDeleteBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "1").length -
                      1
                      ? false
                      : true,
                  disabled:
                    index ===
                    user.avaiability.filter((item) => item.day === "1").length -
                      1
                      ? false
                      : true,
                };
              }),
          }
        : {
            checked: false,
            timeSlots: [
              {
                day: 1,
                start: "00:00",
                end: "00:30",
                start_time: "",
                end_time: "",
                id: new Date().getMilliseconds(),
                showPlusBtn: true,
                showDeleteBtn: false,
                disabled: false,
              },
            ],
          },
    tue:
      user?.avaiability.filter((item) => item.day === "2").length > 0
        ? {
            checked: true,
            timeSlots: user.avaiability
              .filter((item) => item.day === "2")
              .map((i, index) => {
                return {
                  day: 2,
                  start: i.start_time_utc.slice(0, 5),
                  end: i.end_time_utc.slice(0, 5),
                  start_time: i.start_time_utc.slice(0, 5),
                  end_time: i.end_time_utc.slice(0, 5),
                  id: index,
                  showPlusBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "2").length -
                      1
                      ? true
                      : false,
                  showDeleteBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "2").length -
                      1
                      ? false
                      : true,
                  disabled:
                    index ===
                    user.avaiability.filter((item) => item.day === "2").length -
                      1
                      ? false
                      : true,
                };
              }),
          }
        : {
            checked: false,
            timeSlots: [
              {
                day: 2,
                start: "00:00",
                end: "00:30",
                start_time: "",
                end_time: "",
                id: new Date().getMilliseconds(),
                showPlusBtn: true,
                showDeleteBtn: false,
                disabled: false,
              },
            ],
          },
    wed:
      user?.avaiability.filter((item) => item.day === "3").length > 0
        ? {
            checked: true,
            timeSlots: user.avaiability
              .filter((item) => item.day === "3")
              .map((i, index) => {
                return {
                  day: 3,
                  start: i.start_time_utc.slice(0, 5),
                  end: i.end_time_utc.slice(0, 5),
                  start_time: i.start_time_utc.slice(0, 5),
                  end_time: i.end_time_utc.slice(0, 5),
                  id: index,
                  showPlusBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "3").length -
                      1
                      ? true
                      : false,
                  showDeleteBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "3").length -
                      1
                      ? false
                      : true,
                  disabled:
                    index ===
                    user.avaiability.filter((item) => item.day === "3").length -
                      1
                      ? false
                      : true,
                };
              }),
          }
        : {
            checked: false,
            timeSlots: [
              {
                day: 3,
                start: "00:00",
                end: "00:30",
                start_time: "",
                end_time: "",
                id: new Date().getMilliseconds(),
                showPlusBtn: true,
                showDeleteBtn: false,
                disabled: false,
              },
            ],
          },
    thu:
      user?.avaiability.filter((item) => item.day === "4").length > 0
        ? {
            checked: true,
            timeSlots: user.avaiability
              .filter((item) => item.day === "4")
              .map((i, index) => {
                return {
                  day: 4,
                  start: i.start_time_utc.slice(0, 5),
                  end: i.end_time_utc.slice(0, 5),
                  start_time: i.start_time_utc.slice(0, 5),
                  end_time: i.end_time_utc.slice(0, 5),
                  id: index,
                  showPlusBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "4").length -
                      1
                      ? true
                      : false,
                  showDeleteBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "4").length -
                      1
                      ? false
                      : true,
                  disabled:
                    index ===
                    user.avaiability.filter((item) => item.day === "4").length -
                      1
                      ? false
                      : true,
                };
              }),
          }
        : {
            checked: false,
            timeSlots: [
              {
                day: 4,
                start: "00:00",
                end: "00:30",
                start_time: "",
                end_time: "",
                id: new Date().getMilliseconds(),
                showPlusBtn: true,
                showDeleteBtn: false,
                disabled: false,
              },
            ],
          },
    fri:
      user?.avaiability.filter((item) => item.day === "5").length > 0
        ? {
            checked: true,
            timeSlots: user.avaiability
              .filter((item) => item.day === "5")
              .map((i, index) => {
                return {
                  day: 5,
                  start: i.start_time_utc.slice(0, 5),
                  end: i.end_time_utc.slice(0, 5),
                  start_time: i.start_time_utc.slice(0, 5),
                  end_time: i.end_time_utc.slice(0, 5),
                  id: index,
                  showPlusBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "5").length -
                      1
                      ? true
                      : false,
                  showDeleteBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "5").length -
                      1
                      ? false
                      : true,
                  disabled:
                    index ===
                    user.avaiability.filter((item) => item.day === "5").length -
                      1
                      ? false
                      : true,
                };
              }),
          }
        : {
            checked: false,
            timeSlots: [
              {
                day: 5,
                start: "00:00",
                end: "00:30",
                start_time: "",
                end_time: "",
                id: new Date().getMilliseconds(),
                showPlusBtn: true,
                showDeleteBtn: false,
                disabled: false,
              },
            ],
          },
    sat:
      user?.avaiability.filter((item) => item.day === "6").length > 0
        ? {
            checked: true,
            timeSlots: user.avaiability
              .filter((item) => item.day === "6")
              .map((i, index) => {
                return {
                  day: 6,
                  start: i.start_time_utc.slice(0, 5),
                  end: i.end_time_utc.slice(0, 5),
                  start_time: i.start_time_utc.slice(0, 5),
                  end_time: i.end_time_utc.slice(0, 5),
                  id: index,
                  showPlusBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "6").length -
                      1
                      ? true
                      : false,
                  showDeleteBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "6").length -
                      1
                      ? false
                      : true,
                  disabled:
                    index ===
                    user.avaiability.filter((item) => item.day === "6").length -
                      1
                      ? false
                      : true,
                };
              }),
          }
        : {
            checked: false,
            timeSlots: [
              {
                day: 6,
                start: "00:00",
                end: "00:30",
                start_time: "",
                end_time: "",
                id: new Date().getMilliseconds(),
                showPlusBtn: true,
                showDeleteBtn: false,
                disabled: false,
              },
            ],
          },
    sun:
      user?.avaiability.filter((item) => item.day === "7").length > 0
        ? {
            checked: true,
            timeSlots: user.avaiability
              .filter((item) => item.day === "7")
              .map((i, index) => {
                return {
                  day: 7,
                  start: i.start_time_utc.slice(0, 5),
                  end: i.end_time_utc.slice(0, 5),
                  start_time: i.start_time_utc.slice(0, 5),
                  end_time: i.end_time_utc.slice(0, 5),
                  id: index,
                  showPlusBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "7").length -
                      1
                      ? true
                      : false,
                  showDeleteBtn:
                    index ===
                    user.avaiability.filter((item) => item.day === "7").length -
                      1
                      ? false
                      : true,
                  disabled:
                    index ===
                    user.avaiability.filter((item) => item.day === "7").length -
                      1
                      ? false
                      : true,
                };
              }),
          }
        : {
            checked: false,
            timeSlots: [
              {
                day: 7,
                start: "00:00",
                end: "00:30",
                start_time: "",
                end_time: "",
                id: new Date().getMilliseconds(),
                showPlusBtn: true,
                showDeleteBtn: false,
                disabled: false,
              },
            ],
          },
  });
  // add new time slots
  const addTimeSlot = (start, end, name, id) => {
    if (name === "mon") {
      setAvailableTime({
        ...availableTime,
        mon: {
          ...availableTime.mon,
          timeSlots: availableTime.mon.timeSlots.concat(
            availableTime.mon.timeSlots.splice(-1).map((i) => {
              return {
                ...i,
                showPlusBtn: false,
                showDeleteBtn: true,
                disabled: true,
                start_time: "",
                end_time: "",
                day: 1,
              };
            }),
            {
              day: 1,
              start:
                end === "00:00"
                  ? moment(end, "HH:mm").add(30, "minutes").format("HH:mm")
                  : end,
              end: moment(end, "HH:mss").add(30, "minutes").format("HH:mm"),
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
              start_time: "",
              end_time: "",
            }
          ),
        },
      });
    }
    if (name === "tue") {
      setAvailableTime({
        ...availableTime,
        tue: {
          ...availableTime.tue,
          timeSlots: availableTime.tue.timeSlots.concat(
            availableTime.tue.timeSlots.splice(-1).map((i) => {
              return {
                ...i,
                showPlusBtn: false,
                showDeleteBtn: true,
                disabled: true,
                start_time: "",
                end_time: "",
                day: 1,
              };
            }),
            {
              day: 2,
              start:
                end === "00:00"
                  ? moment(end, "HH:mm").add(30, "minutes").format("HH:mm")
                  : end,
              end: moment(end, "HH:mss").add(30, "minutes").format("HH:mm"),
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
              start_time: "",
              end_time: "",
            }
          ),
        },
      });
    }
    if (name === "wed") {
      setAvailableTime({
        ...availableTime,
        wed: {
          ...availableTime.wed,
          timeSlots: availableTime.wed.timeSlots.concat(
            availableTime.wed.timeSlots.splice(-1).map((i) => {
              return {
                ...i,
                showPlusBtn: false,
                showDeleteBtn: true,
                disabled: true,
                start_time: "",
                end_time: "",
              };
            }),
            {
              day: 3,
              start:
                end === "00:00"
                  ? moment(end, "HH:mm").add(30, "minutes").format("HH:mm")
                  : end,
              end: moment(end, "HH:mss").add(30, "minutes").format("HH:mm"),
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
              start_time: "",
              end_time: "",
            }
          ),
        },
      });
    }
    if (name === "thu") {
      setAvailableTime({
        ...availableTime,
        thu: {
          ...availableTime.thu,
          timeSlots: availableTime.thu.timeSlots.concat(
            availableTime.thu.timeSlots.splice(-1).map((i) => {
              return {
                ...i,
                showPlusBtn: false,
                showDeleteBtn: true,
                disabled: true,
                start_time: "",
                end_time: "",
              };
            }),
            {
              day: 4,
              start:
                end === "00:00"
                  ? moment(end, "HH:mm").add(30, "minutes").format("HH:mm")
                  : end,
              end: moment(end, "HH:mss").add(30, "minutes").format("HH:mm"),
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
              start_time: "",
              end_time: "",
            }
          ),
        },
      });
    }
    if (name === "fri") {
      setAvailableTime({
        ...availableTime,
        fri: {
          ...availableTime.fri,
          timeSlots: availableTime.fri.timeSlots.concat(
            availableTime.fri.timeSlots.splice(-1).map((i) => {
              return {
                ...i,
                showPlusBtn: false,
                showDeleteBtn: true,
                disabled: true,
                start_time: "",
                end_time: "",
              };
            }),
            {
              day: 5,
              start:
                end === "00:00"
                  ? moment(end, "HH:mm").add(30, "minutes").format("HH:mm")
                  : end,
              end: moment(end, "HH:mss").add(30, "minutes").format("HH:mm"),
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
              start_time: "",
              end_time: "",
            }
          ),
        },
      });
    }
    if (name === "sat") {
      setAvailableTime({
        ...availableTime,
        sat: {
          ...availableTime.sat,
          timeSlots: availableTime.sat.timeSlots.concat(
            availableTime.sat.timeSlots.splice(-1).map((i) => {
              return {
                ...i,
                showPlusBtn: false,
                showDeleteBtn: true,
                disabled: true,
                start_time: "",
                end_time: "",
              };
            }),
            {
              day: 6,
              start:
                end === "00:00"
                  ? moment(end, "HH:mm").add(30, "minutes").format("HH:mm")
                  : end,
              end: moment(end, "HH:mss").add(30, "minutes").format("HH:mm"),
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
              start_time: "",
              end_time: "",
            }
          ),
        },
      });
    }
    if (name === "sun") {
      setAvailableTime({
        ...availableTime,
        sun: {
          ...availableTime.sun,
          timeSlots: availableTime.sun.timeSlots.concat(
            availableTime.sun.timeSlots.splice(-1).map((i) => {
              return {
                ...i,
                showPlusBtn: false,
                showDeleteBtn: true,
                disabled: true,
                start_time: "",
                end_time: "",
              };
            }),
            {
              day: 7,
              start:
                end === "00:00"
                  ? moment(end, "HH:mm").add(30, "minutes").format("HH:mm")
                  : end,
              end: moment(end, "HH:mss").add(30, "minutes").format("HH:mm"),
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
              start_time: "",
              end_time: "",
            }
          ),
        },
      });
    }
  };

  // to check if array consist only one item
  const [check, setCheck] = useState(false);
  // delete time slots
  const deleteTimeSlot = (id, name) => {
    if (name === "mon") {
      setAvailableTime({
        ...availableTime,
        mon: {
          ...availableTime.mon,
          timeSlots: availableTime.mon.timeSlots.filter(
            (item) => item.id !== id
          ),
        },
      });
      setCheck((prev) => !prev);
    } else if (name === "tue") {
      setAvailableTime({
        ...availableTime,
        tue: {
          ...availableTime.tue,
          timeSlots: availableTime.tue.timeSlots.filter(
            (item) => item.id !== id
          ),
        },
      });
      setCheck((prev) => !prev);
    } else if (name === "wed") {
      setAvailableTime({
        ...availableTime,
        wed: {
          ...availableTime.wed,
          timeSlots: availableTime.wed.timeSlots.filter(
            (item) => item.id !== id
          ),
        },
      });
    } else if (name === "thu") {
      setAvailableTime({
        ...availableTime,
        thu: {
          ...availableTime.thu,
          timeSlots: availableTime.thu.timeSlots.filter(
            (item) => item.id !== id
          ),
        },
      });
    } else if (name === "fri") {
      setAvailableTime({
        ...availableTime,
        fri: {
          ...availableTime.fri,
          timeSlots: availableTime.fri.timeSlots.filter(
            (item) => item.id !== id
          ),
        },
      });
    } else if (name === "sat") {
      setAvailableTime({
        ...availableTime,
        sat: {
          ...availableTime.sat,
          timeSlots: availableTime.sat.timeSlots.filter(
            (item) => item.id !== id
          ),
        },
      });
    } else if (name === "sun") {
      setAvailableTime({
        ...availableTime,
        sun: {
          ...availableTime.sun,
          timeSlots: availableTime.sun.timeSlots.filter(
            (item) => item.id !== id
          ),
        },
      });
    }
  };

  // handle checkbox click
  const handleCheck = (e) => {
    const { name, checked } = e.target;
    if (name === "mon") {
      setAvailableTime({
        ...availableTime,
        mon: {
          ...availableTime.mon,
          checked: checked,
        },
      });
    }
    if (name === "tue") {
      setAvailableTime({
        ...availableTime,
        tue: {
          ...availableTime.tue,
          checked: checked,
        },
      });
    }
    if (name === "wed") {
      setAvailableTime({
        ...availableTime,
        wed: {
          ...availableTime.wed,
          checked: checked,
        },
      });
    }
    if (name === "thu") {
      setAvailableTime({
        ...availableTime,
        thu: {
          ...availableTime.thu,
          checked: checked,
        },
      });
    }
    if (name === "fri") {
      setAvailableTime({
        ...availableTime,
        fri: {
          ...availableTime.fri,
          checked: checked,
        },
      });
    }
    if (name === "sat") {
      setAvailableTime({
        ...availableTime,
        sat: {
          ...availableTime.sat,
          checked: checked,
        },
      });
    }
    if (name === "sun") {
      setAvailableTime({
        ...availableTime,
        sun: {
          ...availableTime.sun,
          checked: checked,
        },
      });
    }
  };

  // handle submit
  const onSubmit = async () => {
    // check validation for dates
    const validDate = handleDateValidation();
    const { start_date, end_date } = startEndDates;
    if (validDate) {
      let data = [];
      Object.entries(availableTime).map((i) => {
        if (i[1].checked === true) {
          return i[1].timeSlots.map((i) => {
            return data.push({
              day: i.day,
              start_time: i.start,
              end_time: i.end,
              start_date: start_date,
              end_date: end_date,
            });
          });
        } else {
          return false;
        }
      });
      let object = {
        timezone_id: Number(timezoneID),
        availabile_time: data,
      };
      await availability(object)
        .then((res) => {
          successNotify(res.data.message);
          setSteps({
            ...steps,
            step7: { value: true, display: "none" },
            verifcation: { value: true, display: "show" },
          });
        })
        .catch((err) => errorNotify("Something went wrong"));
    }
  };

  useEffect(() => {
    if (availableTime.mon.timeSlots.length === 1) {
      setAvailableTime({
        ...availableTime,
        mon: {
          ...availableTime.mon,
          timeSlots: [
            {
              day: 1,
              start: "00:00",
              end: "00:30",
              start_time: "",
              end_time: "",
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
            },
          ],
        },
      });
    } else if (availableTime.tue.timeSlots.length === 1) {
      setAvailableTime({
        ...availableTime,
        tue: {
          ...availableTime.tue,
          timeSlots: [
            {
              day: 2,
              start: "00:00",
              end: "00:30",
              start_time: "",
              end_time: "",
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
            },
          ],
        },
      });
    } else if (availableTime.wed.timeSlots.length === 1) {
      setAvailableTime({
        ...availableTime,
        wed: {
          ...availableTime.wed,
          timeSlots: [
            {
              day: 3,
              start: "00:00",
              end: "00:30",
              start_time: "",
              end_time: "",
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
            },
          ],
        },
      });
    } else if (availableTime.thu.timeSlots.length === 1) {
      setAvailableTime({
        ...availableTime,
        thu: {
          ...availableTime.thu,
          timeSlots: [
            {
              day: 4,
              start: "00:00",
              end: "00:30",
              start_time: "",
              end_time: "",
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
            },
          ],
        },
      });
    } else if (availableTime.fri.timeSlots.length === 1) {
      setAvailableTime({
        ...availableTime,
        fri: {
          ...availableTime.fri,
          timeSlots: [
            {
              day: 5,
              start: "00:00",
              end: "00:30",
              start_time: "",
              end_time: "",
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
            },
          ],
        },
      });
    } else if (availableTime.sat.timeSlots.length === 1) {
      setAvailableTime({
        ...availableTime,
        sat: {
          ...availableTime.sat,
          timeSlots: [
            {
              day: 6,
              start: "00:00",
              end: "00:30",
              start_time: "",
              end_time: "",
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
            },
          ],
        },
      });
    } else if (availableTime.sun.timeSlots.length === 1) {
      setAvailableTime({
        ...availableTime,
        sun: {
          ...availableTime.sun,
          timeSlots: [
            {
              day: 7,
              start: "00:00",
              end: "00:30",
              start_time: "",
              end_time: "",
              id: new Date().getMilliseconds(),
              showPlusBtn: true,
              showDeleteBtn: false,
              disabled: false,
            },
          ],
        },
      });
    }
  }, [check]);

  // handle date changes
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "start_date":
        setStartEndDates({ ...startEndDates, start_date: value });
        break;
      case "end_date":
        setStartEndDates({ ...startEndDates, end_date: value });
        break;
      default:
        return;
    }
  };
  // handle date validations
  const handleDateValidation = () => {
    const { start_date, end_date } = startEndDates;
    // start date null
    if (start_date === "") {
      setDateError({
        error: true,
        message: "Start Date is required",
      });
      return false;
    }
    // end date null
    if (end_date === "") {
      setDateError({
        error: true,
        message: "End Date is required",
      });
      return false;
    }
    // start date bigger than end date
    if (start_date >= end_date) {
      setDateError({
        error: true,
        message: "Start Date cannot be bigger or equal to End Date",
      });
      return false;
    }
    if (end_date <= startEndDates) {
      setDateError({
        error: true,
        message: "End Date cannot be smaller or equal to Start Date",
      });
    }
    setDateError({
      error: false,
      message: null,
    });
    return true;
  };
  return (
    <>
      <Card>
        <Card.Header>Availability</Card.Header>
        <Card.Body>
          {loader ? (
            <CustomSpinner />
          ) : (
            <>
              <div className="panel panel-primary setup-content" id="step-7">
                <div className="panel-body">
                  <h3>Availabilty</h3>
                  <h4>Set Your Timezone</h4>
                  <p>Is this your current timezone</p>
                  <p>Please Select Your Time Zone</p>
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      {" "}
                      <i className="bi bi-globe"></i>
                    </span>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setTimezoneID(e.target.value);
                      }}
                      defaultValue={timezoneID}
                    >
                      {userDefaultTimeZone && (
                        <option
                          value={userDefaultTimeZone?.id}
                          key={userDefaultTimeZone?.gmt_offset}
                          selected
                        >
                          {userDefaultTimeZone?.gmt_offset} -{" "}
                          {userDefaultTimeZone.zone}
                        </option>
                      )}
                      {timeZone.map((i) => {
                        return (
                          <>
                            <option value={i?.id} key={i?.gmt_offset}>
                              {i?.gmt_offset} - {i.zone}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <h4>Select days for Timeslots</h4>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="start date">Start Date</label>
                    <input
                      name="start_date"
                      type="date"
                      className="form-control"
                      onChange={handleDateChange}
                      defaultValue={startEndDates.start_date}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="start date">End Date</label>
                    <input
                      name="end_date"
                      type="date"
                      className="form-control"
                      onChange={handleDateChange}
                      defaultValue={startEndDates.end_date}
                    />
                  </div>
                </div>
              </div>
              {/* available days checkboxes */}
              <div>
                <div className="days_container mt-3">
                  {/* monday */}
                  <div className="col">
                    <div className="custom-checkbox">
                      <label className="container-check">
                        <input
                          type="checkbox"
                          name="mon"
                          onClick={handleCheck}
                          checked={availableTime.mon.checked}
                        />{" "}
                        MONDAY
                        <span className="checkmark"></span>
                      </label>
                      {availableTime.mon.checked && (
                        <div className="timeslots m-2">
                          {availableTime.mon.timeSlots.map((i, index) => {
                            return (
                              <div className="row mb-1">
                                <>
                                  <div className="col-lg-2">
                                    <lable className="me-2">Start</lable>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      step={30}
                                      beginLimit={i?.start}
                                      endLimit={"23:30"}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          mon: {
                                            ...availableTime.mon,
                                            timeSlots:
                                              availableTime.mon.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 1,
                                                      start: e.target.value,
                                                      end: moment(
                                                        e.target.value,
                                                        "HH:mm"
                                                      )
                                                        .add(30, "minutes")
                                                        .format("HH:mm"),
                                                      id: i?.id,
                                                      showPlusBtn: true,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    <lable>to</lable>
                                  </div>
                                  <div className="col-lg-2">
                                    <label className="me-2">End</label>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      beginLimit={moment(i?.start, "HH:mm")
                                        .add(30, "minutes")
                                        .format("HH:mm")}
                                      endLimit={"24:00"}
                                      step={30}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          mon: {
                                            ...availableTime.mon,
                                            timeSlots:
                                              availableTime.mon.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 1,
                                                      start: item.start,
                                                      end: e.target.value,
                                                      id: i?.id,
                                                      showPlusBtn:
                                                        e.target.value ===
                                                        "00:00"
                                                          ? false
                                                          : true,
                                                      showDeleteBtn: false,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    {i?.showPlusBtn && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={(e) =>
                                          addTimeSlot(
                                            i?.start,
                                            i?.end,
                                            "mon",
                                            i?.id
                                          )
                                        }
                                      />
                                    )}
                                    {i?.showDeleteBtn && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() =>
                                          deleteTimeSlot(i?.id, "mon")
                                        }
                                      />
                                    )}
                                  </div>
                                </>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* tuesday */}
                  <div className="col">
                    <div className="custom-checkbox">
                      <label className="container-check">
                        <input
                          type="checkbox"
                          name="tue"
                          onClick={handleCheck}
                          checked={availableTime.tue.checked}
                        />{" "}
                        TUESDAY
                        <span className="checkmark"></span>
                      </label>
                      {availableTime.tue.checked && (
                        <div className="timeslots m-2">
                          {availableTime.tue?.timeSlots?.map((i, index) => {
                            return (
                              <div className="row mb-1">
                                <>
                                  <div className="col-lg-2">
                                    <lable className="me-2">Start</lable>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      step={30}
                                      beginLimit={i?.start}
                                      endLimit={"23:30"}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          tue: {
                                            ...availableTime.tue,
                                            timeSlots:
                                              availableTime.tue.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 2,
                                                      start_time:
                                                        e.target.value,
                                                      end_time: moment(
                                                        e.target.value,
                                                        "HH:mm"
                                                      )
                                                        .add(30, "minutes")
                                                        .format("HH:mm"),
                                                      start: e.target.value,
                                                      end: moment(
                                                        e.target.value,
                                                        "HH:mm"
                                                      )
                                                        .add(30, "minutes")
                                                        .format("HH:mm"),
                                                      id: i?.id,
                                                      showPlusBtn: true,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    <lable>to</lable>
                                  </div>
                                  <div className="col-lg-2">
                                    <label className="me-2">End</label>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      beginLimit={i?.end}
                                      endLimit={"24:00"}
                                      step={30}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          tue: {
                                            ...availableTime.tue,
                                            timeSlots:
                                              availableTime.tue.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 2,
                                                      start_time: item?.start,
                                                      end_time: e.target.value,
                                                      start: item?.start,
                                                      end: e.target.value,
                                                      id: i?.id,
                                                      showPlusBtn:
                                                        e.target.value ===
                                                        "00:00"
                                                          ? false
                                                          : true,
                                                      showDeleteBtn: false,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    {i?.showPlusBtn && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={(e) =>
                                          addTimeSlot(
                                            i?.start,
                                            i?.end,
                                            "tue",
                                            i?.id
                                          )
                                        }
                                      />
                                    )}
                                    {i?.showDeleteBtn && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() =>
                                          deleteTimeSlot(i?.id, "tue")
                                        }
                                      />
                                    )}
                                  </div>
                                </>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* wednesday */}
                  <div className="col">
                    <div className="custom-checkbox">
                      <label className="container-check">
                        <input
                          type="checkbox"
                          name="wed"
                          onClick={handleCheck}
                          checked={availableTime.wed.checked}
                        />{" "}
                        WEDNESDAY
                        <span className="checkmark"></span>
                      </label>
                      {availableTime.wed.checked && (
                        <div className="timeslots m-2">
                          {availableTime.wed.timeSlots.map((i, index) => {
                            return (
                              <div className="row mb-1">
                                <>
                                  <div className="col-lg-2">
                                    <lable className="me-2">Start</lable>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      step={30}
                                      beginLimit={i?.start}
                                      endLimit={"23:30"}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          wed: {
                                            ...availableTime.wed,
                                            timeSlots:
                                              availableTime.wed.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 3,
                                                      start: e.target.value,
                                                      end: moment(
                                                        e.target.value,
                                                        "HH:mm"
                                                      )
                                                        .add(30, "minutes")
                                                        .format("HH:mm"),
                                                      id: i?.id,
                                                      showPlusBtn: true,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    <lable>to</lable>
                                  </div>
                                  <div className="col-lg-2">
                                    <label className="me-2">End</label>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      beginLimit={moment(i?.start, "HH:mm")
                                        .add(30, "minutes")
                                        .format("HH:mm")}
                                      endLimit={"24:00"}
                                      step={30}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          wed: {
                                            ...availableTime.wed,
                                            timeSlots:
                                              availableTime.wed.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 3,
                                                      start: item.start,
                                                      end: e.target.value,
                                                      id: i?.id,
                                                      showPlusBtn:
                                                        e.target.value ===
                                                        "00:00"
                                                          ? false
                                                          : true,
                                                      showDeleteBtn: false,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    {i?.showPlusBtn && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={(e) =>
                                          addTimeSlot(
                                            i?.start,
                                            i?.end,
                                            "wed",
                                            i?.id
                                          )
                                        }
                                      />
                                    )}
                                    {i?.showDeleteBtn && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() =>
                                          deleteTimeSlot(i?.id, "wed")
                                        }
                                      />
                                    )}
                                  </div>
                                </>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* thursday */}
                  <div className="col">
                    <div className="custom-checkbox">
                      <label className="container-check">
                        <input
                          type="checkbox"
                          name="thu"
                          onClick={handleCheck}
                          checked={availableTime.thu.checked}
                        />{" "}
                        THURSDAY
                        <span className="checkmark"></span>
                      </label>
                      {availableTime.thu.checked && (
                        <div className="timeslots m-2">
                          {availableTime.thu.timeSlots.map((i, index) => {
                            return (
                              <div className="row mb-1">
                                <>
                                  <div className="col-lg-2">
                                    <lable className="me-2">Start</lable>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      step={30}
                                      beginLimit={i?.start}
                                      endLimit={"23:30"}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          thu: {
                                            ...availableTime.thu,
                                            timeSlots:
                                              availableTime.thu.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 4,
                                                      start: e.target.value,
                                                      end: moment(
                                                        e.target.value,
                                                        "HH:mm"
                                                      )
                                                        .add(30, "minutes")
                                                        .format("HH:mm"),
                                                      id: i?.id,
                                                      showPlusBtn: true,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    <lable>to</lable>
                                  </div>
                                  <div className="col-lg-2">
                                    <label className="me-2">End</label>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      beginLimit={moment(i?.start, "HH:mm")
                                        .add(30, "minutes")
                                        .format("HH:mm")}
                                      endLimit={"24:00"}
                                      step={30}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          thu: {
                                            ...availableTime.thu,
                                            timeSlots:
                                              availableTime.thu.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 4,
                                                      start: item.start,
                                                      end: e.target.value,
                                                      id: i?.id,
                                                      showPlusBtn:
                                                        e.target.value ===
                                                        "00:00"
                                                          ? false
                                                          : true,
                                                      showDeleteBtn: false,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    {i?.showPlusBtn && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={(e) =>
                                          addTimeSlot(
                                            i?.start,
                                            i?.end,
                                            "thu",
                                            i?.id
                                          )
                                        }
                                      />
                                    )}
                                    {i?.showDeleteBtn && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() =>
                                          deleteTimeSlot(i?.id, "thu")
                                        }
                                      />
                                    )}
                                  </div>
                                </>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* friday */}
                  <div className="col">
                    <div className="custom-checkbox">
                      <label className="container-check">
                        <input
                          type="checkbox"
                          name="fri"
                          onClick={handleCheck}
                          checked={availableTime.fri.checked}
                        />{" "}
                        FRIDAY
                        <span className="checkmark"></span>
                      </label>
                      {availableTime.fri.checked && (
                        <div className="timeslots m-2">
                          {availableTime.fri.timeSlots.map((i, index) => {
                            return (
                              <div className="row mb-1">
                                <>
                                  <div className="col-lg-2">
                                    <lable className="me-2">Start</lable>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      step={30}
                                      beginLimit={i?.start}
                                      endLimit={"23:30"}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          fri: {
                                            ...availableTime.fri,
                                            timeSlots:
                                              availableTime.fri.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 5,
                                                      start: e.target.value,
                                                      end: moment(
                                                        e.target.value,
                                                        "HH:mm"
                                                      )
                                                        .add(30, "minutes")
                                                        .format("HH:mm"),
                                                      id: i?.id,
                                                      showPlusBtn: true,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    <lable>to</lable>
                                  </div>
                                  <div className="col-lg-2">
                                    <label className="me-2">End</label>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      beginLimit={moment(i?.start, "HH:mm")
                                        .add(30, "minutes")
                                        .format("HH:mm")}
                                      endLimit={"24:00"}
                                      step={30}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          fri: {
                                            ...availableTime.fri,
                                            timeSlots:
                                              availableTime.fri.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 5,
                                                      start: item.start,
                                                      end: e.target.value,
                                                      id: i?.id,
                                                      showPlusBtn:
                                                        e.target.value ===
                                                        "00:00"
                                                          ? false
                                                          : true,
                                                      showDeleteBtn: false,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    {i?.showPlusBtn && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={(e) =>
                                          addTimeSlot(
                                            i?.start,
                                            i?.end,
                                            "fri",
                                            i?.id
                                          )
                                        }
                                      />
                                    )}
                                    {i?.showDeleteBtn && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() =>
                                          deleteTimeSlot(i?.id, "fri")
                                        }
                                      />
                                    )}
                                  </div>
                                </>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* sarturday */}
                  <div className="col">
                    <div className="custom-checkbox">
                      <label className="container-check">
                        <input
                          type="checkbox"
                          name="sat"
                          onClick={handleCheck}
                          checked={availableTime.sat.checked}
                        />{" "}
                        SARTURDAY
                        <span className="checkmark"></span>
                      </label>
                      {availableTime.sat.checked && (
                        <div className="timeslots m-2">
                          {availableTime.sat.timeSlots.map((i, index) => {
                            return (
                              <div className="row mb-1">
                                <>
                                  <div className="col-lg-2">
                                    <lable className="me-2">Start</lable>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      step={30}
                                      beginLimit={i?.start}
                                      endLimit={"23:30"}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          sat: {
                                            ...availableTime.sat,
                                            timeSlots:
                                              availableTime.sat.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 6,
                                                      start: e.target.value,
                                                      end: moment(
                                                        e.target.value,
                                                        "HH:mm"
                                                      )
                                                        .add(30, "minutes")
                                                        .format("HH:mm"),
                                                      id: i?.id,
                                                      showPlusBtn: true,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    <lable>to</lable>
                                  </div>
                                  <div className="col-lg-2">
                                    <label className="me-2">End</label>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      beginLimit={moment(i?.start, "HH:mm")
                                        .add(30, "minutes")
                                        .format("HH:mm")}
                                      endLimit={"24:00"}
                                      step={30}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          sat: {
                                            ...availableTime.sat,
                                            timeSlots:
                                              availableTime.sat.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 6,
                                                      start: item.start,
                                                      end: e.target.value,
                                                      id: i?.id,
                                                      showPlusBtn:
                                                        e.target.value ===
                                                        "00:00"
                                                          ? false
                                                          : true,
                                                      showDeleteBtn: false,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    {i?.showPlusBtn && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={(e) =>
                                          addTimeSlot(
                                            i?.start,
                                            i?.end,
                                            "sat",
                                            i?.id
                                          )
                                        }
                                      />
                                    )}
                                    {i?.showDeleteBtn && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() =>
                                          deleteTimeSlot(i?.id, "sat")
                                        }
                                      />
                                    )}
                                  </div>
                                </>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* sunday */}
                  <div className="col">
                    <div className="custom-checkbox">
                      <label className="container-check">
                        <input
                          type="checkbox"
                          name="sun"
                          onClick={handleCheck}
                          checked={availableTime.sun.checked}
                        />{" "}
                        SUNDAY
                        <span className="checkmark"></span>
                      </label>
                      {availableTime.sun.checked && (
                        <div className="timeslots m-2">
                          {availableTime.sun.timeSlots.map((i, index) => {
                            return (
                              <div className="row mb-1">
                                <>
                                  <div className="col-lg-2">
                                    <lable className="me-2">Start</lable>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      step={30}
                                      beginLimit={i?.start}
                                      endLimit={"23:30"}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          sun: {
                                            ...availableTime.sun,
                                            timeSlots:
                                              availableTime.sun.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 7,
                                                      start: e.target.value,
                                                      end: moment(
                                                        e.target.value,
                                                        "HH:mm"
                                                      )
                                                        .add(30, "minutes")
                                                        .format("HH:mm"),
                                                      id: i?.id,
                                                      showPlusBtn: true,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    <lable>to</lable>
                                  </div>
                                  <div className="col-lg-2">
                                    <label className="me-2">End</label>
                                    <TimePicker
                                      disabled={i?.disabled}
                                      beginLimit={moment(i?.start, "HH:mm")
                                        .add(30, "minutes")
                                        .format("HH:mm")}
                                      endLimit={"24:00"}
                                      step={30}
                                      onChange={(e) => {
                                        setAvailableTime({
                                          ...availableTime,
                                          sun: {
                                            ...availableTime.sun,
                                            timeSlots:
                                              availableTime.sun.timeSlots.map(
                                                (item) => {
                                                  if (item.id === i?.id) {
                                                    return {
                                                      day: 7,
                                                      start: item.start,
                                                      end: e.target.value,
                                                      id: i?.id,
                                                      showPlusBtn:
                                                        e.target.value ===
                                                        "00:00"
                                                          ? false
                                                          : true,
                                                      showDeleteBtn: false,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              ),
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-1">
                                    {i?.showPlusBtn && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={(e) =>
                                          addTimeSlot(
                                            i?.start,
                                            i?.end,
                                            "sun",
                                            i?.id
                                          )
                                        }
                                      />
                                    )}
                                    {i?.showDeleteBtn && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() =>
                                          deleteTimeSlot(i?.id, "sun")
                                        }
                                      />
                                    )}
                                  </div>
                                </>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {dateError?.error && (
                <span className="text-danger">{dateError?.message}</span>
              )}
              <input
                name="login"
                id="login"
                className="mt-4 btn btn-primary btn-block login-btn nextBtn"
                type="button"
                value="Update"
                onClick={onSubmit}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default Availability;
