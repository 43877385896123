import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Card, Table, Spinner } from "react-bootstrap";
import profilePic from "../../../assets/img/profilepic.png";
import "./profile.css";
import { uploadImage } from "../../../services/enterprise";
// library for phone input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { uploading } from "../../../features/uploading/uploadingSlice";
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const errorNotify = (msg) =>
  toast(msg, {
    duration: 3000,
    position: "top-right",
  });

// toaster for success
const successNotify = (msg) =>
  toast(msg, {
    duration: 3000,
    position: "top-right",
    style: {
      backgroundColor: "green",
      color: "white",
      marginTop: "50px",
    },
  });

// loader
const Loader = () => {
  return (
    <div className="text-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text?.slice(0, 600) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};

function Profile(props) {
  const dispatch = useDispatch();
  // loader state
  const [loader, setLoader] = useState();
  // state for form is editable or not
  const [editform, setEditForm] = useState(false);
  // state to store userdata from response
  const [user, setUser] = useState();
  // get Id from local storage and hit aPI to get user data
  useEffect(() => {
    setLoader(true);
    async function fetchData() {
      const url = baseUrl + `/enterprise/profile`;
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      };
      await axios
        .get(url, { headers })
        .then((response) => {
          setUser(response.data.data);
          setLoader(false);
        })
        .catch((error) => {
          let err;
          if (error.response.status === 422) {
            err = Object.values(error.response.data.errors)[0][0];
          } else if (error.response.status === 400) {
            err = error.response.data.message;
          } else if (error.response.status === 401) {
            err = error.response.data.message;
          } else {
            err = "Something went wrong";
          }
          errorNotify(err);
        });
    }
    fetchData();
  }, [editform]);

  // get country list for drop down
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const url = baseUrl + `/countries?per_page=258`;
      const headers = {
        Accept: "application/json",
      };
      await axios
        .get(url, { headers })
        .then((response) => {
          setCountries(response.data.data);
        })
        .catch((error) => {
          let err;
          err = "Something went wrong";
          errorNotify(err);
        });
    }
    fetchData();
  }, []);

  // handle edit
  const handleEdit = (event) => {
    event.preventDefault();
    setEditForm(!editform);
  };

  // handle form input chnage
  // state to show required error
  const [errors, setErrors] = useState({
    bio: false,
    first_name: false,
    last_name: false,
    city: false,
    state: false,
    company_phone: false,
    validForm: true,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
    if (value.length === 0) {
      setErrors({
        ...errors,
        [name]: true,
        validForm: false,
      });
    } else {
      setErrors({
        ...errors,
        [name]: false,
        validForm: true,
      });
    }
  };

  // handle profile upload
  const [imgData, setImgData] = useState(null);
  const [image_id, setImage_id] = useState();
  const handleUpload = async (event) => {
    // to get priewv of the image yet to be uploaded
    if (event.target.files[0]) {
      // uploading image
      dispatch(uploading(true));
      const formData = new FormData();
      formData.append("image", event.target.files[0]);
      await uploadImage(formData, props.setPercentage)
        .then((response) => {
          // setting image id from response
          setImage_id(response.data.data.id);
        })
        .catch((error) => errorNotify("Something went wrong"));
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
    dispatch(uploading(false));
  };

  // handle form submit
  const onSubmit = async (event) => {
    event.preventDefault();
    // create object of the form which needs to be submit
    const obj = {
      bio: user.bio,
      company_name: user.company_name,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      country_id: user.country_id,
      company_phone: user.company_phone,
      company_logo_id: image_id ? image_id : user.company_logo_id,
    };
    const url = baseUrl + "/enterprise/profile";
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    };
    await axios
      .post(url, obj, { headers })
      .then((response) => {
        setEditForm(false);
        successNotify("Updated Successfully");
      })
      .catch((error) => {
        errorNotify("Something went wrong");
      });
  };
  return (
    <>
      <main id="main" className="bg-inner">
        <section className="student-signup-page signup-congrats">
          <div className="container">
            {loader ? (
              <Loader />
            ) : (
              <div className="row p-2">
                <div className="col-sm-6">
                  <Card className="left-card">
                    <div
                      className={`${
                        editform ? "edit_profile_icon" : "display_none"
                      }`}
                    >
                      <label>
                        <i className="bi bi-pencil-square">
                          <input type="file" onChange={handleUpload} />
                        </i>
                      </label>
                    </div>
                    <Card.Img
                      variant="center"
                      src={
                        imgData
                          ? imgData
                          : user?.company_logo
                          ? `https://daylearningapi.daydevelopers.com/storage/${user.company_logo.path}`
                          : profilePic
                      }
                      height={150}
                      width={150}
                      alt="profile_image"
                      className="profile_pic rounded-circle mt-4 m-auto"
                    />
                    <Card.Body className="mt-2">
                      <Card.Text>
                        {editform ? (
                          <textarea
                            rows="12"
                            className="form-control input_fields"
                            disabled={!editform}
                            defaultValue={user?.bio}
                            onChange={handleChange}
                            name="bio"
                          ></textarea>
                        ) : user?.bio ? (
                          <ReadMore>{user?.bio}</ReadMore>
                        ) : (
                          ""
                        )}
                        {errors.bio ? (
                          <span className="text-danger">Required</span>
                        ) : null}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-6 p-4">
                  <form action="">
                    <Table hover>
                      {editform ? (
                        <tbody>
                          <tr>
                            <td>Company Name</td>
                            <td>
                              <input
                                type="text"
                                className="form-control input_fields"
                                disabled={!editform}
                                defaultValue={user?.company_name}
                                name="company_name"
                                onChange={handleChange}
                              />
                              {errors.company_name ? (
                                <span className="text-danger">Required</span>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td>First Name</td>
                            <td>
                              <input
                                type="text"
                                className="form-control input_fields"
                                disabled={!editform}
                                defaultValue={user?.first_name}
                                name="first_name"
                                onChange={handleChange}
                              />
                              {errors.first_name ? (
                                <span className="text-danger">Required</span>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>
                              <input
                                type="text"
                                className="form-control input_fields"
                                disabled={!editform}
                                defaultValue={user?.last_name}
                                name="last_name"
                                onChange={handleChange}
                              />
                              {errors.last_name ? (
                                <span className="text-danger">Required</span>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>
                              <input
                                type="text"
                                className="form-control input_fields"
                                disabled
                                value={user?.email}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <td>
                              <PhoneInput
                                value={user?.company_phone}
                                country={"us"}
                                disabled={!editform}
                                onChange={(e) => {
                                  setUser({
                                    ...user,
                                    company_phone: e,
                                  });
                                  if (e.length === 0) {
                                    setErrors({
                                      ...errors,
                                      company_phone: true,
                                      validForm: false,
                                    });
                                  } else {
                                    setErrors({
                                      ...errors,
                                      company_phone: false,
                                      validForm: true,
                                    });
                                  }
                                }}
                              />
                              {errors.company_phone ? (
                                <span className="text-danger">Required</span>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td>Country</td>
                            <td>
                              <select
                                disabled={!editform}
                                className="form-control input_fields"
                                name="country_id"
                                onChange={handleChange}
                              >
                                <option value={user?.country?.id} selected>
                                  {user?.country?.name}
                                </option>
                                {countries.map((item) => {
                                  return (
                                    <option key={item?.id} value={item?.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>Company Name</td>
                            <td>{user?.company_name}</td>
                          </tr>
                          <tr>
                            <td>First Name</td>
                            <td>{user?.first_name}</td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>{user?.last_name}</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>{user?.email}</td>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <td>{user?.company_phone}</td>
                          </tr>
                          <tr>
                            <td>Country</td>
                            <td>{user?.country?.name}</td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                    <div className="row">
                      <div className="col-sm-12">
                        {editform ? (
                          <button
                            type="submit"
                            onClick={onSubmit}
                            disabled={!errors.validForm}
                            className="form-control btn btn-primary edit-btn"
                          >
                            Update Profile
                          </button>
                        ) : (
                          <button
                            className="form-control btn btn-primary edit-btn"
                            onClick={handleEdit}
                          >
                            Edit Profile
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
      <Toaster
        toastOptions={{
          style: {
            background: "red",
            color: "white",
          },
        }}
      />
    </>
  );
}

export default Profile;
