import React, { lazy, Suspense } from "react";
import Banner from "../../component/Banner/banner";
import Aboutus from "../../component/Aboutus/index";
import Aboutuswhy from "../../component/Aboutuswhy/index";
import Testimonial from "../../component/Testimonial/index";
import Join from "../../component/Join/index";
import Footer from "../Footer/footer";
import FeaturedLanguage from "../../component/FeaturedLanguage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Whyus = lazy(() => import("../../component/Whyus/index"));
const Whyussatisfy = lazy(() => import("../../component/Whyussatisfy/index"));

function Home() {
  const navigate = useNavigate();
  // for scroll to top on render the home component
  useEffect(() => {
    window.scrollTo(0, 0);
    const role = localStorage.getItem("user_role");
    if (role === '2') {
      navigate("/tutor/dashboard");
    }
  }, [navigate]);
  return (
    <>
      <Banner />
      <FeaturedLanguage />
      <Suspense
        fallback={<div style={{ textAlign: "center" }}>Loading...</div>}
      >
        <Whyus />
      </Suspense>
      <Aboutus />
      <Aboutuswhy />
      <Suspense
        fallback={<div style={{ textAlign: "center" }}>loading...</div>}
      >
        <Whyussatisfy />
      </Suspense>
      <Testimonial />
      <Join />
      {/* <Footer /> */}
    </>
  );
}

export default Home;
