import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Education from "./Education";
import WorkExperience from "./WorkExperience";
import Certification from "./Certification";

function Resume({ teacher_education, teacher_certificate }) {
  return (
    <section id="resume" className="tutor-resume card-body-section">
      <h3>Resume</h3>
      {/* <!-- Tabs navs --> */}
      {/* <!-- Nav tabs --> */}
      <Tabs
        defaultActiveKey="education"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="education" title="Education">
          <Education teacher_education={teacher_education} />
        </Tab>
        <Tab eventKey="work_experience" title="Work Experience">
          <WorkExperience />
        </Tab>
        <Tab eventKey="certification" title="Certification">
          <Certification teacher_certificate={teacher_certificate} />
        </Tab>
      </Tabs>
    </section>
  );
}

export default Resume;
