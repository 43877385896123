var createReactClass = require('create-react-class');
var React = require('react');
var moment = require('moment');

var TimePicker =createReactClass({
	

	isEarlierThanEndLimit: function(timeValue, endLimit, lastValue) {
		var timeValueIsEarlier = moment(timeValue, 'h:mmA').diff(moment(endLimit, 'h:mmA')) < 0
		var timeValueIsLaterThanLastValue = lastValue === undefined ? true : moment(lastValue, 'h:mmA').diff(moment(timeValue, 'h:mmA')) < 0
		return timeValueIsEarlier && timeValueIsLaterThanLastValue;
	},

	render () {
		var timeValue = this.props.beginLimit || "00:00";
		var lastValue;
        var endLimit = this.props.endLimit || "24:00";
		var step = this.props.step || 15;

		var options = [];
		options.push(<option key={timeValue} value={timeValue}>{timeValue}</option>);

    while ( this.isEarlierThanEndLimit(timeValue, endLimit, lastValue) ) {
			lastValue = timeValue;
      timeValue = moment(timeValue, 'h:mmA').add(step, 'minutes').format('HH:mm');
      options.push(<option key={timeValue} value={timeValue}>{timeValue}</option>)
    }
    return(
      <select disabled={this.props.disabled} defaultValue={this.props.defaultValue} onChange={this.props.onChange} name={this.props.name}>
        {options}
      </select>
    )
	}
});

export default TimePicker;