import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import tutor from "../../assets/img/pro-tutor.svg";

import { Link } from "react-router-dom";

import "./aboutuswhy.css";

function Aboutuswhy() {
  return (
    <>
      <section id="about" className="about why-us">
        <Container>
          <Row className="content">
            <Col
              md="6"
              className="d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1"
            >
              <h3>Become a pro tutor with DayLearning.</h3>
              <p>
                If you are a linguist who’s passionate about languages as well
                as teaching and mentoring, join our global team of tutors to
                teach students online and earn money!
              </p>

              <div className="accordion-list">
                <ul>
                  <li>
                    <i className="bx bi-check2"></i>
                    <p>
                      Receive feedback from native speakers and professional
                      teachers.
                    </p>
                  </li>
                  <li>
                    <i className="bx bi-check2"></i>
                    <p>
                      Meet and share experiences with millions of language
                      learners from more than 190 countries.
                    </p>
                  </li>
                </ul>
              </div>
              <Link to="#" className="btn-learn-more">
                Learn More
              </Link>
            </Col>
            <Col
              md="6"
              className="bg align-items-stretch order-1 order-lg-2 img"
              style={{
                backgroundImage: "url(" + tutor + ")",
                backgroundSize: "contain ",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top center",
              }}
            ></Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Aboutuswhy;
