import React from "react";
import { Link } from "react-router-dom";
import sidepic1 from "../../assets/img/side-pic1.png";
import sidepic2 from "../../assets/img/side-pic2.svg";

function SideBar() {
  return (
    <div className="col-lg-4 sidebar">
      <div className="card rounded-0 border-0 mb-4">
        <div className="card-body p-0">
          <div className="media mb-3">
            <Link className="d-block" to="">
              <img className="img-fluid w-100" src={sidepic1} alt="" />
            </Link>
          </div>
          <div className="media mb-3">
            <Link className="d-block" to="">
              <img className="img-fluid w-100" src={sidepic2} alt="" />
            </Link>
            <div className="content-area text-center">
              <Link to="#" className="theme-btn">
                Chat Now
              </Link>
              <p>Let’s talk to our expert team mates</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
