import React from "react";
import { Modal, Button } from "react-bootstrap";
import { closeModal } from "../../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateBookingStatus } from "../../services/tutors";
import { useState } from "react";
import { errorNotify } from "../toasters";

function ChangeBookedLessonStatusModal() {
  const state = useSelector(
    (state) => state?.modalState?.changeBookedLessonStatusModal
  );
  const setStatusChange = state?.data?.setStatusChange;
  const defaultStatus = state?.data?.item?.booking_status;
  const dispatch = useDispatch();
  const [status, setStatus] = useState({
    booking_status: null,
    id: state?.data?.item?.id,
  });
  const [err, setErr] = useState(null);
  const [loader, setLoader] = useState(false);
  // handle change status
  const handleStatusChange = (e) => {
    const { value } = e.target;
    setStatus({
      id: state?.data?.item?.id,
      booking_status: value,
    });
    setErr(false);
  };

  // handel submit
  const handleSubmit = () => {
    setLoader(true);
    if (status?.booking_status) {
      updateBookingStatus(status)
        .then((res) => {
          setStatusChange((prev) => !prev);
          setLoader(false);
          dispatch(closeModal());
        })
        .catch((err) => {
          errorNotify("Something went wrong");
          setLoader(false);
        });
    } else {
      setErr(true);
    }
  };
  // handle close
  const handleClose = () => {
    setErr(null);
    setStatus({
      booking_status: null,
      id: null,
    });
    dispatch(closeModal());
  };

  return (
    <Modal id="loginForm" show={state.value} onHide={handleClose} centered>
      <form>
        <Button
          className="btn-close"
          data-bs-dismiss="modal"
          onClick={handleClose}
          aria-label="Close"
        ></Button>
        <Modal.Header>
          <Modal.Title>
            <b>Status</b>
          </Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body className="p-top">
          <div>
            <p>Select Status</p>
          </div>
          <div className="row mt-2 mb-4">
            <div className="col">
              <input
                style={{ width: "15px" }}
                value={1}
                name="status"
                type={"radio"}
                onChange={handleStatusChange}
                defaultChecked={defaultStatus === "1"}
              />{" "}
              Pending{" "}
            </div>
            <div className="col">
              <input
                style={{ width: "15px" }}
                value={2}
                name="status"
                type={"radio"}
                onChange={handleStatusChange}
                defaultChecked={defaultStatus === "2"}
              />{" "}
              Approved{" "}
            </div>
            <div className="col">
              <input
                style={{ width: "15px" }}
                value={3}
                name="status"
                type={"radio"}
                onChange={handleStatusChange}
                defaultChecked={defaultStatus === "3"}
              />{" "}
              Declined
            </div>
            {err && <span className="text-danger mt-2">Required</span>}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center d_block">
          <Button variant="primary" onClick={handleSubmit}>
            {loader ? "Updating.." : "Submit"}
          </Button>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default ChangeBookedLessonStatusModal;
