import React from "react";
import Progress from "./ProgressBar";

function RatingBreakdown() {
  return (
    <>
      <h4>Rating breakdown</h4>
      <Progress progress={100} variant={'warning'} stars={5} rating={1}/>
      <Progress progress={80}  stars={4} rating={0}/>
      <Progress progress={60}  stars={3} rating={0}/>
      <Progress progress={40}  stars={2} rating={0}/>
      <Progress progress={20}  stars={1} rating={0}/>
    </>
  );
}

export default RatingBreakdown;
