import React from "react";
import calendarImage from '../../../../assets/img/google-calender.png';

function Calendar() {
  return (
    <div className="card card-primary">
      <div
        className=""
        id="vert-tabs-calender"
        role="tabpanel"
        aria-labelledby="vert-tabs-calender-tab"
      >
        <div className="card-header">
          <h3 className="card-title">Google Calender</h3>
        </div>

        <div className="wrapper bg-white mt-sm-5 p-4">
          <div className="py-2">
            <div className="row py-2">
              <div className="col-md-7">
                <div className="d-flex">
                  <img
                    style={{ marginRight: "20px" }}
                    width="80"
                    height="80"
                    alt="calender"
                    src={calendarImage}
                  />
                  <p>
                    Connect your Google Calendar and synchronize all your Preply
                    lessons with your personal schedule
                  </p>
                </div>
              </div>
              <div className="col-md-5 align-items-center d-flex">
                <button className="btn btn-primary mr-3">
                  Connect Google Calender
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
