import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Navbar, NavDropdown, Container, Nav, Button } from "react-bootstrap";
import logo from "../../assets/img/Logo.svg";
import "./header.css";
import "../../assets/css/style.css";
import { useDispatch } from "react-redux";
import { storeUser } from "../../features/storeUser/userSlice";
import {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
} from "../../features/Tutor/stepsSlice";
import {
  openLoginModal,
  openSignupModal,
} from "../../features/modal/modalSlice";

export const useScrollHandler = () => {
  const [scroll, setScroll] = useState("");
  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY > 10;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    };
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [scroll, setScroll]);
  return scroll;
};

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scroll = useScrollHandler();
  const loginFormOpen = () => {
    dispatch(openLoginModal());
  };

  const handleLogout = () => {
    // clear all states on logout
    dispatch(storeUser(null));
    dispatch(step1(null));
    dispatch(step2(null));
    dispatch(step3(null));
    dispatch(step4(null));
    dispatch(step5(null));
    dispatch(step6(null));
    dispatch(step7(null));
    localStorage.clear();
    navigate("/");
  };

  const currentPath = window.location.pathname;
  let user = JSON.parse(localStorage.getItem("name"));
  return (
    <>
      <header
        id="header"
        className={`fixed-top ${currentPath === "/" ? scroll : true}`}
      >
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand>
              <Link to="/">
                <img src={logo} alt="" className="img-fluid" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Link to="/find-a-tutor">Find a Tutor</Link>
                <Link to="/became-a-tutor">Become a Tutor</Link>
                <Link to="/enterprise-account">Enterprise Account</Link>
                <NavDropdown title="Explore More" id="basic-nav-dropdown">
                  <NavDropdown.Item className="drop-down-item" to="/">
                    How it works
                  </NavDropdown.Item>
                  <NavDropdown.Item className="drop-down-item" href="/about-us">
                    About us
                  </NavDropdown.Item>
                  <NavDropdown.Item className="drop-down-item" href="/contact-us">
                    Contact us
                  </NavDropdown.Item>
                  <NavDropdown.Item className="drop-down-item" to="/">
                    Language forum
                  </NavDropdown.Item>
                </NavDropdown>
                {localStorage.getItem("token") ? (
                  <>
                    {/* student */}
                    {JSON.parse(localStorage.getItem("user_role")) === 3 && (
                      <NavDropdown title={user} id="basic-nav-dropdown">
                        <Link
                          className="drop-down-item"
                          to="/student/dashboard/setting"
                        >
                          Dashboard
                        </Link>
                        {/* <Link className="drop-down-item" to="/student/profile">
                        Profile
                      </Link> */}
                      </NavDropdown>
                    )}
                    {/* teacher */}
                    {JSON.parse(localStorage.getItem("user_role")) === 2 && (
                      <NavDropdown title={user} id="basic-nav-dropdown">
                        <Link className="drop-down-item" to="/tutor/dashboard">
                          Dashboard
                        </Link>
                        <Link className="drop-down-item" to="/tutor/profile">
                          Profile
                        </Link>
                      </NavDropdown>
                    )}
                    {/* enterprise */}
                    {JSON.parse(localStorage.getItem("user_role")) === 4 && (
                      <NavDropdown title={user} id="basic-nav-dropdown">
                        <Link
                          className="drop-down-item"
                          to="/enterprise/dashboard"
                        >
                          Dashboard
                        </Link>
                        {/* <Link className="drop-down-item" to="/enterprise/profile">
                        Profile
                      </Link> */}
                      </NavDropdown>
                    )}
                    <Nav.Link to="#" onClick={handleLogout}>
                      Logout
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <Nav.Link to="#" onClick={loginFormOpen}>
                      Login
                    </Nav.Link>
                    <Button
                      className="getstarted scrollto"
                      onClick={() => dispatch(openSignupModal())}
                    >
                      Sign Up
                    </Button>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default Header;
