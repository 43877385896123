import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import testimonial from "../../assets/img/Client-Photo.svg";
import quote from "../../assets/img/quote.svg";

import Carousel from "react-bootstrap/Carousel";

import "./testimonial.css";

function Testimonial() {
  return (
    <>
      <section id="testimonial">
        <Container>
          <Row>
            <Col md="12">
              <Carousel>
                <Carousel.Item>
                  <div className="carousel-content">
                    <div className="client-img">
                      <img src={testimonial} alt="testimonial-img" />
                    </div>
                    <h2>
                      <img src={quote} alt="quote-img" />
                      Hear from our top language <br /> learners.
                    </h2>
                    <p>
                      <i>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Interdum quis amet faus lorem. Lectus amet odio quis sed
                        adipiscing adipiscing magna non. Nullam turpis faucibus
                        ridi suscipit. Et blandit suspendisse curabitur eu
                        congue dui. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Interdum quis amet, faucibus lorem.
                        Lectus amet odio quis sed adipiscing adipiscing magna
                        non.
                      </i>
                    </p>
                    <h3>Scarlett Johansson</h3>
                    <h4>CEO at Xion</h4>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="carousel-content">
                    <div className="client-img">
                      <img src={testimonial} alt="testimonial-img" />
                    </div>
                    <h2>
                      <img src={quote} alt="quote-img" />
                      Hear from our top language <br /> learners.
                    </h2>
                    <p>
                      <i>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Interdum quis amet faus lorem. Lectus amet odio quis sed
                        adipiscing adipiscing magna non. Nullam turpis faucibus
                        ridi suscipit. Et blandit suspendisse curabitur eu
                        congue dui. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Interdum quis amet, faucibus lorem.
                        Lectus amet odio quis sed adipiscing adipiscing magna
                        non.
                      </i>
                    </p>
                    <h3>Scarlett Johansson</h3>
                    <h4>CEO at Xion</h4>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="carousel-content">
                    <div className="client-img">
                      <img src={testimonial} alt="testimonial-img" />
                    </div>
                    <h2>
                      <img src={quote} alt="quote-img" />
                      Hear from our top language <br /> learners.
                    </h2>
                    <p>
                      <i>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Interdum quis amet faus lorem. Lectus amet odio quis sed
                        adipiscing adipiscing magna non. Nullam turpis faucibus
                        ridi suscipit. Et blandit suspendisse curabitur eu
                        congue dui. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Interdum quis amet, faucibus lorem.
                        Lectus amet odio quis sed adipiscing adipiscing magna
                        non.
                      </i>
                    </p>
                    <h3>Scarlett Johansson</h3>
                    <h4>CEO at Xion</h4>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Testimonial;
