import React from "react";
import Image from "./Image";
import Name from "./Name";

function Header({ book_tutor }) {
  const { profile_image, verified, first_name, last_name,native_language } = book_tutor;
  const imgUrl = process.env.REACT_APP_FILES + profile_image.path;
  return (
    <div>
      <div className="row mb-2">
        <div className="col-lg-3">
          <Image imgUrl={imgUrl} />
        </div>
        <div className="col">
          <Name
            first_name={first_name}
            last_name={last_name}
            verified={verified}
            native_language={native_language}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
