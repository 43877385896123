import React from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import "./aboutus.css";

function Join() {
  return (
    <>
      <section id="newsletter" className="newsletter">
        <Container>
          <div className="newsletter-inner">
            <Row>
              <Col
                md="6"
                className="align-items-stretch order-1 order-lg-1 img"
              >
                <h4>
                  Ready to join our <br />
                  learners community?
                </h4>
                <Form>
                  <Form.Group>
                    <Form.Control
                      placeholder="Your work email address"
                      type="email"
                    />
                    <Button type="submit">Subscribe</Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Join;
