import axios from "axios";

// api endpoint
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

// get booked lesson listing
export const getBookedLessonListing = async (id, page) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url =
    baseUrl + `/book-lesson?per_page=10&page=${page}&student_booking=${id}`;
  return await axios.get(url, { headers });
};

// upload profile pic
export const uploadImage = async (file, setPercentage) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/image-upload";
  const config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setPercentage(percent);
    },
    headers: headers,
  };
  return await axios.post(url, file, config);
};

// get student profile
export const getStudentProfile = async () => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/student/profile";
  return await axios.get(url, { headers });
};

// get timezone
export const timeZones = async () => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/timezone";
  return await axios.get(url, { headers });
};

// update student
export const updateStudent = async (data) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/student/profile";
  return await axios.post(url, data, { headers });
};

// forgot-passowrd
export const forgotPassword = async (data) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/password/forgot-password";
  return await axios.post(url, data, { headers });
};

// update password
export const updatePassword = async (data) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/password-update";
  return await axios.post(url, data, { headers });
};

// add credit/debit card
export const addCard = async (data) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/stripe/customer";
  return await axios.post(url, data, { headers });
};

// get card details
export const getCard = async (data) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/getstripe/customer";
  return await axios.get(url, { headers });
};

// update card
export const updateCard = async (data) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/stripe-customer/update";
  return await axios.post(url, data, { headers });
};

// get chekout detail
export const getCheckoutDetails = async (uuid) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + `/book-lesson/${uuid}`;
  return await axios.get(url, { headers });
};

// book trail payment
export const bookTrailPayment = async (data, uuid) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + `/book-lesson/payment/${uuid}`;
  return axios.post(url, data, { headers });
};

// payment history listing
export const getPaymentHistory = async (page) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + `/invoice?per_page=10&page=${page}`;
  return await axios.get(url, { headers });
};

// get notification settings
export const getNotificationSettings = async () => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/user/notification";
  return await axios.get(url, { headers });
};

// create notification settings
export const createNotificationSettings = async (data) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/user/notification";
  return await axios.post(url, data, { headers });
};

// update notification settings
export const updateNotificationSettings = async (data) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
  };
  const url = baseUrl + "/user/notification/update";
  return await axios.post(url, data, { headers });
};
