import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { Link } from "react-router-dom";
import { updatePassword } from "../../../../services/student";
import { successNotify, errorNotify } from "../../../../ui/toasters";
function Password() {
  // forgot modal state
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm({mode:'onChange'});
  // onsubmit function
  const onSubmit = (data) => {
    setDisable(true);
    updatePassword(data)
      .then((res) => {
        successNotify(res.data.message);
        setDisable(false);
      })
      .catch((err) => {
        errorNotify(err.response.data.message);
        setDisable(false);
      });
  };
  return (
    <>
      <ForgotPasswordModal show={show} setShow={setShow} />
      <div className="card card-primary">
        <div
          className=""
          id="vert-tabs-password"
          role="tabpanel"
          aria-labelledby="vert-tabs-password-tab"
        >
          <div className="card-header">
            <h3 className="card-title">Change Password</h3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="wrapper bg-white p-4">
              <div className="py-2">
                <div className="row py-2">
                  <div className="col-md-6">
                    <label htmlFor="firstname">Current Password</label>
                    <input
                      {...register("current_password", {
                        required: {
                          value: true,
                          message: "Required",
                        },
                      })}
                      type="password"
                      className="bg-light form-control"
                      placeholder=""
                    />
                    {errors?.current_password?.type === "required" && (
                      <span className="text-danger">
                        {errors?.current_password?.message}
                      </span>
                    )}
                  </div>
                  <Link to="" onClick={() => setShow(true)}>
                    Forgot Password?
                  </Link>
                </div>

                <div className="row py-2">
                  <div className="col-md-6">
                    <label htmlFor="firstname">New Password</label>
                    <input
                      {...register("new_password", {
                        required: {
                          value: true,
                          message: "Required",
                        },
                        pattern: {
                          value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                          message:
                            "Password must be alteast 8 character long must contain 1 letter , 1 number and 1 special character",
                        },
                      })}
                      type="password"
                      className="bg-light form-control"
                      placeholder=""
                    />
                    {errors?.new_password?.type === "required" && (
                      <span className="text-danger">
                        {errors?.new_password?.message}
                      </span>
                    )}
                    {errors.new_password?.type === "pattern" && (
                      <span className="text-danger">
                        {errors?.new_password?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-md-6">
                    <label htmlFor="firstname">Verify Password</label>
                    <input
                      {...register("confirm_password", {
                        required: {
                          value: true,
                          message: "Required",
                        },
                      })}
                      type="password"
                      className="bg-light form-control"
                      placeholder=""
                    />
                    {errors?.confirm_password?.type === "required" && (
                      <span className="text-danger">
                        {errors?.confirm_password?.message}
                      </span>
                    )}
                    {watch("confirm_password") !== watch("new_password") &&
                    getValues("confirm_password") ? (
                      <span className="text-danger">Password not match</span>
                    ) : null}
                  </div>
                </div>

                <div className="py-3 pb-4">
                  <button
                    disabled={disable}
                    type="submit"
                    className="btn btn-primary me-3"
                  >
                    Save Changes
                  </button>
                  <button type="button" className="btn border button">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Password;
