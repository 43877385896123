import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { step5 } from "../../../../features/Tutor/stepsSlice";
import { description } from "../../../../services/tutors";
import { successNotify, errorNotify } from "../../../../ui/toasters";

const imgURl = process.env.REACT_APP_FILES;
function Step5(props) {
  const [noTeachingCertificate, setNoTeachingCertificate] = useState(false);
  // for scroll to top on render the home component
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [disableNextBtn, setDisableNextBtn] = useState(false);

  const { steps, setSteps, user } = props;
  // for lenght of motivate heading field
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      headline: user?.teacher_description?.headline,
      introduction: user?.teacher_description?.introduction,
      describe_teaching: user?.teacher_description?.describe_teaching,
      motivate_heading: user?.teacher_description?.motivate_heading,
    },
  });
  const onSubmit = async (data) => {
    dispatch(step5(data));
    const object = {
      headline: data.headline,
      introduction: data.introduction,
      describe_teaching: data.describe_teaching,
      motivate_heading: data.motivate_heading,
    };
    // disbling next button so user can't hit API more than once
    setDisableNextBtn(true);

    description(object)
      .then((res) => {
        if (res.status === 200) {
          successNotify(res.data.message);
          setSteps({
            ...steps,
            step6: { value: true, display: "show" },
            step5: { value: true, display: "none" },
          });
        }
      })
      .catch((err) => {
        errorNotify("Something went wrong");
        setDisableNextBtn(false);
      });
  };
  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-6">
          <div className="custom-checkbox">
            <label className="container-check">
              I don't have any teaching certificate
              <input
                type="checkbox"
                onClick={(e) => setNoTeachingCertificate(e.target.checked)}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      {noTeachingCertificate ? (
        <div className="row">
          <div className="col-lg-6">
            <input
              name="login"
              id="login"
              className="btn btn-primary btn-block login-btn nextBtn"
              type="button"
              value="Next"
              onClick={() => {
                setSteps({
                  ...steps,
                  step6: { value: true, display: "show" },
                  step5: { value: true, display: "none" },
                });
              }}
            />
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="panel panel-primary setup-content" id="step-4">
            <div className="panel-body">
              <h3>Description for english speaking students</h3>
              <div className="row">
                <div className="col-lg-2">
                  <img
                    width="100%"
                    src={imgURl + user?.profile_image?.path}
                    alt="img"
                  />
                </div>
                <div className="col-lg-4">
                  <label htmlFor="experienceteaching">
                    Write your headline in english
                  </label>
                  <input
                    type="expteaching"
                    name="expteaching"
                    id="expteaching"
                    className="form-control"
                    {...register("headline", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  />
                  {errors?.headline?.type === "required" && (
                    <span className="text-danger">
                      {errors?.headline?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label htmlFor="experienceteaching">
                    Introduce yourself and share briefly about your interests
                  </label>
                  <textarea
                    className="form-control"
                    {...register("introduction", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  ></textarea>
                  {errors?.introduction?.type === "required" && (
                    <span className="text-danger">
                      {errors?.introduction?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label htmlFor="currentsituation">
                    Describe your teaching experience, certification and
                    methodology{" "}
                  </label>
                  <textarea
                    className="form-control"
                    {...register("describe_teaching", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  ></textarea>
                  {errors?.describe_teaching?.type === "required" && (
                    <span className="text-danger">
                      {errors?.describe_teaching?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label htmlFor="country">
                    Motivate Students to book a trial lesson with you
                  </label>
                  <textarea
                    className="form-control"
                    {...register("motivate_heading", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  ></textarea>
                  {errors?.motivate_heading?.type === "required" && (
                    <span className="text-danger">
                      {errors?.motivate_heading?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mt-2 mb-2"></div>
              <div className="row">
                <div className="col-lg-6">
                  <input
                    name="login"
                    id="login"
                    className="btn btn-primary btn-block login-btn nextBtn"
                    type="submit"
                    value="Next"
                    disabled={disableNextBtn}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default Step5;
