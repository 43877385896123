import React, { useEffect, useState } from "react";
import { countries, languages } from "../../../../services/common";
import { useForm, Controller } from "react-hook-form";
import { step1 } from "../../../../features/Tutor/stepsSlice";
import Select from "react-select";
import { updateTutor } from "../../../../services/tutors";
import { successNotify, errorNotify } from "../../../../ui/toasters";
// library for phone input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import DefaultProfileImage from "../../../../assets/img/profilepic.png";
import { uploadImage } from "../../../../services/tutors";
import { step2 } from "../../../../features/Tutor/stepsSlice";
import { uploading } from "../../../../features/uploading/uploadingSlice";
const ImagesUrl = process.env.REACT_APP_FILES;
function Step1(props) {
  // for scroll to top on render the home component
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const { steps, setSteps, setPercentage } = props;
  const state = useSelector((state) => state.steps.step1);
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = async (data, e) => {
    // dispatch step 1 form
    dispatch(step1(data));
    // carerting object of data which needs to be updated
    const object = {
      first_name: data.first_name,
      last_name: data.last_name,
      teacher_type: data.level,
      subject_taught: data.subject_taught,
      hourly_rate: data.hourly_rate,
      teaching_experience: data.teaching_exp,
      current_situation: data.current_situation,
      confirm_older: data.age_check ? data.age_check : 0,
      country_id: data.country.value,
      native_language_id: data.language.value,
      phone: data.phone,
      profile_image_id: file.image_id,
    };
    // disbling next button so user can't hit API more than once
    setDisableNextBtn(true);

    // update API
    updateTutor(object)
      .then((res) => {
        if (res.status === 200) {
          successNotify(res.data.message);
          // movING  to next step
          setSteps({
            ...steps,
            step1: { value: true, display: "none" },
            step3: { value: true, display: "show" },
            step2: { value: true, display: "none" },
          });
        }
      })
      .catch((err) => {
        errorNotify("Something went wrong");
        setDisableNextBtn(false);
      });
  };

  // states for countries and languages array
  const [languageList, setLanguageList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(true);

  // get country list
  useEffect(() => {
    countries()
      .then((res) => {
        setCountryList(
          res.data.data.map((i) => {
            return {
              value: i.id,
              label: i.name,
            };
          })
        );
      })
      .catch((err) => errorNotify("Something went wrong"));
    languages()
      .then((res) => {
        setLanguageList(
          res.data.data.map((i) => {
            return {
              label: i.name,
              value: i.id,
            };
          })
        );
      })
      .catch((err) => errorNotify("Something went wrong"));
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  });

  const [file, setFile] = useState({
    image: null,
    image_id: user?.profile_image_id,
  });
  const handleFileUpload = async (e) => {
    dispatch(uploading(true));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    await uploadImage(formData, setPercentage).then((res) => {
      dispatch(
        step2({
          image: e.target.files[0],
          image_id: res.data.data.id,
        })
      );
      setFile({
        image: e.target.files[0],
        image_id: res.data.data.id,
      });
      dispatch(uploading(false));
    });
  };

  return (
    <>
      {loading ? (
        <div className="text-center p-4 m-4">
          <h4>Loading...</h4>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="panel panel-primary setup-content" id="step-1">
            <div className="panel-body">
              <div className="form-row custom-form-mt">
                <div className="row">
                  {/* First Name */}
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">First Name</label>
                    <input
                      defaultValue={
                        state?.first_name ? state.first_name : user?.first_name
                      }
                      type="text"
                      className="form-control"
                      id="fName"
                      placeholder="Your first name"
                      {...register("first_name", {
                        required: {
                          value:
                            user?.first_name || state?.first_name
                              ? false
                              : true,
                          message: "Firstname is required",
                        },
                      })}
                    />
                    {errors?.first_name?.type === "required" && (
                      <span className="text-danger">
                        {errors?.first_name?.message}
                      </span>
                    )}
                  </div>
                  {/* profile image */}
                  <div className="col-md-6">
                    <span>Profile Image</span>
                    <div className="row text-center">
                      <div className="col">
                        <div className="profile_container">
                          <img
                            src={
                              file?.image
                                ? URL.createObjectURL(file?.image)
                                : user?.profile_image?.path
                                ? ImagesUrl + user?.profile_image?.path
                                : DefaultProfileImage
                            }
                            alt="profile-img"
                            className="profile_img"
                          />
                          <div className="icon">
                            <label>
                              <i className="bi bi-pencil-square">
                                <input
                                  type="file"
                                  className="profile_input"
                                  onChange={handleFileUpload}
                                  accept="image/*"
                                />
                              </i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Last Name */}
                  <div className="form-group col-md-6">
                    <label htmlFor="inputPassword4">Last Name</label>
                    <input
                      defaultValue={
                        state?.last_name ? state.last_name : user?.last_name
                      }
                      type="text"
                      className="form-control"
                      id="lName"
                      placeholder="Your last name"
                      {...register("last_name", {
                        required: {
                          value:
                            user?.last_name || state?.last_name ? false : true,
                          message: "Lastname is required",
                        },
                      })}
                    />
                    {errors?.last_name?.type === "required" && (
                      <span className="text-danger">
                        {errors?.last_name?.message}
                      </span>
                    )}
                  </div>
                  {/* Hourly rate */}
                  <div className="form-group col-md-6">
                    <label htmlFor="hourrate">Hourly rate</label>
                    <div className="input-group mb-2 mr-sm-2">
                      <input
                        type="number"
                        min={1}
                        className="form-control"
                        defaultValue={
                          state?.hourly_rate
                            ? state.hourly_rate
                            : user?.hourly_rate
                        }
                        {...register("hourly_rate", {
                          required: {
                            value:
                              state?.hourly_rate || user?.hourly_rate
                                ? false
                                : true,
                            message: "Required",
                          },
                        })}
                      />
                      <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                      </div>
                    </div>
                    {errors?.hourly_rate?.type === "required" && (
                      <span className="text-danger">
                        {errors?.hourly_rate?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  {/* Country of origin */}
                  <div className="form-group col-md-6">
                    <label htmlFor="country">Country of origin</label>
                    <Controller
                      control={control}
                      name="country"
                      defaultValue={
                        state?.country
                          ? state?.country
                          : user?.country?.id && {
                              label: user?.country?.name,
                              value: user?.country?.id,
                            }
                      }
                      rules={{
                        required: {
                          value: user?.country || state?.country ? false : true,
                          message: "Required",
                        },
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          placeholder="Choose Country..."
                          inputExtraProps={{
                            ref,
                            required: true,
                          }}
                          options={countryList}
                        />
                      )}
                    />
                    {errors?.country?.type === "required" && (
                      <span className="text-danger">
                        {errors?.country?.message}
                      </span>
                    )}
                  </div>
                  {/* Describe your teaching experience */}
                  <div className="form-group col-md-6">
                    <label htmlFor="experienceteaching">
                      Describe your teaching experience
                    </label>
                    <textarea
                      type="expteaching"
                      name="expteaching"
                      id="expteaching"
                      className="form-control"
                      defaultValue={
                        state?.teaching_exp
                          ? state.teaching_exp
                          : user?.teaching_experience
                      }
                      {...register("teaching_exp", {
                        required: {
                          value:
                            state?.teaching_exp || user?.teaching_experience
                              ? false
                              : true,
                          message: "Required",
                        },
                      })}
                    ></textarea>
                    {errors?.teaching_exp?.type === "required" && (
                      <span className="text-danger">
                        {errors?.teaching_exp?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  {/* Languages Spoken */}
                  <div className="form-group col-md-6">
                    <div className="row">
                      <div className="col">
                        <label htmlFor="languages">Languages Spoken</label>
                        <Controller
                          defaultValue={
                            state?.language
                              ? state.language
                              : user?.native_language?.id && {
                                  label: user?.native_language?.name,
                                  value: user?.native_language?.id,
                                }
                          }
                          name="language"
                          control={control}
                          rules={{
                            required: {
                              value:
                                user?.native_language || state?.native_language
                                  ? false
                                  : true,
                              message: "Required",
                            },
                          }}
                          render={({ field: { ref, ...field } }) => (
                            <Select
                              {...field}
                              placeholder="Select Language"
                              inputExtraProps={{
                                ref,
                                required: true,
                              }}
                              options={languageList}
                            />
                          )}
                        />
                        {errors?.language?.type === "required" && (
                          <span className="text-danger">
                            {errors?.language?.message}
                          </span>
                        )}
                      </div>
                      <div className="col">
                        <label htmlFor="Level">Level</label>
                        <select
                          id="Level"
                          name="level"
                          className="form-control"
                          {...register("level")}
                        >
                          <option
                            value="1"
                            defaultValue={
                              Number(state?.level || user?.teacher_type) === 1
                            }
                          >
                            Beginner
                          </option>
                          <option
                            value="2"
                            defaultValue={
                              Number(state?.level || user?.teacher_type) === 2
                            }
                          >
                            Intermediate
                          </option>
                          <option
                            value="3"
                            defaultValue={
                              Number(state?.level || user?.teacher_type) === 3
                            }
                          >
                            Expert
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* Describe your current situation */}
                  <div className="form-group col-md-6">
                    <label htmlFor="currentsituation">
                      Describe your current situation
                    </label>
                    <textarea
                      type="currentsituation"
                      name="currentsituation"
                      id="currentsituation"
                      className="form-control"
                      defaultValue={
                        state?.current_situation
                          ? state?.current_situation
                          : user?.current_situation
                      }
                      {...register("current_situation", {
                        required: {
                          value:
                            state?.current_situation || user?.current_situation
                              ? false
                              : true,
                          message: "Required",
                        },
                      })}
                    ></textarea>
                    {errors?.current_situation?.type === "required" && (
                      <span className="text-danger">
                        {errors?.current_situation?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row">
                  {/* Subject taught */}
                  <div
                    className="form-group col-md-6"
                    style={{ display: "grid" }}
                  >
                    <label htmlFor="subjecttaught">Subject taught</label>
                    <select
                      id="subjectchoices"
                      name="subjectchoices"
                      className="form-control"
                      {...register("subject_taught")}
                    >
                      <option
                        value="1"
                        defaultValue={
                          state?.subject_taught === "1" ||
                          user?.subject_taught === 1
                        }
                      >
                        1
                      </option>
                      <option
                        value="2"
                        defaultValue={
                          state?.subject_taught === "2" ||
                          user?.subject_taught === 2
                        }
                      >
                        2
                      </option>
                      <option
                        value="3"
                        defaultValue={
                          state?.subject_taught === "3" ||
                          user?.subject_taught === 3
                        }
                      >
                        3
                      </option>
                    </select>
                  </div>
                  {/* Phone number(optional) */}
                  <div className="form-group col-md-6">
                    <label htmlFor="numer">Phone number(optional)</label>
                    <Controller
                      control={control}
                      name="phone"
                      defaultValue={state?.phone ? state?.phone : user?.phone}
                      rules={{
                        required: {
                          value: state?.phone || user?.phone ? false : true,
                          message: "Phone number is required",
                        },
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <PhoneInput
                          {...field}
                          inputExtraProps={{
                            ref,
                            required: true,
                          }}
                          country={"us"}
                          countryCodeEditable={true}
                        />
                      )}
                    />
                    {errors.phone?.type === "required" && (
                      <span className="text-danger">
                        {errors?.phone?.message}
                      </span>
                    )}
                    <label htmlFor="disclaimerphone">
                      Receive SMS notifications about new messages from students
                    </label>
                  </div>
                  {/* age_check */}
                </div>
                <div className="row">
                  <div className="form-group col-md-6"></div>
                  <div className="form-group col-md-6">
                    <label className="mb-4 mt-2" style={{ width: "100" }}>
                      <input
                        style={{ width: "20px" }}
                        defaultChecked={
                          Number(state?.age_check || user?.confirm_older) === 1
                            ? true
                            : false
                        }
                        type="checkbox"
                        value={1}
                        name="dusclaimerform"
                        {...register("age_check", {
                          required: {
                            value: true,
                            message: "Required",
                          },
                        })}
                      />{" "}
                      I confirm I'm older 18
                    </label>
                  </div>
                  {errors.age_check?.type === "required" && (
                    <span className="text-danger">
                      {errors?.age_check?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6"></div>
            <div className="form-group col-md-6">
              <button
                disabled={disableNextBtn}
                className="btn btn-primary form-control"
                type="submit"
              >
                Next
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default Step1;
