import React from "react";
import "./uploadFile.css";

function UploadFile(required, handleChange, resetImage, fileName) {
  return (
    <div>
      <label>Upload File</label>
      <div className="col-lg-3 file_container">
        <label>
          Get a Diploma Verified Badge.
          <input
            onChange={(e) => handleChange(e)}
            className="upload_input"
            type={"file"}
            accept=".pdf,.doc"
          />
        </label>
      </div>
      <div className="col-lg-3">
        <button onClick={()=>resetImage()} type="button" className="btn btn-primary mt-2">
          Reset
        </button>
      </div>
    </div>
  );
}

export default UploadFile;
