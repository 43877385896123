import toast from "react-hot-toast";

// toaster for error
export const errorNotify = (msg) =>
  toast(msg, {
    duration: 3000,
    position: "top-right",
    style: {
      backgroundColor: "red",
      color: "white",
    },
  });

// toaster for success
export const successNotify = (msg) =>
  toast(msg, {
    duration: 3000,
    position: "top-right",
    style: {
      backgroundColor: "green",
      color: "white",
      marginTop: "50px",
    },
  });