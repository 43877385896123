import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import { closeModal } from "../../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { addSlots } from "../../services/tutors";

function EditBookingSlotsModal() {
  const state = useSelector((state) => state.modalState.editBookingModal.value);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState("");
  const [slotsTime, setSlotsTime] = useState(5);
  const [startDateError, setStartDateError] = useState("");
  const [disableButton, setDisableButton] = useState(true);

  const [endDateError, setEndDateError] = useState("");
  const [startTimeError, setStartTimeError] = useState({
    sun: { index: 0, message: "" },
    mon: { index: 0, message: "" },
    tue: { index: 0, message: "" },
    wed: { index: 0, message: "" },
    thu: { index: 0, message: "" },
    fri: { index: 0, message: "" },
    sat: { index: 0, message: "" },
  });
  const [endTimeError, setEndTimeError] = useState({
    sun: { index: 0, message: "" },
    mon: { index: 0, message: "" },
    tue: { index: 0, message: "" },
    wed: { index: 0, message: "" },
    thu: { index: 0, message: "" },
    fri: { index: 0, message: "" },
    sat: { index: 0, message: "" },
  });

  const [availableTime, setAvailableTime] = useState({
    mon: {
      checked: false,
      timeSlots: [
        {
          day: 1,
          start_time: "",
          end_time: "",
          add: true,
          delete: false,
        },
      ],
    },
    tue: {
      checked: false,
      timeSlots: [
        {
          day: 2,
          start_time: "",
          end_time: "",
          add: true,
          delete: false,
        },
      ],
    },
    wed: {
      checked: false,
      timeSlots: [
        {
          day: 3,
          start_time: "",
          end_time: "",
          add: true,
          delete: false,
        },
      ],
    },
    thu: {
      checked: false,
      timeSlots: [
        {
          day: 4,
          start_time: "",
          end_time: "",
          add: true,
          delete: false,
        },
      ],
    },
    fri: {
      checked: false,
      timeSlots: [
        {
          day: 5,
          start_time: "",
          end_time: "",
          add: true,
          delete: false,
        },
      ],
    },
    sat: {
      checked: false,
      timeSlots: [
        {
          day: 6,
          start_time: "",
          end_time: "",
          add: true,
          delete: false,
        },
      ],
    },
    sun: {
      checked: false,
      timeSlots: [
        {
          day: 0,
          start_time: "",
          end_time: "",
          add: true,
          delete: false,
        },
      ],
    },
  });
  const slots = [15, 30, 45, 60];
  const lastTime = new Date();
  const startTime = new Date();
  lastTime.setHours(23);
  lastTime.setMinutes(59);
  lastTime.setSeconds(59);
  startTime.setHours(0);
  startTime.setMinutes(0);
  startTime.setSeconds(0);

  const sloteList = [];

  slots.forEach((slots) => {
    sloteList.push(<option value={slots}>{slots}</option>);
  });

  const selectSlotTime = (event) => {
    setSlotsTime(event.target.value);
  };

  const addTimeSlot = (name) => {
    if (name === "sun") {
      const temp = { ...availableTime };
      for (let i = 0; i < temp.sun.timeSlots.length; i++) {
        temp.sun.timeSlots[i].add = false;
        temp.sun.timeSlots[i].delete = true;
      }
      temp.sun.timeSlots.push({
        day: 0,
        start_time: "",
        end_time: "",
        add: true,
        delete: false,
      });
      setAvailableTime(temp);
    }
    if (name === "mon") {
      const temp = { ...availableTime };
      for (let i = 0; i < temp.mon.timeSlots.length; i++) {
        temp.mon.timeSlots[i].add = false;
        temp.mon.timeSlots[i].delete = true;
      }
      temp.mon.timeSlots.push({
        day: 1,
        start_time: "",
        end_time: "",
        add: true,
        delete: false,
      });
      setAvailableTime(temp);
    }
    if (name === "tue") {
      const temp = { ...availableTime };
      for (let i = 0; i < temp.tue.timeSlots.length; i++) {
        temp.tue.timeSlots[i].add = false;
        temp.tue.timeSlots[i].delete = true;
      }
      temp.tue.timeSlots.push({
        day: 2,
        start_time: "",
        end_time: "",
        add: true,
        delete: false,
      });
      setAvailableTime(temp);
    }
    if (name === "wed") {
      const temp = { ...availableTime };
      for (let i = 0; i < temp.wed.timeSlots.length; i++) {
        temp.wed.timeSlots[i].add = false;
        temp.wed.timeSlots[i].delete = true;
      }
      temp.wed.timeSlots.push({
        day: 3,
        start_time: "",
        end_time: "",
        add: true,
        delete: false,
      });
      setAvailableTime(temp);
    }
    if (name === "thu") {
      const temp = { ...availableTime };
      for (let i = 0; i < temp.thu.timeSlots.length; i++) {
        temp.thu.timeSlots[i].add = false;
        temp.thu.timeSlots[i].delete = true;
      }
      temp.thu.timeSlots.push({
        day: 4,
        start_time: "",
        end_time: "",
        add: true,
        delete: false,
      });
      setAvailableTime(temp);
    }
    if (name === "fri") {
      const temp = { ...availableTime };
      for (let i = 0; i < temp.fri.timeSlots.length; i++) {
        temp.fri.timeSlots[i].add = false;
        temp.fri.timeSlots[i].delete = true;
      }
      temp.fri.timeSlots.push({
        day: 5,
        start_time: "",
        end_time: "",
        add: true,
        delete: false,
      });
      setAvailableTime(temp);
    }
    if (name === "sat") {
      const temp = { ...availableTime };
      for (let i = 0; i < temp.sat.timeSlots.length; i++) {
        temp.sat.timeSlots[i].add = false;
        temp.sat.timeSlots[i].delete = true;
      }
      temp.sat.timeSlots.push({
        day: 6,
        start_time: "",
        end_time: "",
        add: true,
        delete: false,
      });
      setAvailableTime(temp);
    }
  };

  const deleteSlot = (name, i) => {
    if (name === "sun") {
      const temp = { ...availableTime };
      temp.sun.timeSlots.splice(i, 1);
      setAvailableTime(temp);
    }
    if (name === "mon") {
      const temp = { ...availableTime };
      temp.mon.timeSlots.splice(i, 1);
      setAvailableTime(temp);
    }
    if (name === "tue") {
      const temp = { ...availableTime };
      temp.tue.timeSlots.splice(i, 1);
      setAvailableTime(temp);
    }
    if (name === "wed") {
      const temp = { ...availableTime };
      temp.wed.timeSlots.splice(i, 1);
      setAvailableTime(temp);
    }
    if (name === "thu") {
      const temp = { ...availableTime };
      temp.thu.timeSlots.splice(i, 1);
      setAvailableTime(temp);
    }
    if (name === "fri") {
      const temp = { ...availableTime };
      temp.fri.timeSlots.splice(i, 1);
      setAvailableTime(temp);
    }
    if (name === "sat") {
      const temp = { ...availableTime };
      temp.sat.timeSlots.splice(i, 1);
      setAvailableTime(temp);
    }
  };

  const handleCheck = (e) => {
    //debugger;
    const { name, checked } = e.target;

    if (name === "mon") {
      if (!checked) {
        const temp = { ...availableTime };
        temp.mon.timeSlots = [
          {
            day: 1,
            start_time: "",
            end_time: "",
            add: true,
            delete: false,
          },
        ];
        setAvailableTime(temp);
        const tempStartTimeError = { ...startTimeError };
        tempStartTimeError.mon.index = 0;
        tempStartTimeError.mon.message = "";
        setStartTimeError(tempStartTimeError);
        const tempEndTimeError = { ...endTimeError };
        tempEndTimeError.mon.index = 0;
        tempEndTimeError.mon.message = "";
        setEndTimeError(tempEndTimeError);
      }
      setAvailableTime({
        ...availableTime,
        mon: {
          ...availableTime.mon,
          checked: checked,
        },
      });
      setDisableButton(false);
    }
    if (name === "tue") {
      if (!checked) {
        const temp = { ...availableTime };
        temp.tue.timeSlots = [
          {
            day: 2,
            start_time: "",
            end_time: "",
            add: true,
            delete: false,
          },
        ];

        setAvailableTime(temp);
        const tempStartTimeError = { ...startTimeError };
        tempStartTimeError.tue.index = 0;
        tempStartTimeError.tue.message = "";
        setStartTimeError(tempStartTimeError);
        const tempEndTimeError = { ...endTimeError };
        tempEndTimeError.tue.index = 0;
        tempEndTimeError.tue.message = "";
        setEndTimeError(tempEndTimeError);
      }
      setAvailableTime({
        ...availableTime,
        tue: {
          ...availableTime.tue,
          checked: checked,
        },
      });
    }
    if (name === "wed") {
      if (!checked) {
        const temp = { ...availableTime };
        temp.wed.timeSlots = [
          {
            day: 3,
            start_time: "",
            end_time: "",
            add: true,
            delete: false,
          },
        ];
        setAvailableTime(temp);
        const tempStartTimeError = { ...startTimeError };
        tempStartTimeError.wed.index = 0;
        tempStartTimeError.wed.message = "";
        setStartTimeError(tempStartTimeError);
        const tempEndTimeError = { ...endTimeError };
        tempEndTimeError.wed.index = 0;
        tempEndTimeError.wed.message = "";
        setEndTimeError(tempEndTimeError);
      }
      setAvailableTime({
        ...availableTime,
        wed: {
          ...availableTime.wed,
          checked: checked,
        },
      });
    }
    if (name === "thu") {
      if (!checked) {
        const temp = { ...availableTime };
        temp.thu.timeSlots = [
          {
            day: 4,
            start_time: "",
            end_time: "",
            add: true,
            delete: false,
          },
        ];
        setAvailableTime(temp);
        const tempStartTimeError = { ...startTimeError };
        tempStartTimeError.thu.index = 0;
        tempStartTimeError.thu.message = "";
        setStartTimeError(tempStartTimeError);
        const tempEndTimeError = { ...endTimeError };
        tempEndTimeError.thu.index = 0;
        tempEndTimeError.thu.message = "";
        setEndTimeError(tempEndTimeError);
      }
      setAvailableTime({
        ...availableTime,
        thu: {
          ...availableTime.thu,
          checked: checked,
        },
      });
    }
    if (name === "fri") {
      if (!checked) {
        const temp = { ...availableTime };
        temp.wed.timeSlots = [
          {
            day: 5,
            start_time: "",
            end_time: "",
            add: true,
            delete: false,
          },
        ];
        setAvailableTime(temp);
        const tempStartTimeError = { ...startTimeError };
        tempStartTimeError.fri.index = 0;
        tempStartTimeError.fri.message = "";
        setStartTimeError(tempStartTimeError);
        const tempEndTimeError = { ...endTimeError };
        tempEndTimeError.fri.index = 0;
        tempEndTimeError.fri.message = "";
        setEndTimeError(tempEndTimeError);
      }
      setAvailableTime({
        ...availableTime,
        fri: {
          ...availableTime.fri,
          checked: checked,
        },
      });
    }
    if (name === "sat") {
      if (!checked) {
        const temp = { ...availableTime };
        temp.sat.timeSlots = [
          {
            day: 6,
            start_time: "",
            end_time: "",
            add: true,
            delete: false,
          },
        ];
        setAvailableTime(temp);
        const tempStartTimeError = { ...startTimeError };
        tempStartTimeError.sat.index = 0;
        tempStartTimeError.sat.message = "";
        setStartTimeError(tempStartTimeError);
        const tempEndTimeError = { ...endTimeError };
        tempEndTimeError.sat.index = 0;
        tempEndTimeError.sat.message = "";
        setEndTimeError(tempEndTimeError);
      }
      setAvailableTime({
        ...availableTime,
        sat: {
          ...availableTime.sat,
          checked: checked,
        },
      });
    }
    if (name === "sun") {
      if (!checked) {
        const temp = { ...availableTime };
        temp.sun.timeSlots = [
          {
            day: 0,
            start_time: "",
            end_time: "",
            add: true,
            delete: false,
          },
        ];
        setAvailableTime(temp);
        const tempStartTimeError = { ...startTimeError };
        tempStartTimeError.sun.index = 0;
        tempStartTimeError.sun.message = "";
        setStartTimeError(tempStartTimeError);
        const tempEndTimeError = { ...endTimeError };
        tempEndTimeError.sun.index = 0;
        tempEndTimeError.sun.message = "";
        setEndTimeError(tempEndTimeError);
      }
      setAvailableTime({
        ...availableTime,
        sun: {
          ...availableTime.sun,
          checked: checked,
        },
      });
    }
  };
  const handelStartTime = (time, i, name) => {
    if (name === "sun") {
      const temp = { ...availableTime };
      temp.sun.timeSlots[i].start_time = time;
      temp.sun.timeSlots[i].end_time = "";
      for (let j = i + 1; j < temp.sun.timeSlots.length; j++) {
        temp.sun.timeSlots[j].start_time = "";
        temp.sun.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "mon") {
      const temp = { ...availableTime };
      temp.mon.timeSlots[i].start_time = time;
      temp.mon.timeSlots[i].end_time = "";
      for (let j = i + 1; j < temp.mon.timeSlots.length; j++) {
        temp.mon.timeSlots[j].start_time = "";
        temp.mon.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "tue") {
      const temp = { ...availableTime };
      temp.tue.timeSlots[i].start_time = time;
      temp.tue.timeSlots[i].end_time = "";
      for (let j = i + 1; j < temp.tue.timeSlots.length; j++) {
        temp.tue.timeSlots[j].start_time = "";
        temp.tue.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "wed") {
      const temp = { ...availableTime };
      temp.wed.timeSlots[i].start_time = time;
      temp.wed.timeSlots[i].end_time = "";
      for (let j = i + 1; j < temp.wed.timeSlots.length; j++) {
        temp.wed.timeSlots[j].start_time = "";
        temp.wed.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "thu") {
      const temp = { ...availableTime };
      temp.thu.timeSlots[i].start_time = time;
      temp.thu.timeSlots[i].end_time = "";
      for (let j = i + 1; j < temp.thu.timeSlots.length; j++) {
        temp.thu.timeSlots[j].start_time = "";
        temp.thu.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "fri") {
      const temp = { ...availableTime };
      temp.fri.timeSlots[i].start_time = time;
      temp.fri.timeSlots[i].end_time = "";
      for (let j = i + 1; j < temp.fri.timeSlots.length; j++) {
        temp.fri.timeSlots[j].start_time = "";
        temp.fri.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "sat") {
      const temp = { ...availableTime };
      temp.sat.timeSlots[i].start_time = time;
      temp.sat.timeSlots[i].end_time = "";
      for (let j = i + 1; j < temp.sat.timeSlots.length; j++) {
        temp.sat.timeSlots[j].start_time = "";
        temp.sat.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
  };
  const handelEndTime = (time, i, name) => {
    if (name === "sun") {
      const temp = { ...availableTime };
      temp.sun.timeSlots[i].end_time = time;
      for (let j = i + 1; j < temp.sun.timeSlots.length; j++) {
        temp.sun.timeSlots[j].start_time = "";
        temp.sun.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "mon") {
      const temp = { ...availableTime };
      temp.mon.timeSlots[i].end_time = time;
      for (let j = i + 1; j < temp.mon.timeSlots.length; j++) {
        temp.mon.timeSlots[j].start_time = "";
        temp.mon.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "tue") {
      const temp = { ...availableTime };
      temp.tue.timeSlots[i].end_time = time;
      for (let j = i + 1; j < temp.tue.timeSlots.length; j++) {
        temp.tue.timeSlots[j].start_time = "";
        temp.tue.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "wed") {
      const temp = { ...availableTime };
      temp.wed.timeSlots[i].end_time = time;
      for (let j = i + 1; j < temp.wed.timeSlots.length; j++) {
        temp.wed.timeSlots[j].start_time = "";
        temp.wed.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "thu") {
      const temp = { ...availableTime };
      temp.thu.timeSlots[i].end_time = time;
      for (let j = i + 1; j < temp.thu.timeSlots.length; j++) {
        temp.thu.timeSlots[j].start_time = "";
        temp.thu.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "fri") {
      const temp = { ...availableTime };
      temp.fri.timeSlots[i].end_time = time;
      for (let j = i + 1; j < temp.fri.timeSlots.length; j++) {
        temp.fri.timeSlots[j].start_time = "";
        temp.fri.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
    if (name === "sat") {
      const temp = { ...availableTime };
      temp.sat.timeSlots[i].end_time = time;
      for (let j = i + 1; j < temp.sat.timeSlots.length; j++) {
        temp.sat.timeSlots[j].start_time = "";
        temp.sat.timeSlots[j].end_time = "";
      }
      setAvailableTime(temp);
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const checkErrorInStartTime = (errors) => {
    let count = 0;
    for (let i = 0; i < errors.length; i++) {
      if (errors[i].message === "Required") {
        count = count + 1;
      }
    }
    return count === 0 ? true : false;
  };

  const handelSubmit = async () => {
    if (!startDate) {
      setStartDateError("Required");
    }
    if (!endDate) {
      setEndDateError("Required");
    }
    const values = Object.values(availableTime);
    const checkedTrue = values.filter((item) => {
      return item.checked === true;
    });
    if (checkedTrue.length > 0) {
      for (let i = 0; i < checkedTrue.length; i++) {
        for (let j = 0; j < checkedTrue[i]?.timeSlots.length; j++) {
          if (!checkedTrue[i].timeSlots[j].start_time) {
            if (checkedTrue[i].timeSlots[j].day === 0) {
              const tempStartTimeError = { ...startTimeError };
              tempStartTimeError.sun.index = j;
              tempStartTimeError.sun.message = "Required";
              setStartTimeError(tempStartTimeError);
            }
            if (checkedTrue[i].timeSlots[j].day === 1) {
              const temp = { ...startTimeError };
              temp.mon.index = j;
              temp.mon.message = "Required";
              setStartTimeError(temp);
            }
            if (checkedTrue[i].timeSlots[j].day === 2) {
              const temp = { ...startTimeError };
              temp.tue.index = j;
              temp.tue.message = "Required";
              setStartTimeError(temp);
            }
            if (checkedTrue[i].timeSlots[j].day === 3) {
              const temp = { ...startTimeError };
              temp.wed.index = j;
              temp.wed.message = "Required";
              setStartTimeError(temp);
            }
            if (checkedTrue[i].timeSlots[j].day === 4) {
              const temp = { ...startTimeError };
              temp.thu.index = j;
              temp.thu.message = "Required";
              setStartTimeError(temp);
            }
            if (checkedTrue[i].timeSlots[j].day === 5) {
              const temp = { ...startTimeError };
              temp.fri.index = j;
              temp.fri.message = "Required";
              setStartTimeError(temp);
            }
            if (checkedTrue[i].timeSlots[j].day === 6) {
              const temp = { ...startTimeError };
              temp.sat.index = j;
              temp.sat.message = "Required";
              setStartTimeError(temp);
            }
          }
          if (!checkedTrue[i].timeSlots[j].end_time) {
            if (checkedTrue[i].timeSlots[j].day === 0) {
              const tempEndTimeError = { ...endTimeError };
              tempEndTimeError.sun.index = j;
              tempEndTimeError.sun.message = "Required";
              setEndTimeError(tempEndTimeError);
            }
            if (checkedTrue[i].timeSlots[j].day === 1) {
              const temp = { ...endTimeError };
              temp.mon.index = j;
              temp.mon.message = "Required";
              setEndTimeError(temp);
            }
            if (checkedTrue[i].timeSlots[j].day === 2) {
              const temp = { ...endTimeError };
              temp.tue.index = j;
              temp.tue.message = "Required";
              setEndTimeError(temp);
            }
            if (checkedTrue[i].timeSlots[j].day === 3) {
              const temp = { ...endTimeError };
              temp.wed.index = j;
              temp.wed.message = "Required";
              setEndTimeError(temp);
            }
            if (checkedTrue[i].timeSlots[j].day === 4) {
              const temp = { ...endTimeError };
              temp.thu.index = j;
              temp.thu.message = "Required";
              setEndTimeError(temp);
            }
            if (checkedTrue[i].timeSlots[j].day === 5) {
              const temp = { ...endTimeError };
              temp.fri.index = j;
              temp.fri.message = "Required";
              setEndTimeError(temp);
            }
            if (checkedTrue[i].timeSlots[j].day === 6) {
              const temp = { ...endTimeError };
              temp.sat.index = j;
              temp.sat.message = "Required";
              setEndTimeError(temp);
            }
          }
        }
      }
    }
    const startTimeErrorValue = Object.values(startTimeError);
    const endTimeErrorValue = Object.values(endTimeError);

    const errorInStartTime = checkErrorInStartTime(startTimeErrorValue);
    const errorInEndTime = checkErrorInStartTime(endTimeErrorValue);

    if (startDate && endDate && errorInStartTime && errorInEndTime) {
      const values = Object.values(availableTime);
      let data = {
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
        availabile_time: [],
      };
      values.map((item, index) => {
        if (values[index]?.checked) {
          if (item.timeSlots.length > 0) {
            for (let i = 0; i < item.timeSlots.length; i++) {
              if (item.timeSlots[i].start_time && item.timeSlots[i].end_time) {
                const start_time = new Date(item.timeSlots[i].start_time);
                const end_time = new Date(item.timeSlots[i].end_time);
                data.availabile_time.push({
                  day: item.timeSlots[i].day,
                  start_time: start_time.toTimeString("HH:MM").split(" ")[0],
                  end_time: end_time.toTimeString("HH:MM").split(" ")[0],
                });
              }
            }
          }
        }
      });
      if (data.availabile_time.length > 0) {
        await addSlots(data)
          .then((res) => {
            if (res?.data?.status) {
              const temp = { ...availableTime };
              const values = Object.values(temp);
              values.map((value, index) => {
                value.checked = false;
                value.timeSlots = [
                  {
                    day: value.timeSlots[0].day,
                    start_time: "",
                    end_time: "",
                    add: true,
                    delete: false,
                  },
                ];
              });
              setAvailableTime(temp);
              const tempStartTimeError = { ...startTimeError };
              const tempEndTimeError = { ...endTimeError };
              const startTimeErrorValue = Object.values(tempStartTimeError);
              const endTimeErrorValue = Object.values(tempEndTimeError);
              startTimeErrorValue.map((value) => {
                value.index = 0;
                value.message = "";
              });
              endTimeErrorValue.map((value) => {
                value.index = 0;
                value.message = "";
              });
              setEndTimeError(tempEndTimeError);
              setEndTimeError(tempStartTimeError);
              setSlotsTime(5);
              setStartDate("");
              setEndDate("");
              setStartDateError("");
              setEndDateError("");
              dispatch(closeModal());
            }
          })
          .catch((e) => {
            return e;
          });
      }
    }
  };

  useEffect(() => {
    const values = Object.values(availableTime);
    const checkedTrue = values.filter((item) => {
      return item.checked === true;
    });
    checkedTrue?.length > 0 ? setDisableButton(false) : setDisableButton(true);
  }, [handleCheck]);

  return (
    <Modal
      className="edit-slot-modal"
      show={state}
      onHide={() => {
        const temp = { ...availableTime };
        const values = Object.values(temp);
        values.map((value, index) => {
          value.checked = false;
          value.timeSlots = [
            {
              day: value.timeSlots[0].day,
              start_time: "",
              end_time: "",
              add: true,
              delete: false,
            },
          ];
        });
        setAvailableTime(temp);
        const tempStartTimeError = { ...startTimeError };
        const tempEndTimeError = { ...endTimeError };
        const startTimeErrorValue = Object.values(tempStartTimeError);
        const endTimeErrorValue = Object.values(tempEndTimeError);
        startTimeErrorValue.map((value) => {
          value.index = 0;
          value.message = "";
        });
        endTimeErrorValue.map((value) => {
          value.index = 0;
          value.message = "";
        });
        setEndTimeError(tempEndTimeError);
        setStartTimeError(tempStartTimeError);

        setSlotsTime(5);
        setStartDate("");
        setEndDate("");
        setDisableButton(true);
        setStartDateError("");
        setEndDateError("");
        dispatch(closeModal());
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Slots</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="App">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 pb-3">
                Change Start date:
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setStartDateError("");
                    setEndDate("");
                  }}
                  selectsStart
                  placeholderText="Start date"
                  startDate={startDate}
                  endDate={endDate}
                />
                {startDateError && <div style={{ color: "red" }}>Required</div>}
              </div>
              <div className="col-lg-4 pb-3">
                Change End date:
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDateError("");
                    setEndDate(date);
                  }}
                  selectsEnd
                  placeholderText="End date"
                  startDate={startDate}
                  endDate={endDate}
                  minDate={moment(startDate).add(1, "d").toDate()}
                />
                {endDateError && <div style={{ color: "red" }}>Required</div>}
              </div>
              <div className="col-lg-4">
                Slot time:
                <select className="slots-time" onChange={selectSlotTime}>
                  <option value={5}>Minutes</option>
                  {sloteList}
                </select>
              </div>
            </div>
            <table className="table" border="1">
              <thead>
                <tr>
                  <th scope="col">Day</th>
                  <th scope="col" colSpan="2">
                    Start time(HH:MM)
                  </th>
                  <th scope="col" colSpan="2">
                    End time(HH:MM)
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <div className="col-sm-2 float-left">
                      <input
                        type="checkbox"
                        name="sun"
                        onChange={handleCheck}
                      />
                    </div>
                    <div className="col-sm-10 text-left">Sunday</div>
                  </th>
                  <td colSpan="5">
                    {availableTime.sun.checked && (
                      <ul className="repeat-slots">
                        {availableTime.sun.timeSlots.map((item, i) => {
                          return (
                            <li>
                              <div
                                className={`add-slot-row ${
                                  availableTime.sun.timeSlots[i].delete &&
                                  "add-slot-row-hide"
                                }`}
                              >
                                <div>
                                  <DatePicker
                                    selected={
                                      availableTime.sun.timeSlots[i]?.start_time
                                    }
                                    placeholderText="Start time"
                                    onChange={(time) => {
                                      const temp = { ...startTimeError };
                                      temp.sun.message = "";
                                      setStartTimeError(temp);
                                      handelStartTime(time, i, "sun");
                                    }}
                                    readOnly={
                                      availableTime.sun.timeSlots[i].delete
                                    }
                                    value={
                                      availableTime.sun.timeSlots[i]?.start_time
                                    }
                                    minTime={
                                      i === 0
                                        ? startTime
                                        : availableTime.sun?.timeSlots[i - 1]
                                            ?.end_time
                                    }
                                    maxTime={lastTime}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    timeCaption="Time"
                                    dateFormat="h:mm a"
                                  />
                                  {startTimeError.sun.index === i && (
                                    <div style={{ color: "red" }}>
                                      {startTimeError.sun.message}
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <DatePicker
                                    selected={
                                      availableTime.sun.timeSlots[i]?.end_time
                                    }
                                    onChange={(time) => {
                                      const temp = { ...endTimeError };
                                      temp.sun.message = "";
                                      setEndTimeError(temp);
                                      handelEndTime(time, i, "sun");
                                    }}
                                    readOnly={
                                      availableTime.sun.timeSlots[i].delete
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    minTime={
                                      moment(
                                        availableTime.sun.timeSlots[i]
                                          ?.start_time
                                      )
                                        .add(slotsTime, "m")
                                        .toDate() > lastTime
                                        ? lastTime
                                        : moment(
                                            availableTime.sun.timeSlots[i]
                                              ?.start_time
                                          )
                                            .add(slotsTime, "m")
                                            .toDate()
                                    }
                                    maxTime={lastTime}
                                    placeholderText="End time"
                                    timeCaption="Time"
                                    dateFormat="h:mm a"
                                  />
                                  {endTimeError.sun.index === i && (
                                    <div style={{ color: "red" }}>
                                      {endTimeError.sun.message}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div>
                                {availableTime.sun.timeSlots[i].add && (
                                  <i
                                    className="bi bi-plus-lg"
                                    onClick={() => {
                                      if (
                                        availableTime.sun.timeSlots[i]
                                          .start_time &&
                                        availableTime.sun.timeSlots[i].end_time
                                      ) {
                                        addTimeSlot("sun");
                                      }
                                    }}
                                  />
                                )}
                                {availableTime.sun.timeSlots[i].delete && (
                                  <i
                                    className="bi bi-trash"
                                    onClick={() => {
                                      deleteSlot("sun", i);
                                    }}
                                  />
                                )}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div className="col-sm-2 float-left">
                      <input type="checkbox" name="mon" onClick={handleCheck} />
                    </div>
                    <div className="col-sm-10 text-left">Monday</div>
                  </th>
                  <td colSpan="5">
                    {availableTime.mon.checked && (
                      <ul className="repeat-slots">
                        {availableTime.mon.checked && (
                          <ul className="repeat-slots">
                            {availableTime.mon.timeSlots.map((item, i) => {
                              return (
                                <li>
                                  <div
                                    className={`add-slot-row ${
                                      availableTime.mon.timeSlots[i].delete &&
                                      "add-slot-row-hide"
                                    }`}
                                  >
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.mon.timeSlots[i]
                                            ?.start_time
                                        }
                                        placeholderText="Start time"
                                        onChange={(time) => {
                                          const temp = { ...startTimeError };

                                          temp.mon.message = "";
                                          setStartTimeError(temp);
                                          handelStartTime(time, i, "mon");
                                        }}
                                        readOnly={
                                          availableTime.mon.timeSlots[i].delete
                                        }
                                        value={
                                          availableTime.mon.timeSlots[i]
                                            ?.start_time
                                        }
                                        minTime={
                                          i === 0
                                            ? startTime
                                            : availableTime.mon?.timeSlots[
                                                i - 1
                                              ]?.end_time
                                        }
                                        maxTime={lastTime}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {startTimeError.mon.index === i && (
                                        <div style={{ color: "red" }}>
                                          {startTimeError.mon.message}
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.mon.timeSlots[i]
                                            ?.end_time
                                        }
                                        onChange={(time) => {
                                          const temp = { ...endTimeError };
                                          temp.mon.message = "";
                                          setEndTimeError(temp);
                                          handelEndTime(time, i, "mon");
                                        }}
                                        readOnly={
                                          availableTime.mon.timeSlots[i].delete
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        minTime={
                                          moment(
                                            availableTime.mon.timeSlots[i]
                                              ?.start_time
                                          )
                                            .add(slotsTime, "m")
                                            .toDate() > lastTime
                                            ? lastTime
                                            : moment(
                                                availableTime.mon.timeSlots[i]
                                                  ?.start_time
                                              )
                                                .add(slotsTime, "m")
                                                .toDate()
                                        }
                                        maxTime={lastTime}
                                        placeholderText="End time"
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {endTimeError.mon.index === i && (
                                        <div style={{ color: "red" }}>
                                          {endTimeError.mon.message}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    {availableTime.mon.timeSlots[i].add && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={() => {
                                          if (
                                            availableTime.mon.timeSlots[i]
                                              .start_time &&
                                            availableTime.mon.timeSlots[i]
                                              .end_time
                                          ) {
                                            addTimeSlot("mon");
                                          }
                                        }}
                                      />
                                    )}
                                    {availableTime.mon.timeSlots[i].delete && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() => {
                                          deleteSlot("mon", i);
                                        }}
                                      />
                                    )}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </ul>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div className="col-sm-2 float-left">
                      <input type="checkbox" name="tue" onClick={handleCheck} />
                    </div>
                    <div className="col-sm-10 text-left">Tuesday</div>
                  </th>
                  <td colSpan="5">
                    {availableTime.tue.checked && (
                      <ul className="repeat-slots">
                        {availableTime.tue.checked && (
                          <ul className="repeat-slots">
                            {availableTime.tue.timeSlots.map((item, i) => {
                              return (
                                <li>
                                  {" "}
                                  <div
                                    className={`add-slot-row ${
                                      availableTime.tue.timeSlots[i].delete &&
                                      "add-slot-row-hide"
                                    }`}
                                  >
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.tue.timeSlots[i]
                                            ?.start_time
                                        }
                                        placeholderText="Start time"
                                        onChange={(time) => {
                                          const temp = { ...startTimeError };

                                          temp.tue.message = "";
                                          setStartTimeError(temp);
                                          handelStartTime(time, i, "tue");
                                        }}
                                        readOnly={
                                          availableTime.tue.timeSlots[i].delete
                                        }
                                        value={
                                          availableTime.tue.timeSlots[i]
                                            ?.start_time
                                        }
                                        minTime={
                                          i === 0
                                            ? startTime
                                            : availableTime.tue?.timeSlots[
                                                i - 1
                                              ]?.end_time
                                        }
                                        maxTime={lastTime}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {startTimeError.tue.index === i && (
                                        <div style={{ color: "red" }}>
                                          {startTimeError.tue.message}
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.tue.timeSlots[i]
                                            ?.end_time
                                        }
                                        onChange={(time) => {
                                          const temp = { ...endTimeError };
                                          temp.tue.message = "";
                                          setEndTimeError(temp);
                                          handelEndTime(time, i, "tue");
                                        }}
                                        readOnly={
                                          availableTime.tue.timeSlots[i].delete
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        minTime={
                                          moment(
                                            availableTime.tue.timeSlots[i]
                                              ?.start_time
                                          )
                                            .add(slotsTime, "m")
                                            .toDate() > lastTime
                                            ? lastTime
                                            : moment(
                                                availableTime.tue.timeSlots[i]
                                                  ?.start_time
                                              )
                                                .add(slotsTime, "m")
                                                .toDate()
                                        }
                                        maxTime={lastTime}
                                        placeholderText="End time"
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {endTimeError.tue.index === i && (
                                        <div style={{ color: "red" }}>
                                          {endTimeError.tue.message}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    {availableTime.tue.timeSlots[i].add && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={() => {
                                          if (
                                            availableTime.tue.timeSlots[i]
                                              .start_time &&
                                            availableTime.tue.timeSlots[i]
                                              .end_time
                                          ) {
                                            addTimeSlot("tue");
                                          }
                                        }}
                                      />
                                    )}
                                    {availableTime.tue.timeSlots[i].delete && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() => {
                                          deleteSlot("tue", i);
                                        }}
                                      />
                                    )}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </ul>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div className="col-sm-2 float-left">
                      <input type="checkbox" name="wed" onClick={handleCheck} />
                    </div>
                    <div className="col-sm-10 text-left auto-width">
                      Wednesday
                    </div>
                  </th>
                  <td colSpan="5">
                    {availableTime.wed.checked && (
                      <ul className="repeat-slots">
                        {availableTime.wed.checked && (
                          <ul className="repeat-slots">
                            {availableTime.wed.timeSlots.map((item, i) => {
                              return (
                                <li>
                                  <div
                                    className={`add-slot-row ${
                                      availableTime.wed.timeSlots[i].delete &&
                                      "add-slot-row-hide"
                                    }`}
                                  >
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.wed.timeSlots[i]
                                            ?.start_time
                                        }
                                        placeholderText="Start time"
                                        onChange={(time) => {
                                          const temp = { ...startTimeError };

                                          temp.wed.message = "";
                                          setStartTimeError(temp);
                                          handelStartTime(time, i, "wed");
                                        }}
                                        readOnly={
                                          availableTime.wed.timeSlots[i].delete
                                        }
                                        value={
                                          availableTime.wed.timeSlots[i]
                                            ?.start_time
                                        }
                                        minTime={
                                          i === 0
                                            ? startTime
                                            : availableTime.wed?.timeSlots[
                                                i - 1
                                              ]?.end_time
                                        }
                                        maxTime={lastTime}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {startTimeError.wed.index === i && (
                                        <div style={{ color: "red" }}>
                                          {startTimeError.wed.message}
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.wed.timeSlots[i]
                                            ?.end_time
                                        }
                                        onChange={(time) => {
                                          const temp = { ...endTimeError };
                                          temp.wed.message = "";
                                          setEndTimeError(temp);
                                          handelEndTime(time, i, "wed");
                                        }}
                                        readOnly={
                                          availableTime.wed.timeSlots[i].delete
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        minTime={
                                          moment(
                                            availableTime.wed.timeSlots[i]
                                              ?.start_time
                                          )
                                            .add(slotsTime, "m")
                                            .toDate() > lastTime
                                            ? lastTime
                                            : moment(
                                                availableTime.wed.timeSlots[i]
                                                  ?.start_time
                                              )
                                                .add(slotsTime, "m")
                                                .toDate()
                                        }
                                        maxTime={lastTime}
                                        placeholderText="End time"
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {endTimeError.wed.index === i && (
                                        <div style={{ color: "red" }}>
                                          {endTimeError.wed.message}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    {availableTime.wed.timeSlots[i].add && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={() => {
                                          if (
                                            availableTime.wed.timeSlots[i]
                                              .start_time &&
                                            availableTime.wed.timeSlots[i]
                                              .end_time
                                          ) {
                                            addTimeSlot("wed");
                                          }
                                        }}
                                      />
                                    )}
                                    {availableTime.wed.timeSlots[i].delete && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() => {
                                          deleteSlot("wed", i);
                                        }}
                                      />
                                    )}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </ul>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div className="col-sm-2 float-left">
                      <input type="checkbox" name="thu" onClick={handleCheck} />
                    </div>
                    <div className="col-sm-10 text-left">Thursday</div>
                  </th>
                  <td colSpan="5">
                    {availableTime.thu.checked && (
                      <ul className="repeat-slots">
                        {availableTime.thu.checked && (
                          <ul className="repeat-slots">
                            {availableTime.thu.timeSlots.map((item, i) => {
                              return (
                                <li>
                                  <div
                                    className={`add-slot-row ${
                                      availableTime.thu.timeSlots[i].delete &&
                                      "add-slot-row-hide"
                                    }`}
                                  >
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.thu.timeSlots[i]
                                            ?.start_time
                                        }
                                        placeholderText="Start time"
                                        onChange={(time) => {
                                          const temp = { ...startTimeError };

                                          temp.thu.message = "";
                                          setStartTimeError(temp);
                                          handelStartTime(time, i, "thu");
                                        }}
                                        readOnly={
                                          availableTime.thu.timeSlots[i].delete
                                        }
                                        value={
                                          availableTime.thu.timeSlots[i]
                                            ?.start_time
                                        }
                                        minTime={
                                          i === 0
                                            ? startTime
                                            : availableTime.thu?.timeSlots[
                                                i - 1
                                              ]?.end_time
                                        }
                                        maxTime={lastTime}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {startTimeError.thu.index === i && (
                                        <div style={{ color: "red" }}>
                                          {startTimeError.thu.message}
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.thu.timeSlots[i]
                                            ?.end_time
                                        }
                                        onChange={(time) => {
                                          const temp = { ...endTimeError };
                                          temp.thu.message = "";
                                          setEndTimeError(temp);
                                          handelEndTime(time, i, "thu");
                                        }}
                                        readOnly={
                                          availableTime.thu.timeSlots[i].delete
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        minTime={
                                          moment(
                                            availableTime.thu.timeSlots[i]
                                              ?.start_time
                                          )
                                            .add(slotsTime, "m")
                                            .toDate() > lastTime
                                            ? lastTime
                                            : moment(
                                                availableTime.thu.timeSlots[i]
                                                  ?.start_time
                                              )
                                                .add(slotsTime, "m")
                                                .toDate()
                                        }
                                        maxTime={lastTime}
                                        placeholderText="End time"
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {endTimeError.thu.index === i && (
                                        <div style={{ color: "red" }}>
                                          {endTimeError.thu.message}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    {availableTime.thu.timeSlots[i].add && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={() => {
                                          if (
                                            availableTime.thu.timeSlots[i]
                                              .start_time &&
                                            availableTime.thu.timeSlots[i]
                                              .end_time
                                          ) {
                                            addTimeSlot("thu");
                                          }
                                        }}
                                      />
                                    )}
                                    {availableTime.thu.timeSlots[i].delete && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() => {
                                          deleteSlot("thu", i);
                                        }}
                                      />
                                    )}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </ul>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div className="col-sm-2 float-left">
                      <input
                        type="checkbox"
                        name="fri"
                        onChange={handleCheck}
                      />
                    </div>
                    <div className="col-sm-10 text-left">Friday</div>
                  </th>
                  <td colSpan="5">
                    {availableTime.fri.checked && (
                      <ul className="repeat-slots">
                        {availableTime.fri.checked && (
                          <ul className="repeat-slots">
                            {availableTime.fri.timeSlots.map((item, i) => {
                              return (
                                <li>
                                  <div
                                    className={`add-slot-row ${
                                      availableTime.fri.timeSlots[i].delete &&
                                      "add-slot-row-hide"
                                    }`}
                                  >
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.fri.timeSlots[i]
                                            ?.start_time
                                        }
                                        placeholderText="Start time"
                                        onChange={(time) => {
                                          const temp = { ...startTimeError };

                                          temp.fri.message = "";
                                          setStartTimeError(temp);
                                          handelStartTime(time, i, "fri");
                                        }}
                                        readOnly={
                                          availableTime.fri.timeSlots[i].delete
                                        }
                                        value={
                                          availableTime.fri.timeSlots[i]
                                            ?.start_time
                                        }
                                        minTime={
                                          i === 0
                                            ? startTime
                                            : availableTime.fri?.timeSlots[
                                                i - 1
                                              ]?.end_time
                                        }
                                        maxTime={lastTime}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {startTimeError.fri.index === i && (
                                        <div style={{ color: "red" }}>
                                          {startTimeError.fri.message}
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.fri.timeSlots[i]
                                            ?.end_time
                                        }
                                        onChange={(time) => {
                                          const temp = { ...endTimeError };
                                          temp.fri.message = "";
                                          setEndTimeError(temp);
                                          handelEndTime(time, i, "fri");
                                        }}
                                        readOnly={
                                          availableTime.fri.timeSlots[i].delete
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        minTime={
                                          moment(
                                            availableTime.fri.timeSlots[i]
                                              ?.start_time
                                          )
                                            .add(slotsTime, "m")
                                            .toDate() > lastTime
                                            ? lastTime
                                            : moment(
                                                availableTime.fri.timeSlots[i]
                                                  ?.start_time
                                              )
                                                .add(slotsTime, "m")
                                                .toDate()
                                        }
                                        maxTime={lastTime}
                                        placeholderText="End time"
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {endTimeError.fri.index === i && (
                                        <div style={{ color: "red" }}>
                                          {endTimeError.fri.message}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    {availableTime.fri.timeSlots[i].add && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={() => {
                                          if (
                                            availableTime.fri.timeSlots[i]
                                              .start_time &&
                                            availableTime.fri.timeSlots[i]
                                              .end_time
                                          ) {
                                            addTimeSlot("fri");
                                          }
                                        }}
                                      />
                                    )}
                                    {availableTime.fri.timeSlots[i].delete && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() => {
                                          deleteSlot("fri", i);
                                        }}
                                      />
                                    )}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </ul>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div className="col-sm-2 float-left">
                      <input type="checkbox" name="sat" onClick={handleCheck} />
                    </div>
                    <div className="col-sm-10 text-left">Saturday</div>
                  </th>
                  <td colSpan="5">
                    {availableTime.sat.checked && (
                      <ul className="repeat-slots">
                        {availableTime.sat.checked && (
                          <ul className="repeat-slots">
                            {availableTime.sat.timeSlots.map((item, i) => {
                              return (
                                <li>
                                  <div
                                    className={`add-slot-row ${
                                      availableTime.sat.timeSlots[i].delete &&
                                      "add-slot-row-hide"
                                    }`}
                                  >
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.sat.timeSlots[i]
                                            ?.start_time
                                        }
                                        placeholderText="Start time"
                                        onChange={(time) => {
                                          const temp = { ...startTimeError };

                                          temp.sat.message = "";
                                          setStartTimeError(temp);
                                          handelStartTime(time, i, "sat");
                                        }}
                                        readOnly={
                                          availableTime.sat.timeSlots[i].delete
                                        }
                                        value={
                                          availableTime.sat.timeSlots[i]
                                            ?.start_time
                                        }
                                        minTime={
                                          i === 0
                                            ? startTime
                                            : availableTime.sat?.timeSlots[
                                                i - 1
                                              ]?.end_time
                                        }
                                        maxTime={lastTime}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {startTimeError.sat.index === i && (
                                        <div style={{ color: "red" }}>
                                          {startTimeError.sat.message}
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <DatePicker
                                        selected={
                                          availableTime.sat.timeSlots[i]
                                            ?.end_time
                                        }
                                        onChange={(time) => {
                                          const temp = { ...endTimeError };
                                          temp.sat.message = "";
                                          setEndTimeError(temp);
                                          handelEndTime(time, i, "sat");
                                        }}
                                        readOnly={
                                          availableTime.sat.timeSlots[i].delete
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        minTime={
                                          moment(
                                            availableTime.sat.timeSlots[i]
                                              ?.start_time
                                          )
                                            .add(slotsTime, "m")
                                            .toDate() > lastTime
                                            ? lastTime
                                            : moment(
                                                availableTime.sat.timeSlots[i]
                                                  ?.start_time
                                              )
                                                .add(slotsTime, "m")
                                                .toDate()
                                        }
                                        maxTime={lastTime}
                                        placeholderText="End time"
                                        timeCaption="Time"
                                        dateFormat="h:mm a"
                                      />
                                      {endTimeError.sat.index === i && (
                                        <div style={{ color: "red" }}>
                                          {endTimeError.sat.message}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    {availableTime.sat.timeSlots[i].add && (
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={() => {
                                          if (
                                            availableTime.sat.timeSlots[i]
                                              .start_time &&
                                            availableTime.sat.timeSlots[i]
                                              .end_time
                                          ) {
                                            addTimeSlot("sat");
                                          }
                                        }}
                                      />
                                    )}
                                    {availableTime.sat.timeSlots[i].delete && (
                                      <i
                                        className="bi bi-trash"
                                        onClick={() => {
                                          deleteSlot("sat", i);
                                        }}
                                      />
                                    )}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </ul>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-4 float-right">
          <Button
            disabled={disableButton}
            onClick={() => {
              handelSubmit();
            }}
          >
            Confirm Time
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EditBookingSlotsModal;
