import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";

import "./profile.css";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import Step6 from "./steps/Step6";
import Step7 from "./steps/Step7";
import Verification from "./Verification";
import { getTutor } from "../../../services/tutors";
import { errorNotify } from "../../../ui/toasters";
import { useDispatch, useSelector } from "react-redux";
import { storeUser } from "../../../features/storeUser/userSlice";

function Profile(props) {
  const [user, setUser] = useState();
  const stepState = useSelector((state) => state.steps);
  const dispatch = useDispatch();
  // steps
  const [steps, setSteps] = useState({
    step1: { value: true, display: "show" },
    step2: { value: stepState.step1 ? true : false, display: "none" },
    step3: { value: stepState.step2 ? true : false, display: "none" },
    step4: { value: stepState.step3 ? true : false, display: "none" },
    step5: { value: stepState.step4 ? true : false, display: "none" },
    step6: { value: stepState.step5 ? true : false, display: "none" },
    step7: { value: stepState.step6 ? true : false, display: "none" },
    verifcation: { value: false, display: "none" },
  });

  // get user details
  useEffect(() => {
    getTutor()
      .then((res) => {
        setUser(res.data.data);
        dispatch(storeUser(res.data.data));
      })
      .catch((err) => errorNotify("Something went wrong"));
  }, [dispatch, steps]);
  return (
    <>
      <main id="main" className="bg-inner pt-5">
        {/* Multistep form section */}
        <div className="profile-wrapper my-auto pt-5 mt-5 pb-5 mb-5">
          <div className="container">
            <div className="stepwizard">
              <div className="stepwizard-row setup-panel">
                <div className="step-1 stepwizard-step col-xs-3">
                  <button
                    type="button"
                    className="btn btn-default btn-circle"
                    disabled={!steps.step1.value}
                    onClick={() => {
                      setSteps({
                        ...steps,
                        step1: { ...steps.step1, display: "show" },
                        step2: { ...steps.step2, display: "none" },
                        step3: { ...steps.step3, display: "none" },
                        step4: { ...steps.step4, display: "none" },
                        step5: { ...steps.step5, display: "none" },
                        step6: { ...steps.step6, display: "none" },
                        step7: { ...steps.step7, display: "none" },
                        verifcation: { value: false, display: "none" },
                      });
                    }}
                  >
                    1
                  </button>
                  <p>
                    <small>About</small>
                  </p>
                </div>
                {/* <div className="step-2 stepwizard-step col-xs-3">
                  <button
                    type="button"
                    className="btn btn-default btn-circle"
                    disabled={!steps.step2.value}
                    onClick={() => {
                      setSteps({
                        ...steps,
                        step1: { ...steps.step1, display: "none" },
                        step2: { ...steps.step2, display: "show" },
                        step3: { ...steps.step3, display: "none" },
                        step4: { ...steps.step4, display: "none" },
                        step5: { ...steps.step5, display: "none" },
                        step6: { ...steps.step6, display: "none" },
                        step7: { ...steps.step7, display: "none" },
                        verifcation: { value: false, display: "none" },
                      });
                    }}
                  >
                    2
                  </button>
                  <p>
                    <small>Photo</small>
                  </p>
                </div> */}
                <div className="step-3 stepwizard-step col-xs-3">
                  <button
                    type="button"
                    className="btn btn-default btn-circle"
                    disabled={!steps.step3.value}
                    onClick={() => {
                      setSteps({
                        ...steps,
                        step1: { ...steps.step1, display: "none" },
                        step2: { ...steps.step2, display: "none" },
                        step3: { ...steps.step3, display: "show" },
                        step4: { ...steps.step4, display: "none" },
                        step5: { ...steps.step5, display: "none" },
                        step6: { ...steps.step6, display: "none" },
                        step7: { ...steps.step7, display: "none" },
                        verifcation: { value: false, display: "none" },
                      });
                    }}
                  >
                    2
                  </button>
                  <p>
                    <small>Education</small>
                  </p>
                </div>
                <div className="step-4 stepwizard-step col-xs-3">
                  <button
                    type="button"
                    className="btn btn-default btn-circle"
                    disabled={!steps.step4.value}
                    onClick={() => {
                      setSteps({
                        ...steps,
                        step1: { ...steps.step1, display: "none" },
                        step2: { ...steps.step2, display: "none" },
                        step3: { ...steps.step3, display: "none" },
                        step4: { ...steps.step4, display: "show" },
                        step5: { ...steps.step5, display: "none" },
                        step6: { ...steps.step6, display: "none" },
                        step7: { ...steps.step7, display: "none" },
                        verifcation: { value: false, display: "none" },
                      });
                    }}
                  >
                    3
                  </button>
                  <p>
                    <small>Certifications</small>
                  </p>
                </div>
                <div className="step-5 stepwizard-step col-xs-3">
                  <button
                    type="button"
                    className="btn btn-default btn-circle"
                    disabled={!steps.step5.value}
                    onClick={() => {
                      setSteps({
                        ...steps,
                        step1: { ...steps.step1, display: "none" },
                        step2: { ...steps.step2, display: "none" },
                        step3: { ...steps.step3, display: "none" },
                        step4: { ...steps.step4, display: "none" },
                        step5: { ...steps.step5, display: "show" },
                        step6: { ...steps.step6, display: "none" },
                        step7: { ...steps.step7, display: "none" },
                        verifcation: { value: false, display: "none" },
                      });
                    }}
                  >
                    4
                  </button>
                  <p>
                    <small>Description</small>
                  </p>
                </div>
                <div className="step-6 stepwizard-step col-xs-3">
                  <button
                    type="button"
                    className="btn btn-default btn-circle"
                    disabled={!steps.step6.value}
                    onClick={() => {
                      setSteps({
                        ...steps,
                        step1: { ...steps.step1, display: "none" },
                        step2: { ...steps.step2, display: "none" },
                        step3: { ...steps.step3, display: "none" },
                        step4: { ...steps.step4, display: "none" },
                        step5: { ...steps.step5, display: "none" },
                        step6: { ...steps.step6, display: "show" },
                        step7: { ...steps.step7, display: "none" },
                        verifcation: { value: false, display: "none" },
                      });
                    }}
                  >
                    5
                  </button>
                  <p>
                    <small>Videos</small>
                  </p>
                </div>
                <div className="step-7 stepwizard-step col-xs-3">
                  <button
                    type="button"
                    className="btn btn-default btn-circle"
                    disabled={!steps.step7.value}
                    onClick={() => {
                      setSteps({
                        ...steps,
                        step1: { ...steps.step1, display: "none" },
                        step2: { ...steps.step2, display: "none" },
                        step3: { ...steps.step3, display: "none" },
                        step4: { ...steps.step4, display: "none" },
                        step5: { ...steps.step5, display: "none" },
                        step6: { ...steps.step6, display: "none" },
                        step7: { ...steps.step7, display: "show" },
                        verifcation: { value: false, display: "none" },
                      });
                    }}
                  >
                    6
                  </button>
                  <p>
                    <small>Availablity</small>
                  </p>
                </div>
              </div>
            </div>

            {/* steps */}
            {/* step 1 form */}
            {steps.step1.display === "show" && (
              <Step1
                setSteps={setSteps}
                steps={steps}
                user={user}
                setPercentage={props.setPercentage}
              />
            )}
            {/* step 2 form */}
            {/* {steps.step2.display === "show" && (
              <Step2
                setSteps={setSteps}
                steps={steps}
                setPercentage={props.setPercentage}
                user={user}
              />
            )} */}
            {/* step 3 form */}
            {steps.step3.display === "show" && (
              <Step3 setSteps={setSteps} steps={steps} user={user} />
            )}
            {/* step 4 form */}
            {steps.step4.display === "show" && (
              <Step4 setSteps={setSteps} steps={steps} user={user} />
            )}
            {/* step 5 form */}
            {steps.step5.display === "show" && (
              <Step5 setSteps={setSteps} steps={steps} user={user} />
            )}
            {/* step 6 form */}
            {steps.step6.display === "show" && (
              <Step6 setSteps={setSteps} steps={steps} user={user} />
            )}
            {/* step 7 form */}
            {steps.step7.display === "show" && (
              <Step7 setSteps={setSteps} steps={steps} user={user} />
            )}
            {steps.verifcation.display === "show" && <Verification />}
          </div>
        </div>
      </main>
      <Toaster />
    </>
  );
}

export default Profile;
