import React from "react";
import DefaultProfileImage from "../../../../assets/img/pic1.svg";
import verifiedIcon from "../../../../assets/img/verified.svg";
import { Link } from "react-router-dom";
const ImageUrl = process.env.REACT_APP_FILES;
function Intro({
  profile_image,
  first_name,
  last_name,
  bio,
  teaching_experience,
  native_language,
  teacher_languages,
  verified,
}) {
  return (
    <div className="list-item row align-items-center mb-0">
      <div className="col-lg-3">
        <Link className="d-block post-trending" to="">
          <img
            className="img-fluid w-100"
            src={
              profile_image
                ? ImageUrl + profile_image?.path
                : DefaultProfileImage
            }
            alt="profile_image"
          />
        </Link>
      </div>
      <div className="col-lg-9">
        <h2 className="h3 mb-4">
          <Link className="d-block reset-anchor" to="">
            {first_name} {last_name}{" "}
            {verified && (
              <img className="img-fluid" src={verifiedIcon} alt="verfied" />
            )}
          </Link>
        </h2>
        <p className="text-muted">{bio ? bio : teaching_experience}</p>
        <ul className="list-inline">
          <li className="list-inline-item mr-2">
            <Link className="category-link" to="">
              <span>Speaks:</span>{" "}
              {teacher_languages
                .map((i) => {
                  return i?.language?.name;
                })
                .toString()}
            </Link>
          </li>
          <li className="list-inline-item mx-2">
            <Link className="category-link meta-link" to="">
              <span>Native:</span> {native_language?.name}
            </Link>
          </li>
          <li className="list-inline-item ">
            <Link className="category-link meta-link" to="">
              <i className="bi bi-people"></i>
              361 Student
            </Link>
          </li>
          <li className="list-inline-item mx-2">
            <Link className="category-link meta-link" to="">
              <i className="bi bi-journal-bookmark"></i>
              500 lessons
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Intro;
