import React from "react";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
function ServiceDetails({ hourly_rate, processingFee }) {
  return (
    <div className="row">
      <Table borderless>
        <tbody>
          <tr>
            <th>Service Details</th>
            <th className="text-right">Price per hour</th>
          </tr>
          <tr>
            <td>
              1 hour lesson{" "}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Price per hour in UK dollars.
                  </Tooltip>
                }
              >
                <i className="bi bi-question-circle"></i>
              </OverlayTrigger>
            </td>
            <td className="text-right">${hourly_rate.toFixed(2)}</td>
          </tr>
          <tr>
            <td>
              Processing fee {" "}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    This fee secures your payment and helps us make payments to
                    your tutor when you complete your lessons.
                  </Tooltip>
                }
              >
                <i className="bi bi-question-circle"></i>
              </OverlayTrigger>
            </td>
            <td className="text-right">${processingFee.toFixed(2)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default ServiceDetails;
