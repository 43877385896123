import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { step6 } from "../../../../features/Tutor/stepsSlice";
import { videoUrl } from "../../../../services/tutors";
import { successNotify, errorNotify } from "../../../../ui/toasters";

function Step6(props) {
  // for scroll to top on render the home component
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const { steps, setSteps, user } = props;
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.steps?.step6);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      video_url: user?.video_url,
    },
  });
  const onSubmit = (data) => {
    dispatch(step6(data));
    // disbling next button so user can't hit API more than once
    setDisableNextBtn(true);
    videoUrl(data)
      .then((res) => {
        successNotify(res.data.message);
        setSteps({
          ...steps,
          step7: { value: true, display: "show" },
          step6: { value: true, display: "none" },
        });
      })
      .catch((err) => errorNotify("Something went wrong"));
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="panel panel-primary setup-content pb-5" id="step-6">
          <div className="panel-body">
            <div className="form-row custom-form-mt">
              <div className="form-group">
                <h3>Paste a link to your video</h3>
                <input
                  type="text"
                  name="videolink"
                  placeholder="https://www.youtube.com/watch?v="
                  className="form-control mb-2"
                  defaultValue={state?.video_url}
                  {...register("video_url", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                    pattern: {
                      value:
                        /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?[\w?=]*)?/,
                      message: "Invalid youtube link",
                    },
                  })}
                />
              </div>
              {errors?.video_url?.type === "required" && (
                <span className="text-danger">
                  {errors?.video_url?.message}
                </span>
              )}
              {errors?.video_url?.type === "pattern" && (
                <span className="text-danger">
                  {errors?.video_url?.message}
                </span>
              )}
            </div>
            <input
              name="login"
              id="login"
              className="btn btn-primary btn-block login-btn nextBtn"
              type="submit"
              value="Next"
              disabled={disableNextBtn}
            />
          </div>
        </div>
      </form>
    </>
  );
}

export default Step6;
