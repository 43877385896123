import React from "react";
import Table from "react-bootstrap/Table";
import { openChangeBookedLessonStatusModal } from "../../../features/modal/modalSlice";
import { useDispatch } from "react-redux";

const VideoCallBaseUrl = process.env.REACT_APP_CLASS_CALL_LINK;
// get Button accoring to status
const BookingStatus = (status) => {
  switch (status) {
    case "1":
      return "Pending";
    case "2":
      return "Approved";
    case "3":
      return "Declined";
    default:
      return "Pending";
  }
};

const PaymentStatus = (status) => {
  switch (status) {
    case "0":
      return "Unpaid";
    case "1":
      return "Paid";
    default:
      return "Unpaid";
  }
};
const connectCall = (token) => {
  const url = VideoCallBaseUrl + token;
  window.open(url, "_blank", "noopener,noreferrer");
};
function Listing({ bookings, setStatusChange, from }) {
  const dispatch = useDispatch();
  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th>Student Name</th>
          <th className="text-center">Book Time</th>
          <th className="text-center">Date</th>
          <th className="text-center">Booking Status</th>
          <th className="text-center">Payment Status</th>
          <th className="text-center">Action</th>
          <th className="text-center">Call</th>
        </tr>
      </thead>
      <tbody>
        {bookings.map((item, index) => {
          return (
            <tr id={index}>
              <td>{from + index}</td>
              <td>
                {item.book_student.first_name}{" "}
                {item.book_student.last_name && item.book_student.last_name}
              </td>
              <td className="text-center">{item.book_time.slice(0, 5)}</td>
              <td className="text-center">{item.date}</td>
              <td className="text-center">
                {BookingStatus(item.booking_status)}
              </td>
              <td className="text-center">
                {PaymentStatus(item.payment_status)}
              </td>
              <td
                className="text-center"
                onClick={() =>
                  dispatch(
                    openChangeBookedLessonStatusModal({
                      item: item,
                      setStatusChange: setStatusChange,
                    })
                  )
                }
              >
                <i className="bi bi-pencil-square"></i>
              </td>
              <td className="text-center">
                <button
                  onClick={() => connectCall(item.video_token)}
                  className="btn"
                  disabled={item.booking_status === "2" ? false : true}
                >
                  {item.booking_status === "2" ? (
                    <i className="bi bi-camera-video-fill video_icon"></i>
                  ) : (
                    <i className="bi bi-camera-video-off-fill"></i>
                  )}
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default Listing;
