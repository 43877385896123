import React from "react";
import cardImage from "../../../assets/img/credit-card-icons-1.png";
import { useDispatch } from "react-redux";
import { openAddPaymentCardmodal } from "../../../features/modal/modalSlice";
import { useState, useEffect } from "react";
import { getCard } from "../../../services/student";
import CustomSpinner from "../../../ui/loaders/CustomSpinner";
import { bookTrailPayment } from "../../../services/student";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { successNotify, errorNotify } from "../../../ui/toasters";

function RightCard({
  bookLangauge,
  hourlyRate,
  total,
  subTotal,
  processingFee,
}) {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const [card, setCard] = useState(null);
  const [cardAdded, setCardAdded] = useState(false);
  const [loader, setLoader] = useState(null);
  useEffect(() => {
    setLoader(true);
    getCard().then((res) => {
      setCard(res.data.data);
      setLoader(false);
    });
  }, [cardAdded]);
  const handlePayment = () => {
    setLoader(true);
    const obj = {
      hourly_rate: hourlyRate.toFixed(2),
      sub_total: subTotal.toFixed(2),
      processing_fee: processingFee.toFixed(2),
      total: total.toFixed(2),
      booking_language: bookLangauge,
    };
    bookTrailPayment(obj, uuid)
      .then((res) => {
        successNotify(res.data.message);
        navigate("/student/dashboard/setting?menu=payment_history", { state: true });
        setLoader(false);
      })
      .catch((err) => {
        errorNotify("Something went wrong");
        setLoader(false);
      });
  };
  return (
    <div className="card card-primary">
      <div
        className=""
        id="vert-tabs-payment"
        role="tabpanel"
        aria-labelledby="vert-tabs-payment-tab"
      >
        <div className="card-header">
          <h3 className="card-title">Payment Methods</h3>
        </div>

        <div className="wrapper bg-white sm-5 p-4">
          <p>
            Save a payment method for fast and easy lesson payments. DayLearning
            uses industry-standard encryption to protect your information.
          </p>
          {loader ? (
            <CustomSpinner />
          ) : (
            <div className="py-2">
              <div className="row py-2">
                <div className="col-md-6">
                  <h5>{card ? "Your Card" : "Credit or debit card"}</h5>
                  {!card && (
                    <div>
                      <img width="150" src={cardImage} alt="cards" />
                    </div>
                  )}
                  {card && (
                    <label className="mt-2">{`${card?.card_type} **** **** **** ${card?.last4}`}</label>
                  )}
                </div>
                <div className="col-md-6 d-flex align-items-end">
                  {card ? (
                    <button
                      className="btn btn-primary mr-3"
                      type="button"
                      onClick={handlePayment}
                    >
                      Continue
                    </button>
                  ) : (
                    <button
                      data-toggle="modal"
                      data-target="#cardForm"
                      className="btn btn-primary mr-3"
                      onClick={() =>
                        dispatch(openAddPaymentCardmodal(setCardAdded))
                      }
                    >
                      Add Card
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RightCard;
