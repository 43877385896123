import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: null,
};

export const userSlice = createSlice({
    name: "userDetails",
    initialState,
    reducers: {
      storeUser: (state, action) => {
        state.value = action.payload;
      },
    },
  });
  export const { storeUser } = userSlice.actions;
  export default userSlice.reducer;
  
