import React from "react";
import cardImage from "../../../../assets/img/credit-card-icons-1.png";
import { useDispatch } from "react-redux";
import {
  openAddPaymentCardmodal,
  openUpdateCardPaymentCardmodal,
} from "../../../../features/modal/modalSlice";
import { useState, useEffect } from "react";
import { getCard } from "../../../../services/student";
import CustomSpinner from "../../../../ui/loaders/CustomSpinner";
function PaymentMethod() {
  const dispatch = useDispatch();
  const [card, setCard] = useState(null);
  const [cardAdded, setCardAdded] = useState(false);
  const [updateCard, setUpdateCard] = useState(false);
  const [loader, setLoader] = useState(null);
  useEffect(() => {
    setLoader(true);
    getCard().then((res) => {
      setCard(res.data.data);
      setLoader(false);
    });
  }, [cardAdded, updateCard]);
  return (
    <div className="card card-primary">
      <div
        className=""
        id="vert-tabs-payment"
        role="tabpanel"
        aria-labelledby="vert-tabs-payment-tab"
      >
        <div className="card-header">
          <h3 className="card-title">Payment Methods</h3>
        </div>

        <div className="wrapper bg-white sm-5 p-4">
          <p>
            Save a payment method for fast and easy lesson payments. DayLearning
            uses industry-standard encryption to protect your information.
          </p>
          {loader ? (
            <CustomSpinner />
          ) : (
            <div className="py-2">
              <div className="row py-2">
                <div className="col-md-6">
                  <h5>{card ? "Your Card" : "Credit or debit card"}</h5>
                  {!card && (
                    <div>
                      <img width="150" src={cardImage} alt="cards" />
                    </div>
                  )}
                  {card && (
                    <label className="mt-2">{`${card?.card_type} **** **** **** ${card?.last4}`}</label>
                  )}
                </div>
                <div className="col-md-6 d-flex align-items-end">
                  {card ? (
                    <button
                      className="btn btn-primary mr-3"
                      type="button"
                      onClick={() => {
                        dispatch(openUpdateCardPaymentCardmodal(setUpdateCard));
                      }}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      data-toggle="modal"
                      data-target="#cardForm"
                      className="btn btn-primary mr-3"
                      onClick={() =>
                        dispatch(openAddPaymentCardmodal(setCardAdded))
                      }
                    >
                      Add Card
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentMethod;
