import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { closeModal } from "../../features/modal/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import Calendar from "../../Calendar";
import { getTimeSlots, bookLesson } from "../../services/tutors";
import { useNavigate } from "react-router-dom";
import { errorNotify } from "../toasters";
//import "../../Calendar.css";

function BookingCalendarModal({ props }) {
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);
  const [dataProp, setDataProp] = useState(null);
  const showDetailsHandle = (dayStr) => {
    setData(dayStr);
    setShowDetails(true);
  };  
  const state = useSelector((state) => state.modalState.bookingCalendarModal);
  const dispatch = useDispatch(); 
  return (
    <Modal show={state.value} onHide={() => dispatch(closeModal())}>
      <Modal.Header closeButton>
        <Modal.Title>Book a trial lesson</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="App">
          <Calendar
            showDetailsHandle={showDetailsHandle}
            data={state.data?.data?.avaiability}
            language_id={state.data?.data?.teacher_languages[0]?.id}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BookingCalendarModal;
