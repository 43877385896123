import React from "react";
import verifiedImg from "../../../assets/img/verified.svg";
function Name({ first_name, last_name, verified, native_language }) {
  return (
    <div className="mt-3">
      <div className="d-flex">
        <label className="name">
          {first_name} {last_name}
        </label>
        {verified === "1" && (
          <img className="ms-1" src={verifiedImg} alt="verified" />
        )}
      </div>
      <div>
        <i className="bi bi-mortarboard-fill"></i>
        <label className="ms-1">{native_language.name}</label>
      </div>
    </div>
  );
}

export default Name;
